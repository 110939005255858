import Fields from "@vuex-orm/core/lib/model/contracts/Fields";
import { Dictionary } from "vue-router/types/router";
import ServerErrorHandler from "../classes/ServerResponseHandler/ServerErrorHandler";
import ServerResponseHandler from "../classes/ServerResponseHandler/ServerResponseHandler";
import Model from "./Model";

export default class Setting extends Model {

    /**
     * Creates settings from given response.
     *
     * @param {Object} response
     * @return {void}
     */
    public static fromResponse(response: any): void {
        const settings = response.response.data.settings;
        const keys = Object.keys(settings);

        for (const key of keys) {
            Setting.insert({
                data: {
                    name: key,
                    value: settings[key]
                }
            });
        }
    }

    /**
     * Returns all settings wrapped in an object of
     * keys => values.
     *
     * @return {Object}
     */
    public static getSettingsObject(): any {
        const settings = this.query().all();
        const settingsObject: Dictionary<string> = {};

        for (const setting of settings) {
            settingsObject[setting.name] = setting.value;
        }

        return settingsObject;
    }

    /**
     * Sends request to patch settings with given data.
     *
     * @param {number|string} id
     * @param {Object} data
     * @return {Promise<void>}
     */
    public static updateRemote(id: number | string, data: any): Promise<void> {
        return this.api()
            .patch("/" + this.entity, data, { save: false })
            .then(ServerResponseHandler.handle)
            .catch(ServerErrorHandler.handle);
    }

    /**
     * The name that is going be used as module name in Vuex Store.
     *
     * @type string
     */
    public static entity = "settings";

    /**
     * The definition of the fields of the model and its relations.
     *
     * @return {Fields}
     */
    public static fields(): Fields {
        return {
            name: this.string(""),
            value: this.string("")
        };
    }

    /**
     * The setting name.
     *
     * @type {string}
     */
    public name!: string;

    /**
     * The setting value.
     *
     * @type {string}
     */
    public value!: string;

}
