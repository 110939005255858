import Model from "./Model";
import Permission from "./Permission";
import RolePermissionScope from "./RolePermissionScope";

export default class Role extends Model {

    /**
     * The name that is going be used as module name in Vuex Store.
     *
     * @type string
     */
    public static entity = "roles";

    /**
     * The definition of the fields of the model and its relations.
     *
     * @return Object
     */
    public static fields() {
        return {
            id: this.number(0),
            name: this.string(""),
            users_count: this.number(0),
            permissions: this.belongsToMany(
                Permission,
                "role_permissions",
                "permission_id",
                "role_id"
            ),
            permissions_count: this.number(0),
            permissions_scopes: this.hasMany(RolePermissionScope, "role_id"),
            permissions_scopes_count: this.number(0),
            created_at: this.string(null).nullable(),
            updated_at: this.string(null).nullable()
        };
    }

    /**
     * The ID of the role.
     *
     * @type number
     */
    public id!: number;

    /**
     * The name of the role.
     *
     * @type string
     */
    public name!: string;

    /**
     * Total number of users having this role.
     *
     * @type number
     */
    public users_count!: number;

    /**
     * Permissions that the role belongs to.
     *
     * @type Permission[]
     */
    public permissions!: Permission[];

    /**
     * Total number of permissions assigned to the role.
     *
     * @type number
     */
    public permissions_count!: number;

    /**
     * Role permissions scopes of the role.
     *
     * @type RolePermissionScope[]
     */
    public permissions_scopes!: RolePermissionScope[];

    /**
     * Total number of permissions scopes assigned to the role.
     *
     * @type number
     */
    public permissions_scopes_count!: number;

    /**
     * Role creation timestamp.
     *
     * @type string|null
     */
    public created_at!: string | null;

    /**
     * Last update timestamp.
     *
     * @type string|null
     */
    public updated_at!: string | null;

}
