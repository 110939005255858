import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class DestroyTooltipMixin extends Vue {

    /**
     * Before the component is destroyed.
     *
     * @return {void}
     */
    public beforeDestroy(): void {
        // We hide all tooltips that are visible when the
        // component is destroyed, so no tooltip is "dangling"
        $(".tooltip").remove();
    }

}
