import Vue from "vue";
import Component from "vue-class-component";
import Model from "../models/Model";

export interface SubmittedDataInterface {
    model: typeof Model;
    data: any;
}

@Component
export default class FormMixin extends Vue {

    /**
     * Checks whether the form is in edit mode.
     *
     * @return {boolean}
     */
    public get isEditForm(): boolean {
        const routeParams = this.$router.currentRoute.params;
        const routeParamsKeys = Object.keys(routeParams);

        return routeParamsKeys.length > 0;
    }

    /**
     * On form submit.
     *
     * @return {Promise<void>}
     */
    public async onSubmit(): Promise<void> {
        const { model, data } = this.getSubmittedData();
        const routeParams = this.$router.currentRoute.params;
        const routeParamsKeys = Object.keys(routeParams);
        const paramKey = routeParamsKeys[0];
        const id = routeParams[paramKey];

        if (typeof model === "undefined") {
            throw new Error("You need to override the getSubmittedData() method!");
        }

        await this.withLoading(async () => {
            if (this.isEditForm) {
                await model.updateRemote(id, data);
            } else {
                await model.storeRemote(data);
            }
        });
    }

    /**
     * Returns the data for submitting a form.
     *
     * @return {SubmittedDataInterface}
     */
    public getSubmittedData(): SubmittedDataInterface {
        // This method is overridden in other components
        return {} as SubmittedDataInterface;
    }

}
