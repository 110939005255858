













































import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import VIcon from "./VIcon.vue";

@Component({
    components: { VIcon }
})
export default class DropdownSelect extends Vue {

    @Prop()
    public value!: any;

    @Prop({ default: "" })
    public label!: string;

    @Prop({ default: [] })
    public options!: any[];

    @Prop({ default: "value" })
    public optionValueKey!: string;

    @Prop({ default: "label" })
    public optionLabelKey!: string;

    @Prop({ default: "" })
    public buttonClass!: string;

    @Watch("value")
    public onValueChanged(newValue: number): void {
        this.selectedOption = this.options.find((option: any) => option[this.optionValueKey] === newValue);
    }

    /**
     * Currently selected option.
     *
     * @type Object
     */
    public selectedOption = this.options[0];

    /**
     * Returns dropdown toggle button class.
     *
     * @return {Array}
     */
    public get toggleButtonClass(): any[] {
        return [
            "select-toggle",
            "mw-100",
            "overflow-hidden",
            "text-ellipsis",
            "text-left",
            "position-relative",
            this.buttonClass,
            { "selected": true }
        ];
    }

    /**
     * Returns text for toggle button.
     *
     * @return {string}
     */
    public get toggleButtonText(): string {
        return this.selectedOption ? this.selectedOption[this.optionLabelKey] : "";
    }

    /**
     * Returns the style object for toggle button icon.
     *
     * @return {string}
     */
    public get toggleButtonIconStyle(): object {
        return {
            right: "0.5rem",
            top: "25%"
        };
    }

    /**
     * Returns the class for the dropdown item.
     *
     * @return {Array}
     */
    public dropdownItemClass(option: any): any[] {
        return [
            "paginated-dropdown-item",
            { "active": this.selectedOption[this.optionValueKey] === option[this.optionValueKey] }
        ];
    }

    /**
     * On dropdown item click.
     *
     * @param {Object} option
     * @return {void}
     */
    public onItemClick(option: any): void {
        if (this.optionValueKey in option) {
            if (option[this.optionValueKey] !== this.selectedOption[this.optionValueKey]) {
                this.selectedOption = option;

                this.$emit("change", option);
                this.$emit("input", option.value);
            }
        }
    }

}
