


























import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import VIcon from "./VIcon.vue";

@Component({
    components: { VIcon }
})
export default class ResourceDropdownMenu extends Vue {

    @Prop({ required: true })
    public id!: string;

    @Prop({ default: true })
    public show!: boolean;

}
