import Vue from "vue";

// Custom "record-enter" Vue directive that records if enter was pressed
// while element was focused. Any time enter was pressed the target
// component will emit an empty "key-enter" event
Vue.directive("record-enter", {
    bind(element, binding, node): void {
        element.addEventListener("keydown", (event: KeyboardEvent) => {
            if (event.key === "Enter") {
                node.componentInstance?.$emit("key-enter");
            }
        });
    }
});
