









import Vue from "vue";
import Component from "vue-class-component";
import ResourceEmpty from "../../components/ResourceEmpty.vue";

@Component({
    components: { ResourceEmpty }
})
export default class DistrictEmpty extends Vue {

    /**
     * Array of messages that appear when the resource is not present at all.
     *
     * @type string[]
     */
    public messagesEmpty = [
        this.$lang("Wszystko wskazuje na to, że nie ma zdefiniowanych żadnych hufców."),
        this.$lang("Szukałem, ale nie znalazłem żadnych hufców."),
        this.$lang("Ani jednego hufca ani nawet hufieca tutaj nie ma."),
        this.$lang("Przed wyszukaniem hufców należy zebrać... ekhem. Należy je utworzyć.")
    ];

    /**
     * Array of messages that appear when the resource was searched for
     * but not found by the server.
     *
     * @type string[]
     */
    public messagesNotFound = [
        this.$lang("Szukałem hufców zgodnie z \":search\", ale nic nie pasowało."),
        this.$lang("Na pewno \":search\"? Ja nie znalazłem żadnego hufca."),
        this.$lang("Nie znalazłem żadnego hufca, który pasowałby do \":search\"."),
        this.$lang("Miałem lamę o imieniu \":search\", ale takiego hufca nie znalazłem.")
    ];

    /**
     * Array of leads that appear when the resource is not present at all.
     *
     * @type string[]
     */
    public leadsEmpty = [
        this.$lang("Być może nie masz uprawnień do przeglądania hufców, a być może te, do których masz uprawnienia - nie istnieją.")
    ];

    /**
     * Array of leads that appear when the resource was searched for
     * but not found by the server.
     *
     * @type string[]
     */
    public leadsNotFound = [
        this.$lang("Jeśli jednak utrzymujesz, że taki hufiec powinien istnieć, to zgłoś to... komuś tam. W każdym razie zgłoś to."),
        this.$lang("Nie zawsze znajdujemy to, czego szukamy, ale to najlepsza motywacja, by szukać dalej! Czy byłoby li znajdowanie równie satysfakcjonujące bez szukania?"),
        this.$lang("Jeśli ta wiadomość jest dla Ciebie równie zaskakująca, co dla mnie, to przybij piątkę!"),
        this.$lang("Mamo tato, mamo tato, zamawiam hufiec na laaaatooo!")
    ];

}
