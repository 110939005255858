import District from "./District";
import Model from "./Model";

export default class Unit extends Model {

    /**
     * The name that is going be used as module name in Vuex Store.
     *
     * @return string
     */
    public static entity = "units";

    /**
     * The definition of the fields of the model and its relations.
     *
     * @return Object
     */
    public static fields() {
        return {
            id: this.number(0),
            name: this.string(""),
            district_id: this.number(null).nullable(),
            district: this.attr({}, (data: any) => new District(data)),
            scouts_count: this.number(0),
            users_count: this.number(0),
            created_at: this.string(null).nullable(),
            updated_at: this.string(null).nullable()
        };
    }

    /**
     * The ID of the unit.
     *
     * @type number
     */
    public id!: number;

    /**
     * The name of the unit.
     *
     * @type string
     */
    public name!: string;

    /**
     * The ID of the unit's district.
     *
     * @type number
     */
    public district_id!: number;

    /**
     * The district the unit belongs to.
     *
     * @type District
     */
    public district!: District;

    /**
     * The total number of scouts that belong to the unit.
     *
     * @type number
     */
    public scouts_count!: number;

    /**
     * The total number of users that have been assigned the unit.
     *
     * @type number
     */
    public users_count!: number;

    /**
     * Unit creation timestamp.
     *
     * @type string|null
     */
    public created_at!: string | null;

    /**
     * Unit update timestamp.
     *
     * @type string|null
     */
    public updated_at!: string | null;


}
