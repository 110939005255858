import Vue from "vue";

// Custom "v-tooltip" directive that accepts a string and displays
// it inside a tooltip when the component is hovered
Vue.directive("tooltip", {
    bind(element, binding, node): void {
        // We add new data attributes to enable tooltips
        element.dataset["toggle"] = "tooltip";
        element.dataset["title"] = binding.value;
    }
});
