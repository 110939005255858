import { Config, Response } from "@vuex-orm/plugin-axios";
import { Dictionary } from "vue-router/types/router";
import ServerErrorHandler from "../classes/ServerResponseHandler/ServerErrorHandler";
import ServerResponseHandler from "../classes/ServerResponseHandler/ServerResponseHandler";
import Model from "./Model";
import Setting from "./Setting";

export default class ContributionAmount extends Model {

    /**
     * Creates contributions amounts array from given object.
     *
     * @param {Object} data
     * @return {ContributionAmount[]}
     */
    public static from(data: any): ContributionAmount[] {
        const keys = Object.keys(data);
        const entries = [];

        for (const key of keys) {
            entries.push({
                year: key,
                quarter1: data[key].quarter1,
                quarter2: data[key].quarter2,
                quarter3: data[key].quarter3,
                quarter4: data[key].quarter4
            });
        }

        // We map amounts into contribution amount models
        // and return them
        return entries.map((amount: any) => new ContributionAmount(amount));
    }

    /**
     * Returns all amounts wrapped in an object of
     * keys => values.
     *
     * @return {Object}
     */
    public static getAmountsObject(): any {
        const amounts = this.query().all();
        const amountsObject: Dictionary<any> = {};

        for (const amount of amounts) {
            amountsObject[amount.year] = {
                quarter1: amount.quarter1,
                quarter2: amount.quarter2,
                quarter3: amount.quarter3,
                quarter4: amount.quarter4
            };
        }

        return amountsObject;
    }

    /**
     * Requests resource from the server and stores the results
     * in the Vuex store.
     *
     * @param {Object} params
     * @return {Promise<void|Response>}
     */
    public static index(params?: any): Promise<void | Response> {
        const config = {
            dataKey: this.entity,
            persistBy: "create"
        } as Config;

        return this.api()
            .get("/contributions/amounts", config)
            .then(Setting.fromResponse)
            .catch(ServerErrorHandler.handle);
    }

    /**
     * Sends request to patch settings with given data.
     *
     * @param {number|string} id
     * @param {Object} data
     * @return {Promise<void>}
     */
    public static updateRemote(id: number | string, data: any): Promise<void> {
        return this.api()
            .patch("/contributions/amounts", data, { save: false })
            .then(ServerResponseHandler.handle)
            .catch(ServerErrorHandler.handle);
    }

    /**
     * The name that is going be used as module name in Vuex Store.
     *
     * @type string
     */
    public static entity = "amounts";

    /**
     * The definition of the fields of the model and its relations.
     *
     * @return Object
     */
    public static fields() {
        return {
            year: this.number(0),
            quarter1: this.number(0),
            quarter2: this.number(0),
            quarter3: this.number(0),
            quarter4: this.number(0)
        };
    }

    /**
     * The contribution amount year.
     *
     * @type {number}
     */
    public year!: number;

    /**
     * The contribution amount for first quarter.
     *
     * @type {number}
     */
    public quarter1!: number;

    /**
     * The contribution amount for second quarter.
     *
     * @type {number}
     */
    public quarter2!: number;

    /**
     * The contribution amount for third quarter.
     *
     * @type {number}
     */
    public quarter3!: number;

    /**
     * The contribution amount for fourth quarter.
     *
     * @type {number}
     */
    public quarter4!: number;

}
