







































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ResourceDropdownMenu from "../../components/ResourceDropdownMenu.vue";
import VIcon from "../../components/VIcon.vue";
import Scout from "../../models/Scout";
import { ContributionsDisplayMode } from "./Index.vue";
import ScoutCardContributions from "./ScoutCardContributions.vue";
import ScoutCardDetails from "./ScoutCardDetails.vue";

@Component({
    components: {
        ScoutCardDetails,
        ScoutCardContributions,
        ResourceDropdownMenu,
        VIcon
    }
})
export default class ScoutCard extends Vue {

    @Prop({ required: true })
    public scout!: Scout;

    @Prop({ required: true })
    public requestScoutsFunction!: Function;

    @Prop({ required: true })
    public contributionsDisplayMode!: ContributionsDisplayMode;

    /**
     * Returns the full class attribute for scout card.
     *
     * @return {string[]}
     */
    public get scoutCardClass(): string[] {
        return [
            "scout-card",
            "text-light",
            "bg-success-transparent",
            "my-3",
            "shadow-sm",
            this.cardBorderColor
        ];
    }

    /**
     * Returns class for card border color.
     *
     * @return {string}
     */
    public get cardBorderColor(): string {
        const totalQuarters = this.scout.contributions_profile.yearsCount * 4;
        const unpaidQuarters = this.scout.contributions_profile.arrears.quartersCount;

        if (unpaidQuarters === 0) {
            return "card-border-success";
        } else if (unpaidQuarters <= totalQuarters / 2) {
            return "card-border-warning";
        } else {
            return "card-border-danger";
        }
    }

    /**
     * Returns the scout district name.
     *
     * @return {string}
     */
    public get scoutDistrictName(): string {
        if (this.scout.district) {
            return this.scout.district.name;
        } else if (this.scout.unit && this.scout.unit.district) {
            return this.scout.unit.district.name;
        } else {
            return "";
        }
    }

    /**
     * Returns the scout unit name.
     *
     * @return {string}
     */
    public get scoutUnitName(): string {
        return this.scout.unit ? this.scout.unit.name : "";
    }

    /**
     * Returns dropdown id string.
     *
     * @return {string}
     */
    public get dropdownId(): string {
        return "scout-card-" + this.scout.id + "-dropdown";
    }

    /**
     * Whether to show the dropdown menu.
     *
     * @return {boolean}
     */
    public get showMenu(): boolean {
        return (
            this.can(this.allPermissions.scout.edit) ||
            this.can(this.allPermissions.scout.delete) ||
            this.can(this.allPermissions.scout.archive) ||
            this.can(this.allPermissions.scout.archived.restore)
        );
    }

    /**
     * Handles "edit scout" button click.
     *
     * @return {void}
     */
    public onScoutEdit(): void {
        this.redirectTo("scouts.edit", this.scout.id);
    }

    /**
     * Handles "archive scout" button click.
     *
     * @return {void}
     */
    public onScoutArchive(): void {
        const { id, display_name } = this.scout;
        const question = this.$lang(
            "Czy na pewno chcesz archiwizować harcerza \":name\"?",
            { name: display_name }
        );

        this.confirmationModal({
            question,
            onConfirm: () => Scout.updateRemote(id, { archived: true }).then(() => {
                this.withLoading(this.requestScoutsFunction as any);
            })
        });
    }

    /**
     * Handles "restore scout" button click.
     *
     * @return {void}
     */
    public onScoutRestore(): void {
        const { id, display_name } = this.scout;
        const question = this.$lang(
            "Czy na pewno chcesz przywrócić harcerza \":name\"?",
            { name: display_name }
        );

        this.confirmationModal({
            question,
            onConfirm: () => Scout.updateRemote(id, { archived: false }).then(() => {
                this.withLoading(this.requestScoutsFunction as any);
            })
        });
    }


    /**
     * Handles "delete scout" button click.
     *
     * @return void
     */
    public onScoutDelete(): void {
        const { id, display_name } = this.scout;
        const question = this.$lang(
            "Czy na pewno chcesz usunąć harcerza \":name\"?",
            { name: display_name }
        );

        this.confirmationModal({
            question,
            onConfirm: () => Scout.deleteRemote(id)
        });
    }

}
