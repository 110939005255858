import ContributionsProfile from "../classes/ContributionsProfile";
import District from "./District";
import Model from "./Model";
import Unit from "./Unit";

export default class Scout extends Model {

    /**
     * The name that is going be used as module name in Vuex Store.
     *
     * @type string
     */
    public static entity = "scouts";

    /**
     * The definition of the fields of the model and its relations.
     *
     * @return Object
     */
    public static fields() {
        return {
            id: this.number(0),
            first_name: this.string(""),
            last_name: this.string(""),
            display_name: this.string(""),
            registration_number: this.string(""),
            unit_id: this.number(null).nullable(),
            unit: this.attr({}, (data: any) => data ? new Unit(data) : null),
            district_id: this.number(null).nullable(),
            district: this.attr({}, (data: any) => data ? new District(data) : null),
            remarks: this.string(null).nullable(),
            joined_at: this.string(null).nullable(),
            quit_at: this.string(null).nullable(),
            archived: this.boolean(false),
            contributions_profile: this.attr({}, (data: any) => ContributionsProfile.from(data)),
            created_at: this.string(null).nullable(),
            updated_at: this.string(null).nullable()
        };
    }

    /**
     * Returns all the models sorted by their names.
     *
     * @return {Scout[]}
     */
    public static orderByDisplayName(): Scout[] {
        return this.query()
            .orderBy("display_name")
            .get();
    }

    /**
     * Scout's ID.
     *
     * @type number
     */
    public id!: number;

    /**
     * Scout's first name.
     *
     * @type string
     */
    public first_name!: string;

    /**
     * Scout's last name.
     *
     * @type string
     */
    public last_name!: string;

    /**
     * Scout's full display name.
     *
     * @type string
     */
    public display_name!: string;

    /**
     * Scout's official registration number.
     *
     * @type string
     */
    public registration_number!: string;

    /**
     * ID of the unit the scout belongs to.
     *
     * @type number|null
     */
    public unit_id!: number | null;

    /**
     * Model of the unit the scout belongs to.
     *
     * @type Unit|null
     */
    public unit!: Unit | null;

    /**
     * ID of the district the scout belongs to.
     * It is inferred from the unit.
     *
     * @type number|null
     */
    public district_id!: number | null;

    /**
     * Model of the district the scout belongs to.
     *
     * @type District|null
     */
    public district!: District | null;

    /**
     * Remarks regarding the scout.
     *
     * @type string|null
     */
    public remarks!: string | null;

    /**
     * The date the scout joined the organisation.
     *
     * @type string|null
     */
    public joined_at!: string | null;

    /**
     * The date the scout quit the organisation.
     *
     * @type string|null
     */
    public quit_at!: string | null;

    /**
     * Whether the scout is archived or not.
     *
     * @type boolean
     */
    public archived!: boolean;

    /**
     * Full contributions profile for the scout.
     *
     * @type ContributionsProfile
     */
    public contributions_profile!: ContributionsProfile;

    /**
     * Scout creation timestamp.
     *
     * @type string|null
     */
    public created_at!: string | null;

    /**
     * Scout update timestamp.
     *
     * @type string|null
     */
    public updated_at!: string | null;

}
