import { Response } from "@vuex-orm/plugin-axios";
import { Route } from "vue-router";
import { Dictionary } from "vue-router/types/router";
import Auth from "../models/Auth";
import { DEFAULT_AUTHORIZED_PATH, DEFAULT_UNAUTHORIZED_PATH } from "../router/guards";
import router from "../router/index";

export default class Router {

    /**
     * Returns back in history.
     *
     * @return {void}
     */
    public static back(): void {
        router.back();
    }

    /**
     * Redirects the user to given route or, if no route given, to a default
     * route depending on whether the user is authorized or not.
     *
     * @param {string|Object|Response|void|undefined} route
     * @return {void}
     */
    public static redirectUser(route?: string | Object | Response | void): void {
        if (!route || typeof route !== "string") {
            route = Router.getDefaultRedirectionPath();
        }

        router.push({ name: route as string });
    }

    /**
     * Creates a Vue Router path with a "redirect_to" query that stores
     * previous URL to redirect to.
     *
     * @param {string} routeName
     * @return {Object}
     */
    public static intended(routeName: string): object {
        return {
            name: routeName,
            query: {
                redirect_to: Router.getDefaultRedirectionPath()
            }
        }
    }

    /**
     * Returns the default redirection path depending on if the user
     * is authorized or not.
     *
     * @return {string}
     */
    public static getDefaultRedirectionPath(): string {
        return Auth.check() ? DEFAULT_AUTHORIZED_PATH : DEFAULT_UNAUTHORIZED_PATH;
    }

    /**
     * Returns the query string from the URL.
     *
     * @return {string|undefined}
     */
    public static getQuery(): string | undefined {
        const query = Router.getQueryParam("q");

        return query ? query as string : undefined;
    }

    /**
     * Returns the page number from the URL.
     *
     * @return {number|undefined}
     */
    public static getPage(): number | undefined {
        return Router.getNumberQueryParam("page");
    }

    /**
     * Returns the district id number from the URL.
     *
     * @return {number|undefined}
     */
    public static getDistrictId(): number | undefined {
        return Router.getNumberQueryParam("district_id");
    }

    /**
     * Returns the unit id number from the URL.
     *
     * @return {number|undefined}
     */
    public static getUnitId(): number | undefined {
        return Router.getNumberQueryParam("unit_id");
    }

    /**
     * Returns the archived value from the URL.
     *
     * @return {boolean|undefined}
     */
    public static getArchived(): boolean | undefined {
        const archived = Router.getQueryParam("archived");

        if (typeof archived === "string") {
            return archived === "1";
        }

        return undefined;
    }

    /**
     * Returns the query param parsed to integer.
     *
     * @private
     * @param {string} param
     * @return {number|undefined}
     */
    private static getNumberQueryParam(param: string): number | undefined {
        const value = Router.getQueryParam(param);

        return value ? parseInt(value as string) : undefined;
    }

    /**
     * Returns query param's value from the current URL.
     *
     * @param {string} param
     * @return {string|(string|null)[]}
     */
    public static getQueryParam(param: string): string | (string | null)[] {
        return router.currentRoute.query[param];
    }

    /**
     * Sets the page in the URL query attributes.
     *
     * @param {number} page
     * @return {Promise<Route>}
     */
    public static setPage(page: number): Promise<Route | void> {
        return Router.setQueryParam("page", page.toString());
    }

    /**
     * Sets the query string in the URL query attributes.
     *
     * @param {string} query
     * @return {Promise<Route>}
     */
    public static setQuery(query: string = ""): Promise<Route | void> {
        return Router.setQueryParams({
            q: query,
            page: undefined
        });
    }

    /**
     * Sets the district id in the URL query attributes.
     *
     * @param {number|null} id
     * @return {Promise<Route>}
     */
    public static setDistrictId(id: number | null): Promise<Route | void> {
        return Router.setQueryParams({
            district_id: (id || "").toString(),
            unit_id: undefined,
            page: undefined
        });
    }

    /**
     * Sets the unit id in the URL query attributes.
     *
     * @param {number|null} id
     * @return {Promise<Route|void>}
     */
    public static setUnitId(id: number | null): Promise<Route | void> {
        return Router.setQueryParams({
            unit_id: (id || "").toString(),
            page: undefined
        });
    }

    /**
     * Sets the archived value in the URL query attributes.
     *
     * @param {boolean} archived
     * @return {Promise<Route|void>}
     */
    public static setArchived(archived: boolean): Promise<Route | void> {
        return Router.setQueryParams({
            archived: archived ? "1" : "0",
            page: undefined
        });
    }

    /**
     * Sets the query param in the URL query attributes.
     *
     * @param {string} param
     * @param {string} value
     * @return {Promise<Route>}
     */
    public static setQueryParam(param: string, value: string = ""): Promise<Route | void> {
        // We want to omit redundant location changes when the given
        // query param has exactly the same value as the one in the URL
        // or when the URL doesn't have that query param and given value
        // is an empty string
        if (router.currentRoute.query[param] === value || (!router.currentRoute.query[param] && !value)) {
            return Promise.resolve();
        }

        // We push new route and return the promise
        return router.push({
            query: {
                ...router.currentRoute.query,
                [param]: value || undefined
            }
        });
    }

    /**
     * Sets multiple query params in the URL query attributes at once.
     *
     * @param {Dictionary<string>} params
     * @return {Promise<Route>}
     */
    public static setQueryParams(params: Dictionary<string|(string | null)[]|null|undefined>): Promise<Route | void> {
        // We map all keys into the boolean telling if that key is repeated
        // in the current url query
        const keysRepeatedInUrl = Object.keys(params).map((key: string) => {
            const queryParam = router.currentRoute.query[key];

            return (
                queryParam === params[key] ||
                (!queryParam && !params[key])
            );
        });

        // We then check whether every value is true
        const everyKeyRepeatedInUrl = keysRepeatedInUrl.every((value) => value);

        // We want to omit redundant location changes when given query params
        // have exactly the same values as the ones in the url
        if (everyKeyRepeatedInUrl) {
            return Promise.resolve();
        }

        // We push new route and return the promise
        return router.push({
            query: {
                ...router.currentRoute.query,
                ...params
            }
        });
    }

    /**
     * Returns the current route name.
     *
     * @return {string}
     */
    public static getCurrentRouteName(): string {
        return router.currentRoute.name || "";
    }

    /**
     * Checks whether the current route name is as given.
     *
     * @param {string} name
     * @return {boolean}
     */
    public static isRouteName(name: string): boolean {
        return (this.getCurrentRouteName() === name);
    }

}
