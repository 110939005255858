import Model from "./Model";
import Permission from "./Permission";
import Role from "./Role";

export default class RolePermissionScope extends Model {

    /**
     * The name that is going be used as module name in Vuex Store.
     *
     * @type string
     */
    public static entity = "role_permission_scopes";

    /**
     * The definition of the fields of the model and its relations.
     *
     * @return Object
     */
    public static fields() {
        return {
            id: this.number(0),
            role_id: this.number(0),
            role: this.belongsTo(Role, "role_id"),
            permission_id: this.number(0),
            permission: this.belongsTo(Permission, "permission_id"),
            scope: this.string(""),
            values: this.string(""),
            created_at: this.string(null).nullable(),
            updated_at: this.string(null).nullable()
        };
    }

    /**
     * ID of the permission.
     *
     * @type number
     */
    public id!: number;

    /**
     * ID of the related role.
     *
     * @type string
     */
    public role_id!: number;

    /**
     * Role object related to the scope.
     *
     * @type Role
     */
    public role!: Role;

    /**
     * ID of the related permission.
     *
     * @type string
     */
    public permission_id!: number;

    /**
     * Permission object related to the scope.
     *
     * @type Permission
     */
    public permission!: Permission;

    /**
     * The scope.
     *
     * @type string
     */
    public scope!: string;

    /**
     * The array of values involved in the scope.
     *
     * @type string
     */
    public values!: string;

}
