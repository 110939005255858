














import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import VPageHeader from "./VPageHeader.vue";
import VPageMenu from "./VPageMenu.vue";

@Component({
    components: {
        VPageMenu,
        VPageHeader
    }
})
export default class VPageWindow extends Vue {

    @Prop({ required: true })
    public title!: string;

    @Prop({ required: true })
    public collapseId!: string;

    @Prop()
    public description?: string;

    @Prop({ default: "md" })
    public menuToggleable!: string | false;

}
