import { EVENT_NOTIFICATION_SUCCESS, EventBus } from "../event-bus";

export default class Toaster {

    /**
     * Emits event to create a success toast.
     *
     * @param {string} message
     * @param {string} title
     * @return {void}
     */
    public static success(message: string, title?: string): void {
        EventBus.$emit(EVENT_NOTIFICATION_SUCCESS, {
            title,
            message
        });
    }

}
