





















import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Unit from "../../models/Unit";

@Component
export default class UnitCardDetails extends Vue {

    @Prop({ required: true })
    public unit!: Unit;

}
