




















import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class TheWelcomeBar extends Vue {

    /**
     * Options for localized date format.
     *
     * @type {Intl.DateTimeFormatOptions}
     */
    public dateFormatOptions: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long"
    };

    /**
     * Whether to show the welcome message.
     *
     * @return {boolean}
     */
    public get displayWelcomeMessage(): boolean {
        const route = this.$route.name;
        const forbiddenRoutes = ["home", "status"];

        return this.isAuthorized() && !forbiddenRoutes.includes(route!);
    }

    /**
     * Returns authorized user's first name by extracting only
     * the first word of user's name.
     *
     * @private
     * @return string
     */
    private get authUserFirstName(): string {
        return this.authUser?.name.replace(/\s.*/gi, "") || "";
    }

    /**
     * Returns pretty-formatted last logged in date.
     *
     * @return {string}
     */
    public get lastLoggedIn(): string {
        if (this.authUser && this.authUser.last_logged_in) {
            return new Date(this.authUser.last_logged_in).toLocaleDateString(undefined, this.dateFormatOptions);
        }

        return "";
    }

}
