import Vue from "vue";
import Component from "vue-class-component";
import { EVENT_NEW_PENDING_REQUEST, EVENT_PENDING_REQUEST_DONE } from "../event-bus";

declare module "vue/types/vue" {
    interface Vue {
        hasPendingRequests: boolean;
    }
}

@Component
export default class ApiRequestMixin extends Vue {

    /**
     * Total number of application pending requests.
     *
     * @type number
     */
    private pendingRequests: number = 0;

    /**
     * On component created.
     *
     * @return void
     */
    public created(): void {
        this.$eventBus
            .$on(EVENT_NEW_PENDING_REQUEST, this.incrementPendingRequests)
            .$on(EVENT_PENDING_REQUEST_DONE, this.decrementPendingRequests);
    }

    /**
     * Before the component is destroyed.
     *
     * @return void
     */
    public beforeDestroy(): void {
        this.$eventBus
            .$off(EVENT_NEW_PENDING_REQUEST, this.incrementPendingRequests)
            .$off(EVENT_PENDING_REQUEST_DONE, this.decrementPendingRequests);
    }

    /**
     * Whether the application has any pending requests.
     *
     * @return boolean
     */
    public get hasPendingRequests(): boolean {
        return (this.pendingRequests > 0);
    }

    /**
     * Increments total pending requests number.
     *
     * @return void
     */
    public incrementPendingRequests(): void {
        this.pendingRequests++;
    }

    /**
     * Decrements total pending requests number.
     *
     * @return void
     */
    public decrementPendingRequests(): void {
        this.pendingRequests = Math.max(this.pendingRequests - 1, 0);
    }

}
