import { Response } from "@vuex-orm/plugin-axios";
import Vue from "vue";
import roles from "../../json/roles.json";
import Router from "../classes/Router";
import ServerErrorHandler from "../classes/ServerResponseHandler/ServerErrorHandler";
import echo from "../laravel-echo";
import Model from "./Model";
import RolePermissionScope from "./RolePermissionScope";

/**
 * Auth ORM model.
 *
 * This class is used to create a single object of authorization that will
 * be then stored in Vuex store. This object will later be used to authorize
 * the user throughout the application.
 */
export default class Auth extends Model {

    /**
     * Cookies key for authentication data.
     *
     * @type string
     */
    public static COOKIES_AUTH_KEY = "auth";

    /**
     * The name that is going be used as module name in Vuex Store.
     *
     * @type string
     */
    public static entity = "auth";

    /**
     * The definition of the fields of the model and its relations.
     *
     * @return {Object}
     */
    public static fields() {
        return {
            name: this.string(""),
            token: this.string(""),
            last_logged_in: this.string(null).nullable(),
            roles: this.attr([]),
            permissions: this.attr([]),
            scopes: this.attr([])
        };
    }

    /**
     * Performs an attempt to login the user using given email address and password.
     *
     * @param {string} email
     * @param {string} password
     * @return {void}
     */
    public static login(email: string, password: string): void {
        Auth.api()
            .post("/login", { email, password }, { save: false })
            .then(Auth.saveAuthorizationData)
            .then(Router.redirectUser)
            .catch(ServerErrorHandler.handle);
    }

    /**
     * Deletes the authorization data and logs the user out.
     *
     * @return {void}
     */
    public static logout(): void {
        Auth.deleteAll().then(() => {
            Vue.$cookies.remove(Auth.COOKIES_AUTH_KEY);

            Router.redirectUser({ path: "home" });
        });
    }

    /**
     * Creates and stores a new Auth model using cookies.
     *
     * @return {void}
     */
    public static async authorizeWithCookies(): Promise<void> {
        const cookie = Vue.$cookies.get(Auth.COOKIES_AUTH_KEY);

        if (cookie) {
            await Auth.create({ data: cookie });
        }

        return Promise.resolve();
    }

    /**
     * Checks whether the user is authorized.
     *
     * @return {boolean}
     */
    public static check(): boolean {
        return this.authorizedByModel() || this.authorizedByCookies();
    }

    /**
     * Checks whether the user is a super administrator.
     *
     * @return {boolean}
     */
    public static isSuperAdmin(): boolean {
        const user = Auth.user();

        if (!user) {
            return false;
        }

        return user.roles.includes(roles.super_administrator);
    }

    /**
     * Checks if the user has given permission.
     *
     * @param {string} permission
     * @return {boolean}
     */
    public static can(permission: string): boolean {
        return Auth.isSuperAdmin() || Auth.hasPermission(permission);
    }

    /**
     * Returns current auth token (if exists).
     *
     * @return {string|undefined}
     */
    public static token(): string | undefined {
        return Auth.user()?.token;
    }

    /**
     * Checks explicitly if the user has given permission in permissions array.
     *
     * @param {string} permission
     * @return {boolean}
     */
    private static hasPermission(permission: string): boolean {
        const user = Auth.user();

        if (!user) {
            return false;
        }

        return user.permissions.includes(permission);
    }

    /**
     * Returns auth data that come either from stored object
     * or from the cookie.
     *
     * @return {Auth|null}
     */
    public static user(): Auth | null {
        if (Auth.exists()) {
            return Auth.first();
        }

        return Vue.$cookies.get(Auth.COOKIES_AUTH_KEY) as Auth;
    }

    /**
     * Checks if there exists an Auth model that authorizes the user.
     *
     * @private
     * @return {boolean}
     */
    private static authorizedByModel(): boolean {
        const auth = Auth.first();

        return !!auth?.token;
    }

    /**
     * Checks if there exists a cookie that authorizes the user.
     *
     * @private
     * @return {boolean}
     */
    private static authorizedByCookies(): boolean {
        const cookie = Vue.$cookies.get(Auth.COOKIES_AUTH_KEY) as Auth;

        return !!cookie?.token;
    }

    /**
     * Saves authorization data token and information in cookies and
     * stores in Vuex Store.
     *
     * @private
     * @param {Response} response
     * @return {Promise<void>}
     */
    private static saveAuthorizationData(response: Response): Promise<void> {
        return Auth.deleteAll().then(() => {
            response.save().then(() => {
                Vue.$cookies.set(Auth.COOKIES_AUTH_KEY, response.response.data);
                echo.connector.pusher.config.auth.headers["Authorization"] = "Bearer " + response.response.data.token;
            });
        });
    }

    /**
     * Name of the authorized user.
     *
     * @type string
     */
    public name!: string;

    /**
     * Authorization token.
     *
     * @type string
     */
    public token!: string;

    /**
     * The date of last log in.
     *
     * @type string
     */
    public last_logged_in!: string;

    /**
     * Array of roles names.
     *
     * @type string[]
     */
    public roles!: string[];

    /**
     * Array of permissions names.
     *
     * @type string[]
     */
    public permissions!: string[];

    /**
     * Array of permissions scopes.
     *
     * @type RolePermissionScope[]
     */
    public scopes!: RolePermissionScope[];

}
