































import Component, { mixins } from "vue-class-component";
import TwoColumns from "../../components/layouts/TwoColumns.vue";
import VButton from "../../components/VButton.vue";
import VPageWindow from "../../components/VPage/VPageWindow.vue";
import PaginationInterface from "../../interfaces/PaginationInterface";
import PaginationMixin from "../../mixins/PaginationMixin";
import SearchingMixin from "../../mixins/SearchingMixin";
import Pagination from "../../models/Pagination";
import Role from "../../models/Role";
import RoleCards from "./RoleCards.vue";
import RoleEmpty from "./RoleEmpty.vue";

@Component({
    components: {
        TwoColumns,
        RoleEmpty,
        RoleCards,
        VPageWindow,
        VButton
    }
})
export default class Roles extends mixins(PaginationMixin, SearchingMixin) {

    /**
     * On component created.
     *
     * @return {Promise<void>}
     */
    public async created(): Promise<void> {
        return this.withLoading(async () => {
            await Role.index();

            this.firstSearch = true;
        });
    }

    /**
     * Returns an array of roles.
     *
     * @return {Role[]}
     */
    public get roles(): Role[] {
        return Role.orderByName();
    }

    /**
     * Returns pagination data.
     *
     * @return {PaginationInterface}
     */
    public get pagination(): PaginationInterface {
        return Pagination.for(Role);
    }

    /**
     * Handles the search event.
     *
     * @return {Promise<void>}
     */
    public async onSearch(): Promise<void> {
        await Role.index();
    }

    /**
     * Handles the page change.
     *
     * @return {Promise<void>}
     */
    public async onPageChange(): Promise<void> {
        await Role.index();
    }

}
