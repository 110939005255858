
























































import Component, { mixins } from "vue-class-component";
import VButton from "../../components/VButton.vue";
import FormMixin, { SubmittedDataInterface } from "../../mixins/FormMixin";
import ContributionAmount from "../../models/ContributionAmount";
import ContributionAmountInput from "../../components/VueFormulate/ContributionAmount.vue";
import Setting from "../../models/Setting";

@Component({
    components: {
        VButton,
        ContributionAmountInput
    }
})
export default class SettingsAmounts extends mixins(FormMixin) {

    /**
     * The form data.
     *
     * @type {Object}
     */
    public formData = {
        amounts: [] as any[]
    };

    /**
     * On component created.
     *
     * @return {Promise<void>}
     */
    public async created(): Promise<void> {
        await this.withLoading(async () => {
            await ContributionAmount.index();

            const settings = Setting.getSettingsObject();
            const amounts = ContributionAmount.getAmountsObject();

            const yearStart = settings["CONTRIBUTIONS_YEAR_START"];
            const yearEnd = settings["CONTRIBUTIONS_YEAR_END"];

            const newAmounts = [];

            for (let year = yearStart; year <= yearEnd; year++) {
                newAmounts.push({
                    year,
                    quarter1: amounts[year] ? amounts[year].quarter1 : 0,
                    quarter2: amounts[year] ? amounts[year].quarter2 : 0,
                    quarter3: amounts[year] ? amounts[year].quarter3 : 0,
                    quarter4: amounts[year] ? amounts[year].quarter4 : 0
                });
            }

            this.formData.amounts = newAmounts;
        });
    }

    /**
     * Whether the form is in edit mode.
     *
     * @return {boolean}
     */
    public get isEditForm(): boolean {
        return true;
    }

    /**
     * Returns the data for submitting a form.
     *
     * @return {SubmittedDataInterface}
     */
    public getSubmittedData(): SubmittedDataInterface {
        const { amounts } = this.formData;

        return {
            model: ContributionAmount,
            data: {
                amounts
            }
        };
    }

}
