

















import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class LastChargeDay extends Vue {

    /**
     * Custom validation messages.
     *
     * @type {Object}
     */
    public validationMessages = {
        between: () => this.$lang("Ostatni dzień naliczania składki musi być dniem miesiąca pomiędzy 1 a 31.")
    };

}
