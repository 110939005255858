










































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ResourceDropdownMenu from "../../components/ResourceDropdownMenu.vue";
import VIcon from "../../components/VIcon.vue";
import Role from "../../models/Role";
import User from "../../models/User";
import UserCardDetails from "./UserCardDetails.vue";

@Component({
    components: {
        UserCardDetails,
        ResourceDropdownMenu,
        VIcon
    }
})
export default class UserCard extends Vue {

    @Prop({ required: true })
    public user!: User;

    /**
     * Returns the full class attribute for user card.
     *
     * @return {string[]}
     */
    public get userCardClass(): string[] {
        return [
            "user-card",
            "text-light",
            "bg-success-transparent",
            "my-3",
            "shadow-sm"
        ];
    }

    /**
     * Returns dropdown id string.
     *
     * @return {string}
     */
    public get dropdownId(): string {
        return "user-card-" + this.user.id + "-dropdown";
    }

    /**
     * Whether to show the dropdown menu.
     *
     * @return {boolean}
     */
    public get showMenu(): boolean {
        return this.can(this.allPermissions.user.edit) || this.can(this.allPermissions.user.delete);
    }

    /**
     * Handles "edit user" button click.
     *
     * @return {void}
     */
    public onUserEdit(): void {
        this.redirectTo("users.edit", this.user.id);
    }

    /**
     * Handles "delete user" button click.
     *
     * @return void
     */
    public onUserDelete(): void {
        const { id, name } = this.user;
        const question = this.$lang(
            "Czy na pewno chcesz usunąć użytkownika \":name\"? Pamiętaj, że możesz również dezaktywować jego konto przez odznaczenie opcji \"Aktywny\". Wówczas użytkownik nie będzie mógł się zalogować, ale jego dane nie zostaną utracone.",
            { name }
        );

        this.confirmationModal({
            question,
            onConfirm: () => User.deleteRemote(id)
        });
    }

}
