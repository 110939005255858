




















import Vue from "vue";
import Component from "vue-class-component";
import VPageWindow from "../../../components/VPage/VPageWindow.vue";
import DistrictForm from "./DistrictForm.vue";

@Component({
    components: {DistrictForm, VPageWindow }
})
export default class Index extends Vue {

    /**
     * Returns the page window title.
     *
     * @return {string}
     */
    public get title(): string {
        const param = this.routeParam("district");

        return param
            ? this.$lang("Edytuj hufiec")
            : this.$lang("Dodaj hufiec");
    }

    /**
     * Returns the page window description.
     *
     * @return {string}
     */
    public get description(): string {
        const param = this.routeParam("district");

        return param
            ? this.$lang("Popraw dane istniejącego hufca.")
            : this.$lang("Określ dane nowego hufca.");
    }

}
