import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { EVENT_NEW_PENDING_REQUEST, EVENT_PENDING_REQUEST_DONE, EventBus } from "./event-bus";
import Auth from "./models/Auth";

// We create fully configured axios instance
const service = axios.create({
    baseURL: process.env.MIX_APP_URL + "/api",
    url: "/"
});

// We create a callback for axios request that will automatically increment
// the number of pending requests
service.interceptors.request.use((config: AxiosRequestConfig) => {
    EventBus.$emit(EVENT_NEW_PENDING_REQUEST);

    // We append the authorization token to the request
    config.headers["Authorization"] = "Bearer " + Auth.token();

    // We return the configuration
    return config;
});

// We create a callback for axios response that will automatically decrement
// the number of pending requests
service.interceptors.response.use((response: AxiosResponse) => {
    EventBus.$emit(EVENT_PENDING_REQUEST_DONE);

    // We need to resolve the response before returning
    // in order to maintain the Promise chain
    return Promise.resolve(response);
}, (error: AxiosError) => {
    EventBus.$emit(EVENT_PENDING_REQUEST_DONE);

    // We need to reject the response before returning
    // in order to maintain the Promise chain
    return Promise.reject(error);
});

export default service;
