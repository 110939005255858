






























import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import VButton from "../VButton.vue";
import VNavbarToggle from "../VNavbarToggle.vue";
import VPageMenuSearch from "../VPage/VPageMenuSearch.vue";
import VSection from "../VSection.vue";

@Component({
    components: {
        VNavbarToggle,
        VButton,
        VPageMenuSearch,
        VSection
    }
})
export default class TwoColumns extends Vue {

    @Prop({ default: true })
    public showSearchInput!: boolean;

    /**
     * Whether to show the left column or not.
     *
     * @return {boolean}
     */
    public get showLeftColumn(): boolean {
        const menuSlot = this.$slots["menu"];

        return (Array.isArray(menuSlot) && menuSlot.length > 0);
    }

}
