











import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Router from "../classes/Router";
import VResourceNotFound from "../components/VResourceNotFound.vue";

@Component({
    components: { VResourceNotFound }
})
export default class ScoutEmpty extends Vue {

    @Prop({ required: true })
    public messagesEmpty!: string[];

    @Prop({ required: true })
    public messagesNotFound!: string[];

    @Prop({ required: true })
    public leadsEmpty!: string[];

    @Prop({ required: true })
    public leadsNotFound!: string[];

    /**
     * Array of icons to display when the resource list is empty.
     *
     * @private
     * @type string[]
     */
    private iconsEmpty = [
        "fas fa-sad-cry",
        "fas fa-sad-tear",
        "fas fa-frown",
        "fas fa-frown-open",
        "fas fa-heart-broken"
    ];

    /**
     * Array of 404 icons to display.
     *
     * @private
     * @type string[]
     */
    private iconsNotFound = [
        ...this.iconsEmpty,
        "fas fa-cat",
        "fas fa-dizzy",
        "fas fa-thumbs-down"
    ];

    /**
     * Returns random icon class string from the array.
     *
     * @return {string}
     */
    public randomIcon(): string {
        const icons = !Router.getQuery()
            ? this.iconsEmpty
            : this.iconsNotFound;

        return this.$lodash.sample(icons)!;
    }

    /**
     * Returns random message from the array.
     *
     * @return {string}
     */
    public randomMessage(): string {
        const query = Router.getQuery();
        const messages = !query
            ? this.messagesEmpty
            : this.messagesNotFound;

        return this.$lodash
            .sample(messages)!
            .replace(":search", query!);
    }

    /**
     * Returns random lead from the array.
     *
     * @return {string}
     */
    public randomLeadMessage(): string {
        const query = Router.getQuery();
        const leads = !query
            ? this.leadsEmpty
            : this.leadsNotFound;

        return this.$lodash
            .sample(leads)!
            .replace(":search", query!);
    }

}
