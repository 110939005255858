import { Model } from "@vuex-orm/core";
import Fields from "@vuex-orm/core/lib/model/contracts/Fields";
import { Config } from "@vuex-orm/plugin-axios";
import ContributionsProfile from "../classes/ContributionsProfile";
import ContributionAmount from "./ContributionAmount";

export default class Status extends Model {

    /**
     * Requests scout's contributions status from the remote server.
     *
     * @param {string} registrationNumber
     * @return {Promise<void>}
     */
    public static async index(registrationNumber: string): Promise<Status | void> {
        const config: Config = {
            save: false
        };

        return this.api()
            .get("/status/" + registrationNumber, config)
            .then((response) => new Status(response.response.data.status))
            .catch(() => {});
    }

    /**
     * The name that is going be used as module name in Vuex Store.
     *
     * @type {string}
     */
    public static entity = "status";

    /**
     * The definition of the fields of the model and its relations.
     *
     * @return {Fields}
     */
    public static fields(): Fields {
        return {
            amounts: this.attr({}, (data: any) => ContributionAmount.from(data)),
            profile: this.attr({}, (data: any) => ContributionsProfile.from(data)),
            remarks: this.string(null).nullable(),
            last_modified: this.string(null).nullable(),
            transfer_details: this.string(null).nullable()
        };
    }

    /**
     * The array of contributions amounts.
     *
     * @type {ContributionAmount[]}
     */
    public amounts!: ContributionAmount[];

    /**
     * The contributions profile object.
     *
     * @type {ContributionsProfile}
     */
    public profile!: ContributionsProfile;

    /**
     * Scouts remarks.
     *
     * @type {string|null}
     */
    public remarks!: string | null;

    /**
     * The date of last contributions modification in scout's district.
     *
     * @type {string|null}
     */
    public last_modified!: string | null;

    /**
     * The scout's district transfer details.
     *
     * @type {string|null}
     */
    public transfer_details!: string | null;

}
