import Vue from "vue";
import { NavigationGuard } from "vue-router";
import RouteInterface from "../interfaces/RouteInterface";
import { hasPermission } from "../router/guards";

export default class Route implements RouteInterface {

    /**
     * Creates new route with given path.
     *
     * @param {string} path
     * @return Route
     */
    public static get(path: string): Route {
        return new Route(path);
    }

    /**
     * The path of the route in VueRouter.
     *
     * @type string
     */
    public path: string;

    /**
     * The name of the route in VueRouter.
     *
     * @type string
     */
    public name?: string;

    /**
     * The vue component to render.
     *
     * @type Vue
     */
    public component?: typeof Vue;

    /**
     * The navigation guard for the route.
     *
     * @type NavigationGuard
     */
    public beforeEnter?: NavigationGuard;

    /**
     * Route constructor.
     *
     * @param {string} path
     */
    public constructor(path: string) {
        this.path = path;
    }

    /**
     * Sets the route name.
     *
     * @param name
     * @return RouteInterface
     */
    public named(name: string): Route {
        this.name = name;

        return this;
    }

    /**
     * Sets the Vue component to be render upon
     * visiting the route.
     *
     * @param {Vue} component
     * @return RouteInterface
     */
    public renders(component: typeof Vue): Route {
        this.component = component;

        return this;
    }

    /**
     * Sets the navigation guard that runs before
     * entering the route.
     *
     * @param {NavigationGuard} navigationGuard
     * @return RouteInterface
     */
    public guard(navigationGuard: NavigationGuard): Route {
        this.beforeEnter = navigationGuard;

        return this;
    }

    /**
     * Sets the navigation guard that runs before
     * entering the route and required the user
     * to have given permission.
     *
     * @param {string} permission
     * @return RouteInterface
     */
    public permission(permission: string): Route {
        this.beforeEnter = hasPermission(permission);

        return this;
    }

}
