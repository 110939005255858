







































import { BModal } from "bootstrap-vue";
import Vue from "vue";
import Component from "vue-class-component";
import { EVENT_ERROR_INPUT } from "../event-bus";
import VButton from "./VButton.vue";
import VIcon from "./VIcon.vue";

@Component({
    components: {
        VButton,
        VIcon,
        BModal
    }
})
export default class TheInputErrorModal extends Vue {

    /**
     * Whether to show the modal.
     *
     * @type boolean
     */
    public show = false;

    /**
     * Array of error messages.
     *
     * @type string[]
     */
    public errors: string[] = [];

    /**
     * On component created
     *
     * @return void
     */
    public created(): void {
        this.$eventBus.$on(EVENT_ERROR_INPUT, this.onInputError);
    }

    /**
     * Before the component is destroyed.
     *
     * @return void
     */
    public beforeDestroy(): void {
        this.$eventBus.$off(EVENT_ERROR_INPUT, this.onInputError);
    }

    /**
     * Handles the input error.
     *
     * @private
     * @param {string[]} errors
     * @return void
     */
    private onInputError(errors: string[]): void {
        this.errors = errors;
        this.show = true;
    }

    /**
     * Returns modal title.
     *
     * @private
     * @return string
     */
    private getModalTitle(): string {
        return this.$lang("Nieprawidłowe dane");
    }

    /**
     * Returns modal message indicating input error.
     *
     * @private
     * @return string
     */
    private getModalMessage(): string {
        return this.$lang("Zapoznaj się z poniższymi komunikatami i skoryguj błędy.");
    }

}
