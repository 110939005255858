import { Model as VuexORMModel } from "@vuex-orm/core";
import { Config, Response } from "@vuex-orm/plugin-axios";
import Router from "../classes/Router";
import ServerErrorHandler from "../classes/ServerResponseHandler/ServerErrorHandler";
import ServerResponseHandler from "../classes/ServerResponseHandler/ServerResponseHandler";
import Pagination from "./Pagination";

/**
 * Model class that extends the Vuex ORM Model with several methods
 * useful for Laravel <-> Vue integration.
 */
export default class Model extends VuexORMModel {

    /**
     * The name that is going be used as module name in Vuex Store.
     * It is not really useful in context of this model as it should be
     * extended anyway, so the entity can be any string.
     *
     * @type string
     */
    public static entity = "model";

    /**
     * Returns route for resource index.
     *
     * @return {string}
     */
    public static getIndexRoute(): string {
        return this.entity;
    }

    /**
     * Requests resource from the server and stores the results
     * in the Vuex store.
     *
     * @param {Object} params
     * @return {Promise<void|Response>}
     */
    public static index(params?: any): Promise<void | Response> {
        const config = {
            params: {
                q: Router.getQuery() || "",
                page: Router.getPage() || 1,
                ...params
            },
            dataKey: this.entity,
            persistBy: "create"
        } as Config;

        return this.api()
            .get("/" + this.getIndexRoute(), config)
            .then((response) => Pagination.fromResponse(this.entity, response))
            .catch(ServerErrorHandler.handle);
    }

    /**
     * Sends request to create specific resource. It expects to receive
     * additional information about creating the resource, especially
     * related to permitted actions and available resources.
     *
     * @return {Promise<Model>}
     */
    public static createRemote<T extends typeof Model>(this: T): Promise<any> {
        return this.api()
            .get(`/${this.entity}/create`, { save: false })
            .then((response) => response.response.data)
            .catch(ServerErrorHandler.handleAndGoBack);
    }

    /**
     * Sends request to store the resource on the remote server.
     *
     * @param {Object} data
     * @return {Promise<void>}
     */
    public static storeRemote<T extends typeof Model>(this: T, data: any): Promise<void> {
        return this.api()
            .post(`/${this.entity}`, data, { save: false })
            .then(ServerResponseHandler.handle)
            .then(() => Router.redirectUser(this.entity))
            .catch(ServerErrorHandler.handle);
    }

    /**
     * Sends request to get the specific resource from the remote server.
     *
     * @param {number|string} id
     * @return {Promise<Model>}
     */
    public static getRemote<T extends typeof Model>(this: T, id: number | string): Promise<InstanceType<T>> {
        return this.api()
            .get(`/${this.entity}/${id}`, { save: false })
            .then((response) => response.response.data)
            .catch(ServerErrorHandler.handle);
    }

    /**
     * Sends request to get specific resource data for editing.
     *
     * @param {number|string} id
     * @return {Promise<Model>}
     */
    public static editRemote<T extends typeof Model>(this: T, id: number | string): Promise<InstanceType<T>> {
        return this.api()
            .get(`/${this.entity}/${id}/edit`, { save: false })
            .then((response) => response.response.data)
            .catch(ServerErrorHandler.handleAndGoBack);
    }

    /**
     * Sends request to patch specific resource with given data.
     *
     * @param {number|string} id
     * @param {Object} data
     * @return {Promise<void>}
     */
    public static updateRemote<T extends typeof Model>(this: T, id: number | string, data: any): Promise<void> {
        return this.api()
            .patch(`/${this.entity}/${id}`, data, { save: false })
            .then(ServerResponseHandler.handle)
            .catch(ServerErrorHandler.handle);
    }

    /**
     * Requests resource deletion and removes it from the store.
     *
     * @param {number} id
     * @return {Promise<void|Response>}
     */
    public static deleteRemote(id: number): Promise<void | Response> {
        return this.api()
            .delete(`/${this.entity}/${id}`, { delete: id })
            .then(ServerResponseHandler.handle)
            .catch(ServerErrorHandler.handle);
    }

    /**
     * On "store" echo event.
     *
     * @return {void}
     */
    public static onStore(): void {
        if (Router.isRouteName(this.entity)) {
            this.index();
        }
    }

    /**
     * On "update" echo event.
     *
     * @return {void}
     */
    public static onUpdate(ModelClass: typeof Model, data: any): void {
        // The data key for updated object will be the
        // class name lower cased by default
        const dataKey = ModelClass.name.toLowerCase();

        // We get the model data from the data response
        const modelData = data[dataKey];

        // We try to find the model in existing store
        const foundModel = this.query()
            .where("id", modelData.id)
            .first();

        // We update the model we found
        if (foundModel) {
            foundModel.$update({
                ...modelData
            });
        }
    }

    /**
     * On "delete" echo event.
     *
     * @return {void}
     */
    public static onDelete(): void {
        if (Router.isRouteName(this.entity)) {
            this.index();
        }
    }

    /**
     * Returns all the models sorted by their names.
     *
     * @return {Model[]}
     */
    public static orderByName<T extends typeof Model>(this: T): InstanceType<T>[] {
        return this.query()
            .orderBy("name")
            .get();
    }

    /**
     * Returns the first resource found (if exists).
     *
     * @return {Model|null}
     */
    public static first<T extends typeof Model>(this: T): InstanceType<T> | null {
        return this.query().first();
    }

}
