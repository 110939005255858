










import Vue from "vue";
import Component from "vue-class-component";
import HomeFooter from "./HomeFooter.vue";
import HomeHeader from "./HomeHeader.vue";
import HomeMain from "./HomeMain.vue";

@Component({
    components: {
        HomeFooter,
        HomeMain,
        HomeHeader
    }
})
export default class Home extends Vue {}
