











































































import { Model } from "@vuex-orm/core";
import { Response } from "@vuex-orm/plugin-axios";
import { polishPlurals } from "polish-plurals";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import ServerErrorHandler from "../classes/ServerResponseHandler/ServerErrorHandler";
import PaginationInterface from "../interfaces/PaginationInterface";
import VFormulateSelectPagination from "./VueFormulate/partials/VFormulateSelectPagination.vue";
import VIcon from "./VIcon.vue";
import VLoading from "./VLoading.vue";

/**
 * Simple interface for select option.
 *
 * @interface
 */
export interface Option {
    id: string;
    value: string;
    label: string;
}

@Component({
    components: {
        VFormulateSelectPagination,
        VLoading,
        VIcon
    }
})
export default class VFormulateSelectPaginated extends Vue {

    @Prop({ required: true })
    public context!: any;

    @Prop({ default: 5 })
    public perPage!: number;

    @Watch("context.value")
    private onContextValueChanged(value: any): void {
        if (!this.multiselect && this.$lodash.has(value, "label")) {
            this.selectedOption = value as Option;
        }
    }

    /**
     * The search phrase.
     *
     * @type {string}
     */
    public search: string = "";

    /**
     * The select options.
     *
     * @type {Array}
     */
    public options: Option[] = [
        //
    ];

    /**
     * Selected option for single-select mode.
     *
     * @type Option
     */
    public selectedOption: Option|null = null;

    /**
     * The options pagination.
     *
     * @type {PaginationInterface}
     */
    private pagination: PaginationInterface = {} as PaginationInterface;

    /**
     * Returns the Vuex ORM Model passed as attribute.
     *
     * @return {Model}
     */
    public get model(): typeof Model {
        return this.context.attributes["model"];
    }

    /**
     * Returns the value key of the model entity.
     *
     * @return {string}
     */
    public get modelValueKey(): string {
        return this.context.attributes["model-value-key"];
    }

    /**
     * Returns the label key of the model entity.
     *
     * @return {string}
     */
    public get modelLabelKey(): string {
        return this.context.attributes["model-label-key"];
    }

    /**
     * Returns the permission related to the model action.
     *
     * @return {string|undefined}
     */
    public get modelPermission(): string | undefined {
        return this.context.attributes["model-permission"];
    }

    /**
     * Returns the multiselect prop value.
     *
     * @return {boolean}
     */
    public get multiselect(): boolean {
        const attribute = this.context.attributes["multiselect"];

        return (typeof attribute !== "undefined" && attribute !== false);
    }

    /**
     * Returns the text displayed on the toggle button.
     *
     * @return {string}
     */
    public get text(): string {
        const { model } = this.context;

        if (this.multiselect) {
            if (model && Array.isArray(model) && model.length > 0) {
                return this.$lang("Wybrano :amount :options", {
                    amount: model.length,
                    options: polishPlurals(
                        this.$lang("opcję"),
                        this.$lang("opcje"),
                        this.$lang("opcji"),
                        model.length
                    )
                });
            }
        } else if (model && this.selectedOption) {
            return this.selectedOption!.label;
        }

        return this.$lang("Wybierz");
    }

    /**
     * Returns dropdown toggle button class.
     *
     * @return {Array}
     */
    public get toggleClass(): any[] {
        return [
            "select-toggle",
            "mw-100",
            "overflow-hidden",
            "text-ellipsis",
            "text-left",
            "position-relative",
            { "selected": Array.isArray(this.context.model) && this.context.model.length > 0 }
        ];
    }

    /**
     * Returns the class for the whole option label.
     *
     * @return {Array}
     */
    public checkboxLabelClass(option: Option): any[] {
        return [
            "block-checkbox",
            { "active": this.isItemSelected(option.value) },
        ];
    }

    /**
     * Returns the class for the dropdown item.
     *
     * @return {Array}
     */
    public dropdownItemClass(option: Option): any[] {
        return [
            "paginated-dropdown-item",
            { "active": this.isItemSelected(option.value) }
        ];
    }

    /**
     * Handles actions when the dropdown is shown.
     *
     * @return {void}
     */
    private onDropdownShow(): void {
        if (!this.options || !this.options.length) {
            this.requestOptions();
        }
    }

    /**
     * Requests options from server and assigns the results
     * as options.
     *
     * @param {number} page
     * @return {void}
     */
    private requestOptions(page?: number): void {
        const { model } = this;

        model.api().get("/" + model.entity, {
            params: {
                q: this.search,
                page: page || 1,
                per_page: this.perPage,
                model_permission: this.modelPermission
            },
            save: false
        }).then((response: Response) => {
            const entities = response.response.data[model.entity] as Model[];

            this.options = entities.map((entity) => ({
                id: entity[this.modelValueKey as keyof typeof entity],
                value: entity[this.modelValueKey as keyof typeof entity],
                label: entity[this.modelLabelKey as keyof typeof entity]
            }) as Option);

            this.pagination = response.response.data["pagination"];
        }).catch(ServerErrorHandler.handle).then(() => {
            this.refreshDropdownPosition();
            this.setFocusOnSearchInput();
        });
    }

    /**
     * Returns class name for dropdown item.
     *
     * @param {string} value
     * @return {string|undefined}
     */
    public itemClass(value: string): string | undefined {
        return this.isItemSelected(value)
            ? "dropdown-item-selected"
            : undefined;
    }

    /**
     * Returns "key" attribute for dropdown item.
     *
     * @param {Option} option
     * @return {string}
     */
    public itemKey(option: Option): string {
        return this.model.entity + "-option-" + option.value;
    }

    /**
     * Checks whether option of given value is selected or not.
     *
     * @param {string} value The value of the option.
     * @return {boolean}
     */
    public isItemSelected(value: string|number): boolean {
        if (this.multiselect) {
            const { model } = this.context;

            return (model && Array.isArray(model) && model.includes(parseInt(value.toString())));
        }

        return (this.selectedOption !== null && this.selectedOption.value.toString() === value.toString());
    }

    /**
     * Handles clicking an select option for single select mode.
     *
     * @param {Option} option
     * @return {void}
     */
    public onSingleSelectItemClick(option: Option): void {
        this.context.model = option;

        this.selectedOption = option;

        this.$emit("change", option);

        this.refreshDropdownPosition();
    }

    /**
     * Handles clicking an select option.
     *
     * @param {Option} option
     * @return {void}
     */
    public onItemClick(option: Option): void {
        if (!this.multiselect) {
            this.onSingleSelectItemClick(option);
            return;
        }

        let newModel;

        if (this.context.model && Array.isArray(this.context.model)) {
            if (this.context.model.includes(option.value)) {
                newModel = this.context.model.filter((value: number) => value !== parseInt(option.value));
                this.context.model = newModel;
            } else {
                newModel = [...this.context.model, option.value];
                this.context.model = newModel;
            }
        } else {
            newModel = [option.value];
            this.context.model = newModel;
        }

        this.$emit("change", newModel);

        this.refreshDropdownPosition();
    }

    /**
     * Sets the focus on search input.
     *
     * @private
     * @return {void}
     */
    private setFocusOnSearchInput(): void {
        (this.$refs["searchInput"] as any).$el.focus();
    }

    /**
     * Refreshes the position of dropdown after dynamic changes
     * in its body content.
     *
     * @private
     * @return {void}
     */
    private refreshDropdownPosition(): void {
        // Nasty, nasty way to achieve the update of the position
        // of the dropdown menu, yet the update() method behaves
        // nothing like the code below so here we are.
        // window.scrollBy({ top: 1 });
        // window.scrollBy({ top: -1 });

        this.setFocusOnSearchInput();
    }

}
