































import Component, { mixins } from "vue-class-component";
import TwoColumns from "../../components/layouts/TwoColumns.vue";
import VButton from "../../components/VButton.vue";
import VPageWindow from "../../components/VPage/VPageWindow.vue";
import PaginationInterface from "../../interfaces/PaginationInterface";
import PaginationMixin from "../../mixins/PaginationMixin";
import SearchingMixin from "../../mixins/SearchingMixin";
import Pagination from "../../models/Pagination";
import Unit from "../../models/Unit";
import User from "../../models/User";
import UserCards from "./UserCards.vue";
import UserEmpty from "./UserEmpty.vue";

@Component({
    components: {
        VButton,
        TwoColumns,
        UserEmpty,
        UserCards,
        VPageWindow
    }
})
export default class Index extends mixins(PaginationMixin, SearchingMixin) {

    /**
     * On component created.
     *
     * @return {Promise<void>}
     */
    public async created(): Promise<void> {
        return this.withLoading(async () => {
            await User.index();

            this.firstSearch = true;
        });
    }

    /**
     * Returns the page description depending on
     * user's permissions.
     *
     * @return {string}
     */
    public get description(): string {
        const permissions = this.allPermissions;

        if (this.canAny([permissions.user.assign.district, permissions.user.assign.unit, permissions.user.assign.role])) {
            return this.$lang("Zarządzaj użytkownikami oraz ich uprawnieniami dostępu do platformy, a także przypisuj im hufce i jednostki.");
        } else if (this.can(permissions.user.edit)) {
            return this.$lang("Zarządzaj użytkownikami platformy.")
        } else if (this.can(permissions.user.create)) {
            return this.$lang("Przeglądaj i dodawaj nowych użytkowników platformy.");
        } else {
            return this.$lang("Przeglądaj użytkowników platformy.");
        }
    }

    /**
     * Returns an array of users.
     *
     * @return {Unit[]}
     */
    public get users(): User[] {
        return User.orderByName();
    }

    /**
     * Returns pagination data.
     *
     * @return {PaginationInterface}
     */
    public get pagination(): PaginationInterface {
        return Pagination.for(User);
    }

    /**
     * Handle searching the resource.
     *
     * @return {Promise<void>}
     */
    public async onSearch(): Promise<void> {
        await User.index();
    }

    /**
     * Handle the page change.
     *
     * @return {Promise<void>}
     */
    public async onPageChange(): Promise<void> {
        await User.index();
    }

}
