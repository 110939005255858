import Model from "./Model";

export default class District extends Model {

    /**
     * The name that is going be used as module name in Vuex Store.
     *
     * @type string
     */
    public static entity = "districts";

    /**
     * The definition of the fields of the model and its relations.
     *
     * @return Object
     */
    public static fields() {
        return {
            id: this.number(0),
            name: this.string(""),
            last_modified: this.string(null).nullable(),
            transfer_details: this.string(null).nullable(),
            users_count: this.number(0),
            units_count: this.number(0),
            scouts_count: this.number(0),
            created_at: this.string(null).nullable(),
            updated_at: this.string(null).nullable()
        };
    }

    /**
     * The ID of the district.
     *
     * @type number
     */
    public id!: number;

    /**
     * The name of the district.
     *
     * @type string
     */
    public name!: string;

    /**
     * District last modified date.
     *
     * @type string
     */
    public last_modified!: string;

    /**
     * District transfer details for subscriptions transactions.
     *
     * @type string
     */
    public transfer_details!: string;

    /**
     * The total number of users related to the district.
     *
     * @type number
     */
    public users_count!: number;

    /**
     * The total number of units that belong to the district.
     *
     * @type number
     */
    public units_count!: number;

    /**
     * The total number of scouts related to units that belong to the district.
     *
     * @type number
     */
    public scouts_count!: number;

    /**
     * District creation timestamp.
     *
     * @type string|null
     */
    public created_at!: string | null;

    /**
     * District update timestamp.
     *
     * @type string|null
     */
    public updated_at!: string | null;

}
