













import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component
export default class VFormulateDatepicker extends Vue {

    @Prop({ required: true })
    public context!: any;

    @Watch("context.value")
    public onContextValueChanged(date: string): void {
        this.context.model = date;
    }

    /**
     * On date change.
     *
     * @param {string|null} date
     * @return {void}
     */
    public onChange(date: string): void {
        this.context.model = date;
    }

}
