





























































import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import allScopes from "../../../../json/role_permission_scopes.json";
import VButton from "../../../components/VButton.vue";
import VIcon from "../../../components/VIcon.vue";
import District from "../../../models/District";
import Model from "../../../models/Model";
import Role from "../../../models/Role";
import Scout from "../../../models/Scout";
import Unit from "../../../models/Unit";
import User from "../../../models/User";
import permissionsScopes from "../../../utils/permissions-scopes";

@Component({
    components: {
        VIcon,
        VButton
    }
})
export default class RoleFormPermissionScope extends Vue {

    @Prop({ required: true })
    public category!: string;

    @Prop({ required: true })
    public scope!: any;

    @Prop({ required: true })
    public index!: number;

    @Prop({ required: true })
    public model!: any[];

    @Prop({ required: true })
    public permissionsModel!: string[];

    @Watch("permissionsModel")
    private onPermissionModelChanged(permissions: string[]): void {
        if (!permissions.includes(this.scope.permission)) {
            this.resetScopeValues();
        }
    }

    /**
     * All model objects dependent on permission category.
     *
     * @type Object
     */
    private modelObjects = {
        "role": Role,
        "user": User,
        "district": District,
        "unit": Unit,
        "scout": Scout
    };

    /**
     * Returns key attribute for model select component.
     *
     * @private
     * @return {string}
     */
    private get scopeModelSelectKey(): string {
        return "model-select-" + this.scope.permission + "-" + this.scope.scope;
    }

    /**
     * Returns all permissions of given category.
     *
     * @private
     * @return {Object}
     */
    private get permissions(): any {
        const { allPermissions, scope } = this;

        return allPermissions[scope.category as keyof typeof allPermissions];
    }

    /**
     * Whether to show the model select or not.
     *
     * @private
     * @return {boolean}
     */
    private get showModelSelect(): boolean {
        return [
            allScopes.ids,
            allScopes.roles,
            allScopes.referencing.district,
            allScopes.referencing.unit,
            allScopes.related.districts,
            allScopes.related.units
        ].includes(this.scope.scope);
    }

    /**
     * Converts permissions received as prop into array of dropdown options.
     *
     * @private
     * @return {Array}
     */
    private get options(): any {
        return [
            { value: "", label: this.$lang("Wybierz uprawnienie") },
            ...this.convertPermissionsRecursively(this.permissions, this.allPermissionsDescriptions)
                .filter(this.permissionHasScopes)
                .filter(this.permissionChecked)
        ];
    }

    /**
     * Converts permissions scopes into array of dropdown options.
     *
     * @private
     * @return {Array}
     */
    private get scopes(): any {
        const scopes = permissionsScopes[this.scope.permission] || [];

        return [
            { value: "", label: this.$lang("Wybierz zasięg") },
            ...scopes.map(this.scopeToOption).filter(this.permissionNotScoped)
        ];
    }

    /**
     * Returns Model object for dropdown select lists.
     *
     * @private
     * @return {Model}
     */
    private get modelObject(): typeof Model {
        const { modelObjects } = this;

        let modelClass = modelObjects[this.scope.category as keyof typeof modelObjects];

        switch (this.scope.scope) {
            case allScopes.ids:
                switch (this.scope.permission) {
                    case this.allPermissions.user.assign.role:
                        modelClass = modelObjects["role"];
                        break;
                    case this.allPermissions.user.assign.district:
                    case this.allPermissions.unit.assign.district:
                        modelClass = modelObjects["district"];
                        break;
                    case this.allPermissions.user.assign.unit:
                    case this.allPermissions.scout.assign.unit:
                        modelClass = modelObjects["unit"];
                        break;
                }
                break;
            case allScopes.roles:
                modelClass = modelObjects["role"];
                break;
            case allScopes.referencing.district:
            case allScopes.related.districts:
                modelClass = modelObjects["district"];
                break;
            case allScopes.referencing.unit:
            case allScopes.related.units:
                modelClass = modelObjects["unit"];
                break;
        }

        return modelClass;
    }

    /**
     * Checks whether given permission option has any scopes.
     *
     * @private
     * @param {Object} option
     * @return {boolean}
     */
    private permissionHasScopes(option: any): boolean {
        return (typeof permissionsScopes[option.value] !== "undefined");
    }

    /**
     * Checks whether given permission was checked or not.
     *
     * @private
     * @param {Object} option
     * @return {boolean}
     */
    private permissionChecked(option: any): boolean {
        return this.permissionsModel.includes(option.value);
    }

    /**
     * Checks whether given permission option is not already used.
     *
     * @private
     * @param {Object} option
     * @return {boolean}
     */
    private permissionNotScoped(option: any): boolean {
        return !this.model.find((scope) => {
            return (
                scope.category === this.category &&
                scope.permission === this.scope.permission &&
                scope.scope === option.value &&
                scope.id !== this.scope.id
            );
        });
    }

    /**
     * Deeply converts permissions object and flattens the result.
     *
     * @private
     * @param {object} permissions
     * @param {object} descriptions
     * @return {Array}
     */
    private convertPermissionsRecursively(permissions: any, descriptions: any): any {
        return this.$lodash.flatten(Object.values(permissions).map((permission: any) => {
            return typeof permission === "object"
                ? this.convertPermissionsRecursively(permission, descriptions)
                : {
                    value: permission,
                    label: descriptions[permission as keyof typeof descriptions] || permission
                }
        }));
    }

    /**
     * Converts scope string to dropdown option object.
     *
     * @private
     * @param {string} scope
     * @return {Object}
     */
    private scopeToOption(scope: string): Object {
        const { scopesDescriptions } = this;

        return {
            value: scope,
            label: scopesDescriptions[scope as keyof typeof scopesDescriptions] || scope
        };
    }

    /**
     * Resets all scope values.
     *
     * @private
     * @return {void}
     */
    private resetScopeValues(): void {
        this.scope.permission = "";
        this.scope.scope = "";
        this.scope.values = [];
    }

    /**
     * On permission value change.
     *
     * @private
     * @return {void}
     */
    private onPermissionChange(): void {
        this.scope.scope = "";
        this.scope.values = [];
    }

    /**
     * On scope value change.
     *
     * @private
     * @return {void}
     */
    private onScopeChange(): void {
        this.scope.values = [];
    }

    /**
     * Deletes the scope.
     *
     * @private
     * @return {void}
     */
    private deleteScope(): void {
        const index = this.model.findIndex((scope) => scope.id === this.scope.id);

        this.model.splice(index, 1);
    }

}
