








import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import VIcon from "./VIcon.vue";

@Component({
    components: { VIcon }
})
export default class VNavbarToggle extends Vue {

    @Prop({ required: true })
    public target!: string;

    /**
     * Returns the VIcon component class string.
     *
     * @private
     * @param {boolean} expanded
     * @return {string}
     */
    private iconClass(expanded: boolean): string {
        return expanded ? "fas fa-times" : "fas fa-bars";
    }

}
