







































import Component, { mixins } from "vue-class-component";
import Router from "../../classes/Router";
import TwoColumns from "../../components/layouts/TwoColumns.vue";
import VButton from "../../components/VButton.vue";
import VIcon from "../../components/VIcon.vue";
import VPageWindow from "../../components/VPage/VPageWindow.vue";
import DistrictSelect from "../../components/VueFormulate/DistrictSelect.vue";
import PaginationInterface from "../../interfaces/PaginationInterface";
import PaginationMixin from "../../mixins/PaginationMixin";
import SearchingMixin from "../../mixins/SearchingMixin";
import District from "../../models/District";
import Pagination from "../../models/Pagination";
import Unit from "../../models/Unit";
import UnitCards from "./UnitCards.vue";
import UnitEmpty from "./UnitEmpty.vue";

@Component({
    components: {
        VButton,
        TwoColumns,
        VIcon,
        DistrictSelect,
        UnitCards,
        UnitEmpty,
        VPageWindow
    }
})
export default class Index extends mixins(PaginationMixin, SearchingMixin) {

    /**
     * District filter.
     *
     * @type District|null
     */
    public districtFilter: District | null = null;

    /**
     * On component created.
     *
     * @return {Promise<void>}
     */
    public async created(): Promise<void> {
        return this.withLoading(async () => {
            const districtId = Router.getDistrictId();

            await Unit.index({
                district_id: districtId
            });

            if (districtId) {
                this.districtFilter = await District.getRemote(districtId);
            }

            this.firstSearch = true;
        });
    }

    /**
     * Returns an array of units.
     *
     * @return {Unit[]}
     */
    public get units(): Unit[] {
        return Unit.orderByName();
    }

    /**
     * Returns pagination data.
     *
     * @return {PaginationInterface}
     */
    public get pagination(): PaginationInterface {
        return Pagination.for(Unit);
    }

    /**
     * Returns the District model.
     *
     * @return {typeof District}
     */
    public get districtModel(): typeof District {
        return District;
    }

    /**
     * Handle searching the resource.
     *
     * @return {Promise<void>}
     */
    public async onSearch(): Promise<void> {
        await Unit.index({
            district_id: Router.getDistrictId()
        });
    }

    /**
     * Handle the page change.
     *
     * @return {Promise<void>}
     */
    public async onPageChange(): Promise<void> {
        await Unit.index({
            district_id: Router.getDistrictId()
        });
    }

    /**
     * On district filter change.
     *
     * @param {District|null} district
     * @return {Promise<void>}
     */
    public async onDistrictFilterChange(district: District | null): Promise<void> {
        return this.withLoading(async () => {
            await Router.setDistrictId(district ? district.id : null);

            await Unit.index({
                district_id: Router.getDistrictId()
            });
        });
    }

}
