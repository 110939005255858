import contributions_statuses from "../../json/contributions_statuses.json";
import ContributionsProfileInterface, {
    ContributionsArrearsInterface,
    ContributionsProfileQuartersInterface,
    ContributionsProfileStatusInterface
} from "../interfaces/ContributionsProfileInterface";

export default class ContributionsProfile {

    /**
     * Creates the contributions profile class using given data.
     *
     * @param {Object} data
     * @return {ContributionsProfile}
     */
    public static from(data: any): ContributionsProfile {

        // We assert that the data has required field
        if (typeof data["status"] === "undefined") {
            throw new Error("Given data must include \"status\".");
        }

        // We create new contributions profile instance
        return new ContributionsProfile(data as ContributionsProfileInterface);

    }

    /**
     * ID of the related scout.
     *
     * @type number
     */
    public scout_id: number;

    /**
     * Array of years numbers.
     *
     * @type number[]
     */
    public years: number[];

    /**
     * The total count of years included.
     *
     * @type number
     */
    public yearsCount: number;

    /**
     * The full status object.
     *
     * @type ContributionsProfileStatusInterface
     */
    public status: ContributionsProfileStatusInterface;

    /**
     * Arrears object.
     *
     * @type ContributionsArrearsInterface
     */
    public arrears: ContributionsArrearsInterface;

    /**
     * ContributionsProfile constructor.
     *
     * @param {ContributionsProfileInterface} data
     * @return {void}
     */
    public constructor(data: ContributionsProfileInterface) {
        this.scout_id = data.scout_id;
        this.years = data.years;
        this.yearsCount = data.yearsCount;
        this.status = data.status;
        this.arrears = data.arrears;
    }

    /**
     * Check if the contribution is paid for given year and quarter.
     *
     * @param {number} year
     * @param {number} quarter
     * @return {boolean}
     */
    public isContributionPaidFor(year: number, quarter: number): boolean {
        return this.getContributionStatusFor(year, quarter) === contributions_statuses.paid;
    }

    /**
     * Check if the contribution is strictly unpaid for given year and quarter.
     * This condition excludes not applicable and out-of-range contributions.
     *
     * @param {number} year
     * @param {number} quarter
     * @return {boolean}
     */
    public isContributionUnpaidFor(year: number, quarter: number): boolean {
        return this.getContributionStatusFor(year, quarter) === contributions_statuses.unpaid;
    }

    /**
     * Returns contribution status type for given year and quarter.
     *
     * @param {number} year
     * @param {number} quarter
     * @return {number}
     */
    public getContributionStatusFor(year: number, quarter: number): number {
        const quarterKey = ("quarter" + quarter) as keyof ContributionsProfileQuartersInterface;

        return this.status[year][quarterKey];
    }

    /**
     * Returns the key attribute for a given contribution year and quarter.
     *
     * @param {number} year
     * @param {number} quarter
     * @return {string}
     */
    public getKey(year: number, quarter: number): string {
        return `contribution-${this.scout_id}-${year}-${quarter}`;
    }

}
