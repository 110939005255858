














import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class RegistrationNumber extends Vue {

    /**
     * Custom validation messages.
     *
     * @type Object
     */
    public validationMessages = {
        matches: this.$lang("Nieprawidłowy format numeru ewidencyjnego. Prawidłowy numer ewidencyjny to dwie wielkie litery oraz dziewięć cyfr, np. AA123456789.")
    };

}
