




















import Vue from "vue";
import Component from "vue-class-component";
import VPageWindow from "../../../components/VPage/VPageWindow.vue";
import UnitForm from "./UnitForm.vue";

@Component({
    components: {
        VPageWindow,
        UnitForm
    }
})
export default class Index extends Vue {

    /**
     * Returns the page window title.
     *
     * @return {string}
     */
    public get title(): string {
        const param = this.routeParam("unit");

        return param
            ? this.$lang("Edytuj jednostkę")
            : this.$lang("Dodaj jednostkę");
    }

    /**
     * Returns the page window description.
     *
     * @return {string}
     */
    public get description(): string {
        const param = this.routeParam("unit");

        return param
            ? this.$lang("Popraw dane istniejącej jednostki.")
            : this.$lang("Określ dane nowej jednostki.");
    }

}
