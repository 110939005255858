import Vue from "vue";
import Component from "vue-class-component";
import { EVENT_PAGE_CHANGE } from "../event-bus";
import PaginationInterface from "../interfaces/PaginationInterface";
import Pagination from "../models/Pagination";

@Component
export default class PaginationMixin extends Vue {

    /**
     * On component created.
     *
     * @return {void}
     */
    public created(): void {
        this.$eventBus.$on(EVENT_PAGE_CHANGE, this.handlePageChange);
    }

    /**
     * Before the component is destroyed.
     *
     * @return {void}
     */
    public beforeDestroy(): void {
        this.$eventBus.$off(EVENT_PAGE_CHANGE, this.handlePageChange);
    }

    /**
     * Handles page change.
     *
     * @private
     * @param {number} page
     * @return {Promise<void>}
     */
    private async handlePageChange(page: number): Promise<void> {
        // We get the pagination object from whatever component
        // is using this mixin
        const pagination = (this as any).pagination as Pagination;

        if (
            typeof pagination === "undefined" ||
            !pagination.current_page ||
            pagination.current_page === page
        ) {
            return Promise.resolve();
        }

        await this.withLoading(async () => {
            // We update the pagination model, so each pagination
            // component knows already about the change
            await pagination.$update({ current_page: page });

            // We call the user-overridden method
            await this.onPageChange(page);
        });
    }

    /**
     * Handle the page change.
     *
     * @param {string} page
     * @return {Promise<void>}
     */
    public async onPageChange(page: number): Promise<void> {
        // This method is overridden in other components
    }

}
