











































import Vue from "vue";
import Component from "vue-class-component";
import { CancellationCallback, ConfirmationCallback } from "../mixins/ConfirmationMixin";
import { EVENT_CONFIRMATION_MODAL, EventBus } from "../event-bus";
import ConfirmationModalPayload from "../interfaces/ConfirmationModalPayload";
import VButton from "./VButton.vue";
import VIcon from "./VIcon.vue";

@Component({
    components: {
        VButton,
        VIcon
    }
})
export default class VConfirmationModal extends Vue {

    /**
     * Whether to show the confirmation modal.
     *
     * @type boolean
     */
    public show: boolean = false;

    /**
     * The modal title.
     *
     * @type string
     */
    public title?: string;

    /**
     * The confirmation question.
     *
     * @type string
     */
    public question: string = "";

    /**
     * The confirmation callback.
     *
     * @type ConfirmationCallback
     */
    private confirmationCallback: ConfirmationCallback = () => {};

    /**
     * The callback for cancelling the modal.
     *
     * @type CancellationCallback
     */
    private cancellationCallback: CancellationCallback = () => {};

    /**
     * On component created.
     *
     * @return void
     */
    public created(): void {
        EventBus.$on(EVENT_CONFIRMATION_MODAL, this.onConfirmationPrompt);
    }

    /**
     * Handles the confirmation prompt.
     *
     * @private
     * @param {ConfirmationModalPayload} payload
     * @return void
     */
    private onConfirmationPrompt(payload: ConfirmationModalPayload): void {
        this.title = payload.title;
        this.question = payload.question;
        this.confirmationCallback = payload.onConfirm ?? this.confirmationCallback;
        this.cancellationCallback = payload.onCancel ?? this.cancellationCallback;
        this.show = true;
    }

    /**
     * Returns the modal title.
     *
     * @private
     * @return string
     */
    private getModalTitle(): string {
        return this.title ?? this.$lang("Potwierdź akcję");
    }

    /**
     * Handles confirming the question.
     *
     * @private
     * @return void
     */
    private onOk(): void {
        this.confirmationCallback();
    }

    /**
     * Handles canceling/closing the modal.
     *
     * @private
     * @return void
     */
    private onCancel(): void {
        this.cancellationCallback();
    }

}
