




































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import VIcon from "./VIcon.vue";

@Component({
    components: { VIcon }
})
export default class VFormulateSelect extends Vue {

    @Prop({ required: true })
    public context!: any;

    /**
     * Returns all the options that are available.
     *
     * @return array
     */
    public get options(): any[] {
        return this.context.options ?? [];
    }

    /**
     * Returns the text displayed on the toggle button.
     *
     * @return string
     */
    public get text(): string {
        if (this.context.model) {
            return this.options.find((option: any) => option.value === this.context.model).label;
        }
        return this.options[0].label;
    }

    /**
     * Checks whether the placeholder is selected.
     *
     * @return {boolean}
     */
    public get placeholderSelected(): boolean {
        return !!(!this.context.hasValue && this.context.attributes && this.context.attributes.placeholder)
    }

    /**
     * Returns dropdown toggle button class.
     *
     * @return {Array}
     */
    public get toggleClass(): any[] {
        return [
            "select-toggle",
            "mw-100",
            "overflow-hidden",
            "text-ellipsis",
            "text-left",
            "position-relative",
            { "selected": this.context.model !== "" }
        ];
    }

    /**
     * Returns class name for dropdown item.
     *
     * @param {string} value
     * @return {string|undefined}
     */
    public itemClass(value: string): string | undefined {
        const selected = (this.context.model === value) || !this.context.model && value === this.options[0].value;
        return selected ? "dropdown-item-selected" : undefined;
    }

    /**
     * On dropdown item click.
     *
     * @private
     * @param {string} value
     * @return {void}
     */
    private onClick(value: string): void {
        this.context.model = value;
        this.$emit("change", value);
    }

}
