import { Model } from "@vuex-orm/core";
import { Response } from "@vuex-orm/plugin-axios";
import ServerErrorHandler from "../classes/ServerResponseHandler/ServerErrorHandler";

export default class Contribution extends Model {

    /**
     * Sends request to patch the contribution with given data.
     *
     * @param {Object} data
     * @return {Promise<void>}
     */
    public static updateRemote(data: any): Promise<void | Response> {
        return this.api()
            .patch("/contributions/update", data, { save: false })
            .catch(ServerErrorHandler.handle);
    }

    /**
     * The name that is going be used as module name in Vuex Store.
     *
     * @type string
     */
    public static entity = "contributions";

    /**
     * The definition of the fields of the model and its relations.
     *
     * @return Object
     */
    public static fields() {
        return {
            scout_id: this.number(0),
            year: this.number(0),
            quarter1: this.boolean(false),
            quarter2: this.boolean(false),
            quarter3: this.boolean(false),
            quarter4: this.boolean(false)
        };
    }

    /**
     * The ID of the scout related.
     *
     * @type {number}
     */
    public scout_id!: number;

    /**
     * The contribution year.
     *
     * @type {number}
     */
    public year!: number;

    /**
     * Whether the first quarter is paid.
     *
     * @type {boolean}
     */
    public quarter1!: boolean;

    /**
     * Whether the second quarter is paid.
     *
     * @type {boolean}
     */
    public quarter2!: boolean;

    /**
     * Whether the third quarter is paid.
     *
     * @type {boolean}
     */
    public quarter3!: boolean;

    /**
     * Whether the fourth quarter is paid.
     *
     * @type {boolean}
     */
    public quarter4!: boolean;

}
