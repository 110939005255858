











import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class VPageHeader extends Vue {

    @Prop({ required: true })
    public title!: string;

    @Prop()
    public description?: string;

}
