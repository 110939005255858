

























import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
    // The name needs to be explicitly defined, as the component is rendering
    // itself recursively. Without it, the component wouldn't render itself
    // in production mode (check the "name" property in Vue documentation)
    // even though in development mode everything is fine
    name: "RoleFormPermissionGroup",
    components: { RoleFormPermissionGroup }
})
export default class RoleFormPermissionGroup extends Vue {

    @Prop({ required: true })
    public group!: object;

    @Prop({ default: 0 })
    public depth!: number;

    @Prop({ required: true })
    public model!: any;

    /**
     * Returns group sorted by type of attribute.
     * The string attributes go above the object-type attributes.
     *
     * @return {Object}
     */
    public get sortedGroup(): object {
        const group = this.group;
        const keys = Object.keys(group);
        const sorted = {};

        for (const key of keys) {
            if (typeof group[key as keyof typeof group] === "string") {
                sorted[key as keyof typeof group] = group[key as keyof typeof group];
            }
        }

        for (const key of keys) {
            if (typeof group[key as keyof typeof group] === "object") {
                sorted[key as keyof typeof group] = group[key as keyof typeof group];
            }
        }

        return sorted;
    }

    /**
     * Checks whether given group is a leaf (a string permission) or
     * another sub-group of permissions.
     *
     * @param value
     */
    public groupIsLeaf(value: any): boolean {
        return (typeof value === 'string');
    }

}
