




















import Vue from "vue";
import Component from "vue-class-component";
import VPageWindow from "../../../components/VPage/VPageWindow.vue";
import RoleForm from "./RoleForm.vue";

@Component({
    components: {
        RoleForm,
        VPageWindow
    }
})
export default class Role extends Vue {

    /**
     * Returns the page window title.
     *
     * @return {string}
     */
    public get title(): string {
        const param = this.routeParam("role");

        return param
            ? this.$lang("Edytuj rolę")
            : this.$lang("Dodaj rolę");
    }

    /**
     * Returns the page window description.
     *
     * @return {string}
     */
    public get description(): string {
        const param = this.routeParam("role");

        return param
            ? this.$lang("Popraw dane istniejącej roli.")
            : this.$lang("Określ nazwę oraz uprawnienia nowej roli.");
    }

}
