























import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { EVENT_SHOW_LOADING } from "../event-bus";
import Pagination from "../models/Pagination";
import VPagination from "./VPagination.vue";

@Component({
    components: { VPagination }
})
export default class ResourceCards extends Vue {

    @Prop({ required: true })
    public pagination!: Pagination;

    /**
     * Whether the app is loading or not.
     *
     * @type boolean
     */
    public isLoading: boolean = false;

    /**
     * On component created.
     *
     * @return {void}
     */
    public created(): void {
        this.$eventBus.$on(EVENT_SHOW_LOADING, this.onShowLoading);
    }

    /**
     * Before the component is destroyed.
     *
     * @return {void}
     */
    public beforeDestroy(): void {
        this.$eventBus.$off(EVENT_SHOW_LOADING, this.onShowLoading);
    }

    /**
     * On page change.
     *
     * @param {number} page
     * @return {void}
     */
    public onPageChange(page: number): void {
        this.$emit("page", page);
    }

    /**
     * Handles showing the loading.
     *
     * @param {boolean} show
     * @return {void}
     */
    public onShowLoading(show: boolean): void {
        this.isLoading = show;
    }

}
