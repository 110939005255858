import Vue from "vue";
import Component from "vue-class-component";
import { EVENT_CONFIRMATION_MODAL, EventBus } from "../event-bus";
import ConfirmationPayload from "../interfaces/ConfirmationPayload";

declare module "vue/types/vue" {
    interface Vue {
        confirmationModal(payload: ConfirmationPayload): void;
    }
}

/**
 * Type of the confirmation callback.
 *
 * @type Function
 */
export type ConfirmationCallback = () => void;

/**
 * Type of the cancellation callback.
 *
 * @type function
 */
export type CancellationCallback = () => void;

@Component
export default class ConfirmationMixin extends Vue {

    /**
     * Creates a confirmation modal with given callbacks on
     * either confirmation or cancellation.
     *
     * @param {ConfirmationPayload} payload
     * @return void
     */
    public confirmationModal(payload: ConfirmationPayload): void {
        const { title, question, onConfirm, onCancel } = payload;

        // We emit an event to show a confirmation modal with given payload
        EventBus.$emit(EVENT_CONFIRMATION_MODAL, {
            title,
            question,
            onConfirm,
            onCancel
        });
    }

}
