import Model from "./Model";

export default class UserDistrict extends Model {

    /**
     * The name that is going be used as module name in Vuex Store.
     *
     * @type string
     */
    public static entity = "user_districts";

    /**
     * The definition of the fields of the model and its relations.
     *
     * @return {Object}
     */
    public static fields() {
        return {
            id: this.number(0),
            user_id: this.number(0),
            district_id: this.number(0)
        };
    }

}
