

















import Vue from "vue";
import Component from "vue-class-component";
import VIcon from "../../components/VIcon.vue";
import VSpinner from "../../components/VSpinner.vue";

@Component({
    components: {
        VIcon,
        VSpinner
    }
})
export default class HomeHeader extends Vue {}
