




















import Component from "vue-class-component";
import { Prop, Vue } from "vue-property-decorator";
import VIcon from "./VIcon.vue";

export type IconPositionType = "left" | "right";

@Component({
    components: {
        VIcon
    }
})
export default class VButton extends Vue {

    /**
     * The default button class.
     *
     * @type string
     */
    private static DEFAULT_BUTTON_CLASS = "btn";

    /**
     * The default icon class.
     *
     * @type string
     */
    private static DEFAULT_ICON_CLASS = "";

    @Prop()
    public icon!: string;

    @Prop({ default: "left" })
    public iconPosition!: IconPositionType;

    @Prop()
    public iconClass!: string;

    @Prop()
    public text!: string;

    @Prop()
    public buttonClass!: string;

    /**
     * Returns full string with button class or undefined.
     *
     * @return string|undefined
     */
    public get fullButtonClass(): string | undefined {
        const buttonClass = [VButton.DEFAULT_BUTTON_CLASS, this.buttonClass]
            .join(" ")
            .trim();

        return buttonClass ? buttonClass : undefined;
    }

    /**
     * Returns full class of the icon or undefined.
     *
     * @return string|undefined
     */
    public get fullIconClass(): string | undefined {
        const iconClass = [VButton.DEFAULT_ICON_CLASS, this.iconClass]
            .join(" ")
            .trim();

        return iconClass ? iconClass : undefined;
    }

}
