



































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import VIcon from "../../components/VIcon.vue";
import Role from "../../models/Role";

@Component({
    components: { VIcon }
})
export default class RoleCardDetails extends Vue {

    @Prop({ required: true })
    public role!: Role;

}
