


















import Vue from "vue";
import Component from "vue-class-component";
import Status from "../../models/Status";
import HomeFooter from "../Home/HomeFooter.vue";
import StatusNotFound from "./StatusNotFound.vue";
import StatusPaid from "./StatusPaid.vue";
import StatusUnpaid from "./StatusUnpaid.vue";

@Component({
    components: {
        StatusUnpaid,
        HomeFooter,
        StatusPaid,
        StatusNotFound
    }
})
export default class Index extends Vue {

    /**
     * The scout's contributions status.
     *
     * @type {Status}
     */
    public status: Status | false | null = null;

    /**
     * On component created.
     *
     * @return {Promise<void>}
     */
    public async created(): Promise<void> {
        await this.withLoading(async () => {
            const registrationNumber = this.routeParam("registrationNumber");

            if (!registrationNumber) {
                this.redirectTo("home");
            }

            const status = await Status.index(registrationNumber!);

            this.status = status ? status : false;
        });
    }

}
