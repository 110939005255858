





























import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import User from "../../models/User";

@Component
export default class UserCardDetails extends Vue {

    @Prop({ required: true })
    public user!: User;

}
