










import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ResourceCards from "../../components/ResourceCards.vue";
import PaginationInterface from "../../interfaces/PaginationInterface";
import User from "../../models/User";
import UserCard from "./UserCard.vue";

@Component({
    components: {
        ResourceCards,
        UserCard
    }
})
export default class UserCards extends Vue {

    @Prop({ required: true })
    public users!: User[];

    @Prop({ required: true })
    public pagination!: PaginationInterface;

    /**
     * Returns the "key" attribute value.
     *
     * @param {number} id
     * @return string
     */
    public userKey(id: number): string {
        return `user-card-${id}`;
    }

}
