import Echo from "laravel-echo";
import "pusher-js";

const KEY_DEVELOPMENT   = "d5a9d866d1113a6d25de";
const KEY_PRODUCTION    = "ed02a95977ed0cfa01ef";

const echo = new Echo({
    broadcaster:    "pusher",
    key:            (!process.env.NODE_ENV || process.env.NODE_ENV === "development") ? KEY_DEVELOPMENT : KEY_PRODUCTION,
    cluster:        "eu",
    forceTLS:       true,
    namespace:      null,
    auth: {
        headers: {
            Accept: "application/json",
            Authorization: "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5MWViZTUxYy1lY2FkLTQ4Y2QtODE2ZC1hZjNjNjllODBiMmYiLCJqdGkiOiJlYmU3OTgyNmI5MTFiODk3ZDkxODZjZGU1MzdlMjM3ODQxOTU3ZmFkNDFkMzc0YzUxMDg5ZDllNTBhNDY1MWRlMTA0YzQ0NGQxNGE4ZGZiNyIsImlhdCI6MTYwNjE0NzAyMCwibmJmIjoxNjA2MTQ3MDIwLCJleHAiOjE2Mzc2ODMwMjAsInN1YiI6IjEiLCJzY29wZXMiOltdfQ.T1ZCDhU-A_Ch7Ojzd9KJrRr7lxHF9Jyg5h9DVLnbtpLC2NPgtT4EuDOk2BNYXl8zEa8fdR_dFJic_qzSsVz6lxIRbV6BzHNuOUc6bWBH05qEcNLFBNiOZir7I_bcN4-VPKaQTUifz7jF_ejdfy9z_W4-hKkmuhEsokQ4VkN06ZVTKGpAtaRsV8pjuuUXsMYldn3M7QPRJ9y3RtiOwXDi3cnKi7T9x5LFf_7CZi4N_4eZp5sbdmDEVhiNJyp22hcMsC88wgVSSZgN4QPOuSRU7mBhNzXFmEh51N_mh5P1-z1aOUAiXtfMQbGcrBhzTAHSBXOR9x0Qbs_3LocnzmlUJO7WoAg7uxjrF9CWJEuPHTik8hENwO6aWApkyeYeW_ZygPXnZK516M2HQw3Lmqa5_McyuPUGQVlWE546WrtySQAn3d62Gw0_kYbb4tAcmdQ1O7CULcJSNOE-ugd-VkY3F85gE4dVr5_Wr2Ijoil66i6bYWDRcdxsXPBeOoh-mlQ1MFfNuvO3ELq5ALTrrdvPuglbhQci8wiqrfbaI11gBYi5Qx7iJ0KG_akzobi4WHyGBbt1WwXhT-d2NxSiqr5NpgnpZvyO3s6XCWlPl2zXoklLxSaieBaMdj4jT-n5qgnAjGX4mGXlgi7ctz48LpZKvEbon1pXnXonZADnSgidxqc"
        }
    }

    // For some strange (still unknown) reason the following
    // is the correct auth endpoint for local server:
    // authEndpoint:   "localhost:8000/public/api/broadcast/auth",
});

export default echo;
