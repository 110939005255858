import Vue from "vue";

/**
 * Form input error event key.
 *
 * @type string
 */
const EVENT_ERROR_INPUT = "input-error";

/**
 * New pending request event key.
 *
 * @type string
 */
const EVENT_NEW_PENDING_REQUEST = "new-pending-request";

/**
 * Pending request done event key.
 *
 * @type string
 */
const EVENT_PENDING_REQUEST_DONE = "pending-request-done";

/**
 * Info notification key.
 *
 * @type string
 */
const EVENT_NOTIFICATION_INFO = "notification-info";

/**
 * Success notification key.
 *
 * @type string
 */
const EVENT_NOTIFICATION_SUCCESS = "notification-success";

/**
 * Warning notification key.
 *
 * @type string
 */
const EVENT_NOTIFICATION_WARNING = "notification-warning";

/**
 * Error notification key.
 *
 * @type string
 */
const EVENT_NOTIFICATION_ERROR = "notification-error";

/**
 * Server error notification key.
 *
 * @type string
 */
const EVENT_NOTIFICATION_SERVER_ERROR = "notification-server-error";

/**
 * Confirmation modal toggle key.
 *
 * @type string
 */
const EVENT_CONFIRMATION_MODAL = "confirmation-modal";

/**
 * Loading component toggle event.
 *
 * @type string
 */
const EVENT_SHOW_LOADING = "show-loading";

/**
 * Page change event.
 *
 * @type string
 */
const EVENT_PAGE_CHANGE = "page-change";

/**
 * Search event.
 *
 * @type string
 */
const EVENT_SEARCH = "search";

/**
 * The event bus.
 *
 * @type Vue
 */
const EventBus = new Vue();

export {
    EVENT_ERROR_INPUT,
    EVENT_NEW_PENDING_REQUEST,
    EVENT_PENDING_REQUEST_DONE,
    EVENT_NOTIFICATION_ERROR,
    EVENT_NOTIFICATION_INFO,
    EVENT_NOTIFICATION_SERVER_ERROR,
    EVENT_NOTIFICATION_SUCCESS,
    EVENT_NOTIFICATION_WARNING,
    EVENT_CONFIRMATION_MODAL,
    EVENT_SHOW_LOADING,
    EVENT_PAGE_CHANGE,
    EVENT_SEARCH,
    EventBus
};
