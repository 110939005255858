




















import Vue from "vue";
import Component from "vue-class-component";
import VPageWindow from "../../../components/VPage/VPageWindow.vue";
import ScoutForm from "./ScoutForm.vue";

@Component({
    components: {
        ScoutForm,
        VPageWindow
    }
})
export default class Index extends Vue {

    /**
     * Returns the page window title.
     *
     * @return {string}
     */
    public get title(): string {
        const param = this.routeParam("scout");

        return param
            ? this.$lang("Edytuj harcerza")
            : this.$lang("Dodaj harcerza");
    }

    /**
     * Returns the page window description.
     *
     * @return {string}
     */
    public get description(): string {
        const param = this.routeParam("scout");

        return param
            ? this.$lang("Popraw dane istniejącego harcerza.")
            : this.$lang("Określ dane nowego harcerza.");
    }

}
