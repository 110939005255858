
























import Vue from "vue";
import Component from "vue-class-component";
import RegistrationNumber from "../../components/VueFormulate/RegistrationNumber.vue";
import VButton from "../../components/VButton.vue";

@Component({
    components: {
        RegistrationNumber,
        VButton
    }
})
export default class HomeForm extends Vue {

    /**
     * The form data.
     *
     * @type {Object}
     */
    public formData = {
        registration_number: ""
    };

    /**
     * On form submit.
     *
     * @return {Promise<void>}
     */
    public async onSubmit(): Promise<void> {
        this.redirectTo("status", {
            registrationNumber: this.formData.registration_number
        });
    }

}
