









import Vue from "vue";
import Component from "vue-class-component";
import LoginHeader from "./LoginHeader.vue";
import LoginMain from "./LoginMain.vue";

@Component({
    components: {
        LoginMain,
        LoginHeader
    }
})
export default class Login extends Vue {
    public name = "Login";
}
