import District from "./District";
import Model from "./Model";
import Role from "./Role";
import Unit from "./Unit";

export default class User extends Model {

    /**
     * The name that is going be used as module name in Vuex Store.
     *
     * @type string
     */
    public static entity = "users";

    /**
     * The definition of the fields of the model and its relations.
     *
     * @return {Object}
     */
    public static fields() {
        return {
            id: this.number(0),
            name: this.string(""),
            email: this.string(""),
            roles_count: this.number(0),
            roles: this.attr([], (data: any) => data.map((element: any) => new Role(element))),
            districts_count: this.number(0),
            districts: this.attr([], (data: any) => data.map((element: any) => new District(element))),
            units_count: this.number(0),
            units: this.attr([], (data: any) => data.map((element: any) => new Unit(element))),
            active: this.boolean(false),
            created_at: this.string(null).nullable(),
            updated_at: this.string(null).nullable()
        };
    }

    /**
     * The ID of the user.
     *
     * @type number
     */
    public id!: number;

    /**
     * The name of the user.
     *
     * @type string
     */
    public name!: string;

    /**
     * The e-mail address of the user.
     *
     * @type string
     */
    public email!: string;

    /**
     * The total number of roles that have been assigned to the user.
     *
     * @type number
     */
    public roles_count!: number;

    /**
     * The roles assigned to the user.
     *
     * @type Role[]
     */
    public roles!: Role[];

    /**
     * The total number of districts that have been assigned to the user.
     *
     * @type number
     */
    public districts_count!: number;

    /**
     * The districts assigned to the user.
     *
     * @type District[]
     */
    public districts!: District[];

    /**
     * The total number of units that have been assigned to the user.
     *
     * @type number
     */
    public units_count!: number;

    /**
     * The units assigned to the user.
     *
     * @type Unit[]
     */
    public units!: Unit[];

    /**
     * Whether the user is active or not.
     *
     * @type boolean
     */
    public active!: boolean;

    /**
     * User creation timestamp.
     *
     * @type string|null
     */
    public created_at!: string | null;

    /**
     * User update timestamp.
     *
     * @type string|null
     */
    public updated_at!: string | null;

    /**
     * Returns all user's roles names separated with a comma.
     *
     * @return {string}
     */
    public get rolesNames(): string {
        return this.roles
            .map((role: Role) => role.name)
            .join(", ");
    }

}
