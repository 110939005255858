






























import Vue from "vue";
import Component from "vue-class-component";
import { EVENT_SHOW_LOADING } from "../event-bus";
import TheInputErrorModal from "./TheInputErrorModal.vue";
import TheNavbar from "./TheNavbar/Index.vue";
import TheWelcomeBar from "./TheWelcomeBar.vue";
import VConfirmationModal from "./VConfirmationModal.vue";
import VLoading from "./VLoading.vue";

@Component({
    components: {
        TheWelcomeBar,
        VLoading,
        VConfirmationModal,
        TheInputErrorModal,
        TheNavbar
    }
})
export default class TheApp extends Vue {

    /**
     * Whether to show the loading component.
     *
     * @type boolean
     */
    private showLoadingComponent: boolean = false;

    /**
     * On component created.
     *
     * @return {void}
     */
    public created(): void {
        this.$eventBus.$on(EVENT_SHOW_LOADING, this.onShowLoading);

        this.authorizeWithCookies();
    }

    /**
     * Before the component is destroyed.
     *
     * @return {void}
     */
    public beforeDestroy(): void {
        this.$eventBus.$off(EVENT_SHOW_LOADING, this.onShowLoading);
    }

    /**
     * Returns class string for the "main" html tag.
     *
     * @return {string}
     */
    public mainClass(): string {
        return this.displayNavbar()
            ? "main-with-navbar"
            : "h-100";
    }

    /**
     * Determines whether to display the navbar or not.
     *
     * @return {boolean}
     */
    public displayNavbar(): boolean {
        return this.isAuthorized();
    }

    /**
     * Handles loading component toggle.
     *
     * @private
     * @param {boolean} show
     * @return {void}
     */
    private onShowLoading(show: boolean): void {
        this.showLoadingComponent = show;
    }

}
