





















































import Vue from "vue";
import Component from "vue-class-component";
import VButton from "../../components/VButton.vue";
import VIcon from "../../components/VIcon.vue";
import VPageWindow from "../../components/VPage/VPageWindow.vue";
import VSection from "../../components/VSection.vue";
import SettingsAmounts from "./SettingsAmounts.vue";
import SettingsMain from "./SettingsMain.vue";

@Component({
    components: {
        VButton,
        VIcon,
        VSection,
        VPageWindow
    }
})
export default class Index extends Vue {

    /**
     * The settings categories.
     *
     * @type {Array<Object>}
     */
    public categories = [
        {
            name: "main",
            icon: "fas fa-cog",
            label: this.$lang("Główne"),
            component: SettingsMain
        },
        {
            name: "amounts",
            icon: "fas fa-coins",
            label: this.$lang("Kwoty składek"),
            component: SettingsAmounts
        }
    ];

    /**
     * Currently selected settings category.
     *
     * @type {string}
     */
    public selectedCategory = this.categories[0];

    /**
     * On component created.
     *
     * @return {void}
     */
    public created(): void {
        const tab = this.$route.query.tab;

        if (typeof tab !== "undefined") {
            const category = this.categories.find((category) => category.name === tab);

            if (category) {
                this.selectedCategory = category;
            }
        }
    }

    /**
     * Checks whether given tab is active or not.
     *
     * @param {string} key
     * @return {boolean}
     */
    public isTabActive(key: string): boolean {
        return (this.$route.query.tab === key);
    }

    /**
     * On role nav item click.
     *
     * @param {string} key
     * @return {void}
     */
    public onCategoryItemClick(key: string): void {
        if (key === this.$route.query.tab) {
            return;
        }

        this.selectedCategory = this.categories.find((category) => category.name === key)!;

        this.$router.replace({
            query: {
                tab: key
            }
        });
    }

}
