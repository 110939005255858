









































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Router from "../classes/Router";
import { EVENT_PAGE_CHANGE } from "../event-bus";
import PaginationInterface from "../interfaces/PaginationInterface";
import VIcon from "./VIcon.vue";

@Component({
    components: { VIcon }
})
export default class VPagination extends Vue {

    /**
     * The pagination data.
     *
     * @type PaginationInterface
     */
    @Prop({ required: true })
    public pagination!: PaginationInterface;

    /**
     * Whether to append query to the URL or not.
     *
     * @type boolean
     */
    @Prop({ default: true })
    public appendQuery!: boolean;

    /**
     * Returns the number of the current page.
     *
     * @return number
     */
    public get currentPage(): number {
        return this.getPaginationValue("current_page", 0) as number;
    }

    /**
     * Returns the number of the last page.
     *
     * @return number
     */
    public get lastPage(): number {
        return this.getPaginationValue("last_page", 0) as number;
    }

    /**
     * Returns the total number of results.
     *
     * @return number
     */
    public get total(): number {
        return this.getPaginationValue("total", 0) as number;
    }

    /**
     * Returns the starting offset number of the results.
     *
     * @return number
     */
    public get from(): number {
        return this.getPaginationValue("from", 0) as number;
    }

    /**
     * Returns the ending offset number of the results.
     *
     * @return number
     */
    public get to(): number {
        return this.getPaginationValue("to", 0) as number;
    }

    /**
     * Returns the number of results per page.
     *
     * @return number
     */
    public get perPage(): number {
        return this.getPaginationValue("per_page", 0) as number;
    }

    /**
     * Returns the details displayed next to pagination links.
     *
     * @return string
     */
    public get details(): string {
        return this.$lang("Wyświetlanie :from - :to z :total", {
            from: this.from,
            to: this.to,
            total: this.total
        });
    }

    /**
     * Returns class string for details HTML element.
     *
     * @return {string}
     */
    public get detailsParentClass(): string {
        return "text-white ml-0 ml-md-auto text-center text-md-right w-100 w-md-auto mb-3 mb-md-0";
    }

    /**
     * Returns value of the pagination param.
     *
     * @private
     * @param {keyof PaginationInterface} param
     * @param {string|number|null} defaultValue
     * @return string|number|null
     */
    private getPaginationValue(param: keyof PaginationInterface, defaultValue: string | number | null): string | number | null {
        if (!this.pagination[param]) {
            return defaultValue;
        }

        return this.pagination[param];
    }

    /**
     * Handles the page change.
     *
     * @private
     * @param {number} page
     * @return void
     */
    private onPageChange(page: number): void {
        const currentPage = Router.getPage() || 1;

        // We dont go any further if the page
        // didn't really change
        if (currentPage === page) {
            return;
        }

        // We set the URL param
        if (this.appendQuery) {
            Router.setPage(page);
        }

        // We emit a global message
        this.$eventBus.$emit(EVENT_PAGE_CHANGE, page);

        // We emit the page value to the parent
        this.$emit("change", page);
    }

}
