import Model from "./Model"

export default class Permission extends Model {

    /**
     * The name that is going be used as module name in Vuex Store.
     *
     * @type string
     */
    public static entity = "permissions";

    /**
     * The definition of the fields of the model and its relations.
     *
     * @return Object
     */
    public static fields() {
        return {
            id: this.number(0),
            name: this.string("")
        };
    }

    /**
     * ID of the permission.
     *
     * @type number
     */
    public id!: number;

    /**
     * The name of the permission.
     *
     * @type string
     */
    public name!: string;

}
