




































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ContributionsProfile from "../../classes/ContributionsProfile";
import Contribution from "../../models/Contribution";
import Scout from "../../models/Scout";
import { ContributionsDisplayMode } from "./Index.vue";
import ScoutCardContributionCheckbox from "./ScoutCardContributionCheckbox.vue";
import ScoutCardContributionStatus from "./ScoutCardContributionStatus.vue";
import contributions_statuses from "../../../json/contributions_statuses.json";

@Component({
    components: {
        ScoutCardContributionStatus,
        ScoutCardContributionCheckbox
    }
})
export default class ScoutCardContributions extends Vue {

    @Prop({ required: true })
    public profile!: ContributionsProfile;

    @Prop({ default: "show" })
    public contributionsDisplayMode!: ContributionsDisplayMode;

    /**
     * On contribution checkbox change.
     *
     * @param {number} year
     * @param {number} quarter
     * @param {boolean} value
     * @return {Promise<void>}
     */
    public async onContributionCheckboxChange(year: number, quarter: number, value: boolean): Promise<void> {
        await Contribution.updateRemote({
            scout_id: this.profile.scout_id,
            year: year,
            quarter: quarter,
            paid: value
        }).then((response: any) => {
            const contributionsProfile = new ContributionsProfile(response.response.data);
            const scout = Scout.find(contributionsProfile.scout_id);

            if (scout) {
                scout.$update({
                    contributions_profile: contributionsProfile
                });
            }
        });
    }

    /**
     * Returns tooltip description for contribution status.
     *
     * @param {number} year
     * @param {number} quarter
     * @return {string}
     */
    public statusTooltip(year: number, quarter: number): string {
        const status = this.profile.getContributionStatusFor(year, quarter);
        let tooltip = this.$lang("Kwartał :quarter (nie dotyczy)", { quarter });

        switch (status) {
            case contributions_statuses.paid:
                tooltip = this.$lang("Kwartał :quarter opłacony", { quarter });
                break;
            case contributions_statuses.unpaid:
                tooltip = this.$lang("Kwartał :quarter nieopłacony", { quarter });
                break;
        }

        return tooltip;
    }

}
