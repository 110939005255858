


















































































































import Component, { mixins } from "vue-class-component";
import VButton from "../../../components/VButton.vue";
import VSection from "../../../components/VSection.vue";
import DistrictSelect from "../../../components/VueFormulate/DistrictSelect.vue";
import EmailAddress from "../../../components/VueFormulate/EmailAddress.vue";
import Password from "../../../components/VueFormulate/Password.vue";
import RoleSelect from "../../../components/VueFormulate/RoleSelect.vue";
import UnitSelect from "../../../components/VueFormulate/UnitSelect.vue";
import FormMixin, { SubmittedDataInterface } from "../../../mixins/FormMixin";
import District from "../../../models/District";
import Role from "../../../models/Role";
import Unit from "../../../models/Unit";
import User from "../../../models/User";

@Component({
    components: {
        RoleSelect,
        UnitSelect,
        Password,
        EmailAddress,
        DistrictSelect,
        VButton,
        VSection
    }
})
export default class UserForm extends mixins(FormMixin) {

    /**
     * The form data.
     *
     * @type Object
     */
    public formData = {
        name: "",
        active: ["active"],
        email: "",
        password: "",
        password_confirmation: "",
        roles: [] as Role[],
        districts: [] as District[],
        units: [] as Unit[]
    }

    /**
     * On component created.
     *
     * @return {Promise<void>}
     */
    public async created(): Promise<void> {
        if (this.isEditForm) {
            const id = this.routeParam("user")!;

            await this.withLoading(async () => {
                this.showLoading();

                const user = await User.editRemote(id) as User;

                this.formData = {
                    name: user.name,
                    email: user.email,
                    password: "",
                    password_confirmation: "",
                    roles: user.roles,
                    districts: user.districts,
                    units: user.units,
                    active: user.active ? ["active"] : []
                };

                this.hideLoading();
            });
        }
    }

    /**
     * Returns the data for submitting a form.
     *
     * @return {SubmittedDataInterface}
     */
    public getSubmittedData(): SubmittedDataInterface {
        const {
            name,
            email,
            password,
            password_confirmation,
            roles,
            districts,
            units,
            active
        } = this.formData;

        const data = {
            name,
            email,
            password,
            password_confirmation,
            roles: roles.map((role) => role.id),
            districts: districts.map((district) => district.id),
            units: units.map((unit) => unit.id),
            active: active.includes("active")
        };

        return {
            model: User,
            data
        };
    }

}
