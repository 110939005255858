import __ from "./lang";

export default {
    "scopeWithIds": __("Wybrani/wybrane"),
    "scopeWithRoles": __("Z wybranymi rolami"),
    "scopeReferencingDistrict": __("Należący/należące do hufców"),
    "scopeReferencingUnit": __("Należący do jednostek"),
    "scopeRelatedToDistricts": __("Związani/związane z hufcami"),
    "scopeRelatedToUnits": __("Związani z jednostkami"),
    "scopeCommonRoles": __("Wspólnie posiadane role"),
    "scopeCommonDistricts": __("Wspólnie posiadane hufce"),
    "scopeCommonUnits": __("Wspólnie posiadane jednostki")
};
