




























import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Scout from "../../models/Scout";

@Component
export default class ScoutCardDetails extends Vue {

    @Prop({ required: true })
    public scout!: Scout;

    /**
     * Options for localized date format.
     *
     * @type {Intl.DateTimeFormatOptions}
     */
    public dateFormatOptions: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long"
    };

    /**
     * Returns pretty formatted joined at date.
     *
     * @return {string}
     */
    public get joinedAt(): string {
        return this.scout.joined_at
            ? new Date(this.scout.joined_at!).toLocaleDateString(undefined, this.dateFormatOptions)
            : "-";
    }

    /**
     * Returns pretty formatted joined at date.
     *
     * @return {string}
     */
    public get quitAt(): string {
        return this.scout.quit_at
            ? new Date(this.scout.quit_at!).toLocaleDateString(undefined, this.dateFormatOptions)
            : "-";
    }

}
