import Model from "./Model";

export default class RolePermission extends Model {

    /**
     * The name that is going be used as module name in Vuex Store.
     *
     * @type string
     */
    public static entity = "role_permissions";

    /**
     * The definition of the fields of the model and its relations.
     *
     * @return {Object}
     */
    public static fields() {
        return {
            id: this.number(0),
            role_id: this.number(0),
            permission_id: this.number(0)
        };
    }

}
