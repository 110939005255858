import __ from "./lang";

export default {
    "role": __("Role"),
    "access roles": __("Dostęp do zakładki z rolami"),
    "create role": __("Dodawanie nowych ról"),
    "edit role": __("Edytowanie ról"),
    "delete role": __("Usuwanie ról"),
    "user": __("Użytkownicy"),
    "access users": __("Dostęp do zakładki z użytkownikami"),
    "create user": __("Dodawanie użytkowników"),
    "edit user": __("Edytowanie użytkowników"),
    "assign": __("Przypisywanie"),
    "assign user role": __("Przypisywanie ról użytkownikom"),
    "assign user district": __("Przypisywanie hufców użytkownikom"),
    "assign user unit": __("Przypisywanie jednostek użytkownikom"),
    "delete user": __("Usuwanie użytkowników"),
    "district": __("Hufce"),
    "access districts": __("Dostęp do zakładki z hufcami"),
    "create district": __("Dodawanie hufców"),
    "edit district": __("Edytowanie hufców"),
    "last_modified": __("Data ostatniej modyfikacji"),
    "edit district last modified date": __("Edytowanie daty ostatniej modyfikacji"),
    "transfer_details": __("Szczegóły przelewu"),
    "edit district transfer details": __("Edytowanie szczegółów przelewu"),
    "assign district on create": __("Automatyczne przydzielanie nowo utworzonych hufców"),
    "delete district": __("Usuwanie hufców"),
    "unit": __("Jednostki"),
    "access units": __("Dostęp do zakładki z jednostkami"),
    "create unit": __("Dodawanie jednostek"),
    "edit unit": __("Edytowanie jednostek"),
    "assign unit district": __("Przypisywanie hufców jednostkom"),
    "assign unit on create": __("Automatyczne przydzielanie nowo utworzonych jednostek"),
    "delete unit": __("Usuwanie jednostek"),
    "scout": __("Harcerze"),
    "access scouts": __("Dostęp do zakładki z harcerzami"),
    "archived": __("Archiwum"),
    "access archived scouts": __("Dostęp do archiwum harcerzy"),
    "restore archived scout": __("Przywracanie zarchiwizowanych harcerzy"),
    "create scout": __("Dodawanie harcerzy"),
    "edit scout": __("Edytowanie harcerzy"),
    "registration_number": __("Numer ewidencyjny"),
    "display scout registration number": __("Pokazywanie numerów ewidencyjnych"),
    "edit scout registration number": __("Edytowanie numerów ewidencyjnych"),
    "assign scout unit": __("Przypisywanie jednostek harcerzom"),
    "assign scout district": __("Przypisywanie hufców harcerzom"),
    "contribution": __("Składki"),
    "edit scout contribution": __("Edytowanie składek harcerzy"),
    "archive scout": __("Archiwizowanie harcerzy"),
    "delete scout": __("Usuwanie harcerzy"),
    "setting": __("Ustawienia"),
    "access settings": __("Dostęp do zakładki z ustawieniami"),
    "contributions": __("Składki"),
    "access contributions settings": __("Dostęp do zakładki z ustawieniami składek"),
    "edit contributions settings": __("Edytowanie ustawień składek"),
    "contributions_amounts": __("Kwoty składek"),
    "access contributions amounts settings": __("Dostęp do zakładki z ustawieniami kwot składek"),
    "edit contributions amounts settings": __("Edytowanie ustawień kwot składek")
};
