




















import Vue from "vue";
import Component from "vue-class-component";
import VPageWindow from "../../../components/VPage/VPageWindow.vue";
import UserForm from "./UserForm.vue";

@Component({
    components: {
        VPageWindow,
        UserForm
    }
})
export default class Index extends Vue {

    /**
     * Returns the page window title.
     *
     * @return {string}
     */
    public get title(): string {
        const param = this.routeParam("user");

        return param
            ? this.$lang("Edytuj użytkownika")
            : this.$lang("Dodaj użytkownika");
    }

    /**
     * Returns the page window description.
     *
     * @return {string}
     */
    public get description(): string {
        const param = this.routeParam("user");

        return param
            ? this.$lang("Popraw dane istniejącego użytkownika.")
            : this.$lang("Określ dane nowego użytkownika.");
    }

}
