
















import Vue from "vue";
import Component from "vue-class-component";
import VIcon from "../../components/VIcon.vue";
import VSpinner from "../../components/VSpinner.vue";

@Component({
    components: {
        VSpinner,
        VIcon
    }
})
export default class LoginHeader extends Vue {}
