import axios from "./axios";
import VuexORM from "@vuex-orm/core";
import VuexORMAxios from "@vuex-orm/plugin-axios";
import Vue from "vue";
import Vuex from "vuex";

// We use Vuex for state management
Vue.use(Vuex);

// We register all models for our ORM/Vuex database
const database = new VuexORM.Database();
const files = require.context("./models", true, /\.ts/i);
files.keys().forEach((key: string) => {
    database.register(files(key).default)
});

// We use the axios plugin for VuexORM
VuexORM.use(VuexORMAxios, {
    axios,
    database
});

// We create Vuex Store and register database through VuexORM
const store = new Vuex.Store({
    plugins: [
        VuexORM.install(database)
    ]
});

export default store;
