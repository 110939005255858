












































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ResourceDropdownMenu from "../../components/ResourceDropdownMenu.vue";
import VButton from "../../components/VButton.vue";
import VIcon from "../../components/VIcon.vue";
import Unit from "../../models/Unit";
import UnitCardDetails from "./UnitCardDetails.vue";

@Component({
    components: {
        ResourceDropdownMenu,
        UnitCardDetails,
        VIcon,
        VButton
    }
})
export default class UnitCard extends Vue {

    @Prop({ required: true })
    public unit!: Unit;

    /**
     * Returns the full class attribute for unit card.
     *
     * @return {string[]}
     */
    public get unitCardClass(): string[] {
        return [
            "unit-card",
            "text-light",
            "bg-success-transparent",
            "my-3",
            "shadow-sm"
        ];
    }

    /**
     * Returns the unit district name.
     *
     * @return {string}
     */
    public get unitDistrictName(): string {
        if (this.unit.district) {
            return this.unit.district.name;
        }

        return "";
    }

    /**
     * Whether to show the aside menu with actions or not.
     *
     * @return {boolean}
     */
    public get displayAsideMenu(): boolean {
        return this.canAll([
            this.allPermissions.unit.edit,
            this.allPermissions.unit.delete
        ]);
    }

    /**
     * Returns dropdown id string.
     *
     * @return {string}
     */
    public get dropdownId(): string {
        return "unit-card-" + this.unit.id + "-dropdown";
    }

    /**
     * Whether to show the dropdown menu.
     *
     * @return {boolean}
     */
    public get showMenu(): boolean {
        return this.can(this.allPermissions.unit.edit) || this.can(this.allPermissions.unit.delete);
    }

    /**
     * Handles "edit unit" button click.
     *
     * @private
     * @return {void}
     */
    private onUnitEdit(): void {
        this.redirectTo("units.edit", this.unit.id);
    }

    /**
     * Handles "delete unit" button click.
     *
     * @private
     * @return void
     */
    private onUnitDelete(): void {
        const { id, name } = this.unit;
        const question = this.$lang("Czy na pewno chcesz usunąć jednostkę \":name\"?", { name });

        this.confirmationModal({
            question,
            onConfirm: () => Unit.deleteRemote(id)
        });
    }

}
