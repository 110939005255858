













import Vue from "vue";
import Component from "vue-class-component";
import Loading from "vue-loading-overlay";
import { Prop } from "vue-property-decorator";

export type LoaderType = "bars" | "dots" | "spinner";

export type ActiveFunction = () => boolean;

@Component({
    components: { Loading }
})
export default class VLoading extends Vue {

    @Prop({ default: null })
    public active!: ActiveFunction;

    @Prop({ default: 0.6 })
    public opacity!: number;

    @Prop({ default: "dots" })
    public loader!: LoaderType;

    @Prop({ default: false })
    public canCancel!: boolean;

    @Prop({ default: false })
    public isFullPage!: boolean;

    @Prop({ default: false })
    public dark!: boolean;

    /**
     * Returns the loading component color.
     *
     * @return {string}
     */
    private get color(): string {
        return this.isDark ? "#fff" : "#000";
    }

    /**
     * Returns the loading component background color.
     *
     * @return {string}
     */
    private get backgroundColor(): string {
        return this.isDark ? "#000" : "#fff";
    }

    /**
     * Checks whether the dark variant is set.
     *
     * @private
     * @return {boolean}
     */
    private get isDark(): boolean {
        const dark = this.dark as any;

        return (typeof dark !== "undefined" && dark !== false);
    }

}
