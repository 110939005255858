




import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import contributions_statuses from "../../../json/contributions_statuses.json";
import VIcon from "../../components/VIcon.vue";

@Component({
    components: { VIcon }
})
export default class ScoutCardContributionStatus extends Vue {

    @Prop({ required: true })
    public status!: number;

    /**
     * Returns the status icon string.
     *
     * @return {string}
     */
    public get icon(): string {
        let icon = "fas fa-question";

        switch (this.status) {
            case contributions_statuses.paid:
                icon = "fas fa-check";
                break;
            case contributions_statuses.unpaid:
                icon = "fas fa-times text-danger";
                break;
            case contributions_statuses.out_of_range:
            case contributions_statuses.not_applicable:
                icon = "fas fa-minus";
                break;
        }

        return icon;
    }

}
