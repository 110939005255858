








































import Component, { mixins } from "vue-class-component";
import VSection from "../../../components/VSection.vue";
import FormMixin, { SubmittedDataInterface } from "../../../mixins/FormMixin";
import District from "../../../models/District";
import VButton from "../../../components/VButton.vue";
import { VueEditor } from "vue2-editor";

@Component({
    components: {
        VSection,
        VButton,
        VueEditor
    }
})
export default class DistrictForm extends mixins(FormMixin) {

    /**
     * The form data.
     *
     * @type Object
     */
    public formData = {
        name: "",
        transfer_details: ""
    }

    /**
     * Custom toolbar elements.
     *
     * @type {Array}
     */
    public customToolbar = [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike", "clean"],
        [{ list: "ordered" }, { list: "bullet" }]
    ];

    /**
     * On component created.
     *
     * @return {Promise<void>}
     */
    public async created(): Promise<void> {
        if (this.isEditForm) {
            const id = this.routeParam("district")!;

            this.showLoading();

            const district = await District.editRemote(id) as District;

            this.formData = {
                name: district.name,
                transfer_details: district.transfer_details
            };

            this.hideLoading();
        }
    }

    /**
     * Returns the data for submitting a form.
     *
     * @return {SubmittedDataInterface}
     */
    public getSubmittedData(): SubmittedDataInterface {
        const { name, transfer_details } = this.formData;
        const data = {
            name,
            transfer_details
        };

        return {
            model: District,
            data
        };
    }

    /**
     * On transfer details label click.
     *
     * @return {void}
     */
    public onTransferDetailsLabelClick(): void {
        (this.$refs["transfer_details"] as any).quill.focus();
    }

}
