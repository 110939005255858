import { Response } from "@vuex-orm/plugin-axios";
import Toaster from "../Toaster";

export default class ServerResponseHandler {

    /**
     * Handles an successful response from the server.
     *
     * @param {Response} response
     * @return {void}
     */
    public static handle(response: Response): void {
        // We accept only responses from successful requests
        if (response.response.status !== 200) {
            return;
        }

        if (typeof response.response.data === "string") {
            Toaster.success(response.response.data);
            return;
        }

        if (typeof response.response.data.message === "string") {
            Toaster.success(response.response.data.message);
            return;
        }

        Toaster.success(response.response.statusText);
    }

}
