import { Model } from "@vuex-orm/core";
import { Response } from "@vuex-orm/plugin-axios";
import PaginationInterface from "../interfaces/PaginationInterface";

export default class Pagination extends Model {

    /**
     * Returns Pagination model for given VuexORM model.
     *
     * @param {string|Model} model
     * @return {Pagination|null}
     */
    public static for(model: string | typeof Model): PaginationInterface {
        model = typeof model === "string" ? model : model.entity;

        // We query the first pagination model. If the search
        // fails we leave it as an empty object
        const pagination = Pagination.query()
            .where("model", model)
            .first() || {};

        return pagination as PaginationInterface;
    }

    /**
     * Creates or updates the pagination data for given model.
     *
     * @param {string} model
     * @param {Response} response
     * @return {void}
     */
    public static fromResponse(model: string, response: Response): void {
        // We double-check that the pagination is present
        // in the server response data
        if (!response.response.data.pagination) {
            return;
        }

        Pagination.insertOrUpdate({
            data: {
                model,
                ...response.response.data.pagination,
                timestamp: Date.now()
            }
        });
    }

    /**
     * The name that is going be used as module name in Vuex Store.
     *
     * @type string
     */
    public static entity = "pagination";

    /**
     * The primary key to be used for the model.
     *
     * @type string
     */
    public static primaryKey = "model";

    /**
     * The definition of the fields of the model and its relations.
     *
     * @return {Object}
     */
    public static fields() {
        return {
            model: this.string(""),
            total: this.number(0),
            per_page: this.number(0),
            current_page: this.number(0),
            last_page: this.number(0),
            first_page_url: this.string(null).nullable(),
            last_page_url: this.string(null).nullable(),
            next_page_url: this.string(null).nullable(),
            prev_page_url: this.string(null).nullable(),
            path: this.string(""),
            from: this.number(0),
            to: this.number(0)
        };
    }

    /**
     * Total count of the resource.
     *
     * @type number
     */
    public total!: number;

    /**
     * The number of results per page.
     *
     * @type number
     */
    public per_page!: number;

    /**
     * The current page.
     *
     * @type number
     */
    public current_page!: number;

    /**
     * The number of the last page.
     *
     * @type number
     */
    public last_page!: number;

    /**
     * The absolute URL of the first page.
     *
     * @type string|null
     */
    public first_page_url!: string | null;

    /**
     * The absolute URL of the last page.
     *
     * @type string|null
     */
    public last_page_url!: string | null;

    /**
     * The absolute URL to the next page.
     *
     * @type string|null
     */
    public next_page_url!: string | null;

    /**
     * The absolute URL to the previous page.
     *
     * @type string|null
     */
    public prev_page_url!: string | null;

    /**
     * The path of the request.
     *
     * @type string
     */
    public path!: string;

    /**
     * The starting offset of the pagination.
     *
     * @type number
     */
    public from!: number;

    /**
     * The ending offset of the pagination.
     *
     * @type number
     */
    public to!: number;

}
