













import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Role from "../../models/Role";

export type SizeType = "xs" | "sm" | "md" | "lg" | "xl";

@Component
export default class RoleSelect extends Vue {

    @Prop({ default: undefined })
    public hideLabel!: boolean;

    @Prop({ default: "md" })
    public size!: SizeType;

    @Prop({ default: undefined })
    public inlineLabel!: boolean;

    @Prop({ default: "" })
    public toggleClass!: string;

    /**
     * Returns the button class string.
     *
     * @return {string}
     */
    public get buttonClass(): string {
        return "btn-" + this.size + " " + this.toggleClass;
    }

    /**
     * Returns the role model class.
     *
     * @return {typeof Role}
     */
    public get roleModel(): typeof Role {
        return Role;
    }

}
