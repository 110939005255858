










import Component from "vue-class-component";
import { Prop, Vue } from "vue-property-decorator";

@Component
export default class VIcon extends Vue {

    @Prop({ required: true })
    public icon!: string;

    @Prop({ default: true })
    public fixedWidth!: boolean;

    @Prop({ default: 0 })
    public rotation!: number;

    @Prop({ default: undefined })
    public disableRotation?: boolean;

    /**
     * Returns full icon class.
     *
     * @return {string}
     */
    public get iconClass(): string {
        const hasIconClass = (typeof this.disableRotation === "undefined");
        const iconClass = [];

        if (hasIconClass) {
            iconClass.push("icon");
        }

        iconClass.push(this.icon);

        if (this.fixedWidth) {
            iconClass.push("fa-fw");
        }

        return iconClass.join(" ");
    }

    /**
     * Returns full icon style value.
     *
     * @return {Object}
     */
    public get iconStyle(): object {
        return {
            transform: "rotate(" + this.rotation + "deg) scale(1)"
        };
    };

}
