import RolePermissionScope from "../models/RolePermissionScope";
import permissionsCategories from "../../json/permissions_categories.json";

export default class PermissionScope {

    /**
     * Creates new PermissionScope objects array from RolePermissionScope models array.
     *
     * @param {RolePermissionScope[]} rolePermissionScopes
     * @return {PermissionScope[]}
     */
    public static from(rolePermissionScopes: RolePermissionScope[]): PermissionScope[] {
        return rolePermissionScopes.map((rolePermissionScope: RolePermissionScope) => {
            return new PermissionScope(
                rolePermissionScope.id,
                permissionsCategories[rolePermissionScope.permission.name as keyof typeof permissionsCategories],
                rolePermissionScope.permission.name,
                rolePermissionScope.scope,
                PermissionScope.createValuesArray(rolePermissionScope.values)
            );
        });
    }

    /**
     * Returns array of values for given string value.
     *
     * @private
     * @param {string} value
     * @return {number[]}
     */
    private static createValuesArray(value: string): number[] {
        if (value.length > 0) {
            return value.split(",").map((value: string) => parseInt(value));
        }

        return [];
    }

    /**
     * Creates a permission scope instance.
     *
     * @param {number} id
     * @param {string} category
     * @param {string} permission
     * @param {string} scope
     * @param {number[]} values
     * @return {void}
     */
    public constructor(
        public id: number,
        public category: string,
        public permission: string,
        public scope: string,
        public values: number[]
    ) {}

}
