import Vue from "vue";
import "./bootstrap";
import TheApp from "./components/TheApp.vue";
import ToastMixin from "./mixins/ToastMixin";
import router from "./router/index";
import store from "./store";

// We create the main Vue component
const app = new Vue({
    el: "#app",
    components: { TheApp },
    mixins: [
        ToastMixin
    ],
    render: (createElement) => createElement(TheApp),
    router,
    store
});

export default app;
