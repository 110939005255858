










import Vue from "vue";
import Component from "vue-class-component";
import VIcon from "../VIcon.vue";
import VNavbarToggle from "../VNavbarToggle.vue";
import TheNavbarBrand from "./TheNavbarBrand.vue";
import TheNavbarMenu from "./TheNavbarMenu.vue";

@Component({
    components: {
        VNavbarToggle,
        VIcon,
        TheNavbarMenu,
        TheNavbarBrand
    }
})
export default class TheNavbar extends Vue {}
