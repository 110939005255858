






import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

export type PaddingType = 1 | 2 | 3 | 4 | 5;

@Component
export default class VSection extends Vue {

    @Prop({ default: 4 })
    public padding!: PaddingType;

    /**
     * Returns full section class attribute.
     *
     * @private
     * @return {string[]}
     */
    private get sectionClass(): string[] {
        return [
            "bg-success-transparent",
            "text-white",
            "p-" + this.padding,
            "rounded",
            "shadow"
        ];
    }

}
