import lodash from "lodash";

/**
 * Returns translation for given text and (optional) arguments.
 *
 * @param {string} text - Text to translate
 * @param args - Optional text arguments
 * @return string
 */
const __ = (text: string, args?: any) => {
    // The translation is given by Laravel using "language" route,
    // if the translation was not provided - it's the text itself
    let value = lodash.get(window.i18n, text) || text;

    // We apply changes to arguments using lodash
    lodash.eachRight(args, (paramValue, paramKey) => {
        value = lodash.replace(value, ":" + paramKey, paramValue);
    });

    return value;
};

export default __;
