










import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ResourceCards from "../../components/ResourceCards.vue";
import PaginationInterface from "../../interfaces/PaginationInterface";
import Unit from "../../models/Unit";
import UnitCard from "./UnitCard.vue";

@Component({
    components: {
        ResourceCards,
        UnitCard
    }
})
export default class UnitCards extends Vue {

    @Prop({ required: true })
    public units!: Unit[];

    @Prop({ required: true })
    public pagination!: PaginationInterface;

    /**
     * Returns the "key" attribute value.
     *
     * @param {number} id
     * @return string
     */
    public unitKey(id: number): string {
        return `unit-card-${id}`;
    }

}
