






























import Component, { mixins } from "vue-class-component";
import VButton from "../../components/VButton.vue";
import LastChargeDay from "../../components/VueFormulate/LastChargeDay.vue";
import YearEnd from "../../components/VueFormulate/YearEnd.vue";
import YearStart from "../../components/VueFormulate/YearStart.vue";
import FormMixin, { SubmittedDataInterface } from "../../mixins/FormMixin";
import Setting from "../../models/Setting";

@Component({
    components: {
        VButton,
        LastChargeDay,
        YearEnd,
        YearStart
    }
})
export default class SettingsMain extends mixins(FormMixin) {

    /**
     * The form data.
     *
     * @type {Object}
     */
    public formData = {
        year_start: "",
        year_end: "",
        last_charge_day: ""
    };

    /**
     * On component created.
     *
     * @return {Promise<void>}
     */
    public async created(): Promise<void> {
        await this.withLoading(async () => {
            await Setting.index();

            const settings = Setting.getSettingsObject();

            this.formData = {
                year_start: settings["CONTRIBUTIONS_YEAR_START"],
                year_end: settings["CONTRIBUTIONS_YEAR_END"],
                last_charge_day: settings["CONTRIBUTIONS_LAST_CHARGE_DAY"]
            };
        });
    }

    /**
     * Whether the form is in edit mode.
     *
     * @return {boolean}
     */
    public get isEditForm(): boolean {
        return true;
    }

    /**
     * Returns the data for submitting a form.
     *
     * @return {SubmittedDataInterface}
     */
    public getSubmittedData(): SubmittedDataInterface {
        const {
            year_start,
            year_end,
            last_charge_day
        } = this.formData;

        return {
            model: Setting,
            data: {
                settings: [
                    { name: "CONTRIBUTIONS_YEAR_START", value: year_start },
                    { name: "CONTRIBUTIONS_YEAR_END", value: year_end },
                    { name: "CONTRIBUTIONS_LAST_CHARGE_DAY", value: last_charge_day }
                ]
            }
        };
    }

}
