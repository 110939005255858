/**
 * This file defines the scopes available for each permission.
 */

import scopes from "../../json/role_permission_scopes.json";
import permissions from "../../json/permissions.json";

interface PermissionScopesInterface {
    [permission: string]: string[];
}

const permissionsScopes = {

    // Role
    [permissions.role.access]: [
        scopes.ids,
        scopes.common.roles
    ],

    // User
    [permissions.user.access]: [
        scopes.ids,
        scopes.roles,
        scopes.common.units,
        scopes.common.districts,
        scopes.common.roles,
        scopes.related.districts,
        scopes.related.units
    ],
    [permissions.user.assign.role]: [
        scopes.ids,
        scopes.common.roles
    ],
    [permissions.user.assign.district]: [
        scopes.ids,
        scopes.common.districts
    ],
    [permissions.user.assign.unit]: [
        scopes.ids,
        scopes.common.units
    ],

    // District
    [permissions.district.access]: [
        scopes.ids,
        scopes.common.districts
    ],

    // Unit
    [permissions.unit.access]: [
        scopes.ids,
        scopes.common.units,
        scopes.common.districts,
        scopes.referencing.district
    ],
    [permissions.unit.assign.district]: [
        scopes.ids,
        scopes.common.districts
    ],

    // Scout
    [permissions.scout.access]: [
        scopes.ids,
        scopes.common.districts,
        scopes.common.units,
        scopes.related.districts,
        scopes.related.units
    ],
    [permissions.scout.assign.unit]: [
        scopes.ids,
        scopes.common.units
    ],

};

export { PermissionScopesInterface };

export default permissionsScopes as PermissionScopesInterface;
