









































































import Component, { mixins } from "vue-class-component";
import Router from "../../classes/Router";
import DropdownSelect from "../../components/DropdownSelect.vue";
import TwoColumns from "../../components/layouts/TwoColumns.vue";
import VButton from "../../components/VButton.vue";
import VPageWindow from "../../components/VPage/VPageWindow.vue";
import DistrictSelect from "../../components/VueFormulate/DistrictSelect.vue";
import UnitSelect from "../../components/VueFormulate/UnitSelect.vue";
import PaginationInterface from "../../interfaces/PaginationInterface";
import PaginationMixin from "../../mixins/PaginationMixin";
import SearchingMixin from "../../mixins/SearchingMixin";
import District from "../../models/District";
import Pagination from "../../models/Pagination";
import Scout from "../../models/Scout";
import Unit from "../../models/Unit";
import ScoutCard from "./ScoutCard.vue";
import ScoutCards from "./ScoutCards.vue";
import ScoutEmpty from "./ScoutEmpty.vue";

export enum ContributionsDisplayMode {
    Edit = "edit",
    Show = "show"
}

@Component({
    components: {
        DropdownSelect,
        TwoColumns,
        VButton,
        UnitSelect,
        DistrictSelect,
        ScoutEmpty,
        ScoutCards,
        ScoutCard,
        VPageWindow
    }
})
export default class Index extends mixins(PaginationMixin, SearchingMixin) {

    /**
     * District filter.
     *
     * @type {District|null}
     */
    public districtFilter: District | null = null;

    /**
     * Unit filter.
     *
     * @type {Unit|null}
     */
    public unitFilter: Unit | null = null;

    /**
     * Whether to show archived scouts.
     *
     * @type {boolean}
     */
    public archived: boolean = false;

    /**
     * The display mode of contributions.
     *
     * @type {string}
     */
    public contributionsDisplayMode = "edit";

    /**
     * On component created.
     *
     * @return {Promise<void>}
     */
    public async created(): Promise<void> {
        return this.withLoading(async () => {
            const districtId = Router.getDistrictId();
            const unitId = Router.getUnitId();
            const archived = Router.getArchived();

            await this.requestScouts();

            if (districtId) {
                this.districtFilter = await District.getRemote(districtId);
            }

            if (unitId) {
                this.unitFilter = await Unit.getRemote(unitId);
            }

            this.archived = !!archived;

            this.firstSearch = true;
        });
    }

    /**
     * Returns the page description.
     *
     * @return {string}
     */
    public get description(): string {
        return this.$lang("Zarządzaj harcerzami w hufcach i jednostkach.");
    }

    /**
     * Returns an array of scouts.
     *
     * @return {Scout[]}
     */
    public get scouts(): Scout[] {
        return Scout.orderByDisplayName();
    }

    /**
     * Returns pagination data.
     *
     * @return {PaginationInterface}
     */
    public get pagination(): PaginationInterface {
        return Pagination.for(Scout);
    }

    /**
     * Handle searching the resource.
     *
     * @return {Promise<void>}
     */
    public async onSearch(): Promise<void> {
        await this.requestScouts();
    }

    /**
     * Handle the page change.
     *
     * @param {number} page
     * @return {Promise<void>}
     */
    public async onPageChange(page: number): Promise<void> {
        await this.requestScouts();
    }

    /**
     * On district filter change.
     *
     * @param {District|null} district
     * @return {Promise<void>}
     */
    public async onDistrictFilterChange(district: District | null): Promise<void> {
        return this.withLoading(async () => {
            this.unitFilter = null;

            await Router.setDistrictId(district ? district.id : null);

            await this.requestScouts();
        });
    }

    /**
     * On unit filter change.
     *
     * @param {Unit|null} unit
     * @return {Promise<void>}
     */
    public async onUnitFilterChange(unit: Unit | null): Promise<void> {
        return this.withLoading(async () => {
            await Router.setUnitId(unit ? unit.id : null);

            await this.requestScouts();
        });
    }

    /**
     * On scout status change.
     *
     * @param {Object} option
     * @return {Promise<void>}
     */
    public async onStatusChange(option: any): Promise<void> {
        return this.withLoading(async () => {
            await Router.setArchived(option.value as boolean);

            await this.requestScouts();
        });
    }

    /**
     * Requests all scouts from the server.
     *
     * @return {Promise<void>}
     */
    public async requestScouts(): Promise<void> {
        await Scout.index({
            district_id: Router.getDistrictId(),
            unit_id: Router.getUnitId(),
            archived: Router.getArchived() ? 1 : 0
        });
    }

}
