import { AxiosError } from "axios";
import { EVENT_NOTIFICATION_SERVER_ERROR, EventBus } from "../../event-bus";
import { EVENT_ERROR_INPUT } from "../../event-bus";
import Router from "../Router";

export default class ServerErrorHandler {

    /**
     * Handles an error coming from axios request.
     *
     * @param {AxiosError} error
     * @return void
     */
    public static handle(error: AxiosError): void {
        if (error.response?.data) {
            const data = error.response.data;

            if (error.response.status === 422) {
                if (data.errors) {
                    // We handle the input errors
                    ServerErrorHandler.handleInputErrors(data.errors);
                } else {
                    // We handle the input errors
                    ServerErrorHandler.handleInputErrors(data);
                }
            } else if (typeof data === "string") {
                // We create an error notification from the server message
                ServerErrorHandler.createToast(data, error.response.status);
            } else if (data.message) {
                // We check if there is a custom message provided
                ServerErrorHandler.createToast(data.message, error.response.status);
            } else {
                // We just log the error in the console
                console.log(error);
            }
        } else {
            // We just log the error in the console
            console.log(error);
        }
    }

    /**
     * Handles the error by handle() static method and goes back in history.
     *
     * @param {AxiosError} error
     * @return {void}
     */
    public static handleAndGoBack(error: AxiosError): void {
        // We handle the error
        ServerErrorHandler.handle(error);

        // We go back in history
        Router.back();
    }

    /**
     * Handles input errors sent by the server caused by
     * the 422 Unprocessable Entity error.
     *
     * @param errors
     * @return void
     */
    private static handleInputErrors(errors: any): void {
        const messages = [];

        for (const errorKey in errors) {
            if (!errors.hasOwnProperty(errorKey)) {
                continue;
            }

            const values = errors[errorKey];

            if (Array.isArray(values)) {
                messages.push(...values);
            } else if (typeof values === "string") {
                messages.push(values);
            }
        }

        ServerErrorHandler.notify(messages);
    }

    /**
     * Fires an event that will create an error notification.
     *
     * @param {string[]} messages
     * @param {number|null} status
     * @return void
     */
    private static notify(messages: string[], status: number | null = null): void {
        EventBus.$emit(EVENT_ERROR_INPUT, messages);
    }

    /**
     * Fires an event that will create a toast notification.
     *
     * @private
     * @param message
     * @param status
     * @return void
     */
    private static createToast(message: string, status: number | null = null): void {
        EventBus.$emit(EVENT_NOTIFICATION_SERVER_ERROR, {
            message: status ? (status + ": " + message) : message
        });
    }

}
