import Vue from "vue";
import Component from "vue-class-component";
import { EVENT_SHOW_LOADING } from "../event-bus";

declare module "vue/types/vue" {
    interface Vue {
        showLoading(show?: boolean): void;
        hideLoading(): void;
        withLoading(callback: (...args: any[]) => Promise<void>): Promise<void>;
    }
}

@Component
export default class LoadingMixin extends Vue {

    /**
     * Toggles the loading overlay.
     *
     * @param {boolean} show
     * @return {void}
     */
    public showLoading(show: boolean = true): void {
        this.$eventBus.$emit(EVENT_SHOW_LOADING, show);
    }

    /**
     * Hides the loading overlay.
     *
     * @return {void}
     */
    public hideLoading(): void {
        this.showLoading(false);
    }

    /**
     * Calls given method with loading screen.
     *
     * @param callback
     */
    public async withLoading(callback: () => Promise<void>): Promise<void> {
        this.showLoading()

        await callback();

        this.hideLoading();
    }

}
