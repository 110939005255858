
















































import Component, { mixins } from "vue-class-component";
import VButton from "../../components/VButton.vue";
import VPageWindow from "../../components/VPage/VPageWindow.vue";
import VSection from "../../components/VSection.vue";
import Password from "../../components/VueFormulate/Password.vue";
import FormMixin, { SubmittedDataInterface } from "../../mixins/FormMixin";
import Account from "../../models/Account";

@Component({
    components: {
        VButton,
        VSection,
        Password,
        VPageWindow
    }
})
export default class Index extends mixins(FormMixin) {

    /**
     * The form data.
     *
     * @type {Object}
     */
    public formData = {
        current_password: "",
        new_password: "",
        new_password_confirmation: ""
    };

    /**
     * Checks whether the form is in edit mode.
     *
     * @return {boolean}
     */
    public get isEditForm(): boolean {
        return true;
    }

    /**
     * Returns the data for submitting a form.
     *
     * @return {SubmittedDataInterface}
     */
    public getSubmittedData(): SubmittedDataInterface {
        return {
            model: Account,
            data: this.formData
        };
    }

}
