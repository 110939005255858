import Vue from "vue";
import Component from "vue-class-component";
import { EVENT_SEARCH } from "../event-bus";
import SearchPayload from "../interfaces/SearchPayload";

@Component
export default class SearchingMixin extends Vue {

    /**
     * Whether there is a ongoing search request.
     *
     * @type boolean
     */
    public isSearching: boolean = false;

    /**
     * Whether the first search is already finished.
     *
     * @type boolean
     */
    public firstSearch: boolean = false;

    /**
     * On component created.
     *
     * @return {void}
     */
    public created(): void {
        this.$eventBus.$on(EVENT_SEARCH, this.handleSearch);
    }

    /**
     * Before the component is destroyed.
     *
     * @return {void}
     */
    public beforeDestroy(): void {
        this.$eventBus.$off(EVENT_SEARCH, this.handleSearch);
    }

    /**
     * Whether to show resource-empty related component.
     *
     * @return {boolean}
     */
    public get showResourceEmpty(): boolean {
        return (!this.isSearching && this.firstSearch);
    }

    /**
     * Handles search event.
     *
     * @private
     * @param {SearchPayload} searchPayload
     * @return {Promise<void>}
     */
    private async handleSearch(searchPayload: SearchPayload): Promise<void> {
        this.showLoading();

        this.isSearching = true;

        await this.onSearch(searchPayload);

        this.isSearching = false;

        this.hideLoading();
    }

    /**
     * Handle searching the resource.
     *
     * @param {SearchPayload} searchPayload
     * @return {Promise<void>}
     */
    public async onSearch(searchPayload: SearchPayload): Promise<void> {
        // This method is overridden in other components
    }

}
