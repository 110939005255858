import { NavigationGuard, Route } from "vue-router";
import Router from "../classes/Router";
import Auth from "../models/Auth";

/**
 * Default name of the route to redirect the user to if they are unauthorized.
 *
 * @type string
 */
export const DEFAULT_UNAUTHORIZED_PATH = "login";

/**
 * Default name of the route to redirect the user to after authorization.
 *
 * @type string
 */
export const DEFAULT_AUTHORIZED_PATH = "home";

/**
 * Navigation guard allowing only guest users to pass.
 *
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 * @return void
 */
export function allowGuests(to: Route, from: Route, next: Function): void {
    if (Auth.check()) {
        return next({ name: DEFAULT_AUTHORIZED_PATH });
    }

    next();
}

/**
 * Navigation guard allowing only authorized users to pass.
 *
 * @param {Route} to
 * @param {Route} from
 * @param {Route} next
 * @return void
 */
export function allowAuthorized(to: Route, from: Route, next: Function): void {
    if (!Auth.check()) {
        return next({ name: DEFAULT_UNAUTHORIZED_PATH });
    }

    next();
}

/**
 * Creates a navigation guard allowing only authorized users with given permission to pass.
 *
 * @param {string} permission
 * @return NavigationGuard
 */
export function hasPermission(permission: string): NavigationGuard {
    return (to: Route, from: Route, next: Function) => {
        if (Auth.can(permission)) {
            return next();
        }

        next(Router.intended(DEFAULT_UNAUTHORIZED_PATH));
    };
}
