import Vue from "vue";
import Component from "vue-class-component";
import {
    EVENT_NOTIFICATION_ERROR,
    EVENT_NOTIFICATION_INFO,
    EVENT_NOTIFICATION_SERVER_ERROR,
    EVENT_NOTIFICATION_SUCCESS,
    EVENT_NOTIFICATION_WARNING
} from "../event-bus";
import NotificationInterface from "../interfaces/NotificationInterface";

@Component
export default class ToastMixin extends Vue {

    /**
     * On component created.
     *
     * @return void
     */
    public created(): void {
        this.$eventBus
            .$on(EVENT_NOTIFICATION_INFO, this.createInfoToast)
            .$on(EVENT_NOTIFICATION_SUCCESS, this.createSuccessToast)
            .$on(EVENT_NOTIFICATION_WARNING, this.createWarningToast)
            .$on(EVENT_NOTIFICATION_ERROR, this.createErrorToast)
            .$on(EVENT_NOTIFICATION_SERVER_ERROR, this.createServerErrorToast);
    }

    /**
     * Before the component is destroyed.
     *
     * @return void
     */
    public beforeDestroy(): void {
        this.$eventBus
            .$off(EVENT_NOTIFICATION_INFO, this.createInfoToast)
            .$off(EVENT_NOTIFICATION_SUCCESS, this.createSuccessToast)
            .$off(EVENT_NOTIFICATION_WARNING, this.createWarningToast)
            .$off(EVENT_NOTIFICATION_ERROR, this.createErrorToast)
            .$off(EVENT_NOTIFICATION_SERVER_ERROR, this.createServerErrorToast);
    }

    /**
     * Creates an info toast.
     *
     * @param notification
     * @return void
     */
    private createInfoToast(notification: NotificationInterface): void {
        this.createToast("info", {
            ...notification,
            title: notification.title ?? this.getInfoTitle()
        });
    }

    /**
     * Creates a success toast.
     *
     * @param notification
     * @return void
     */
    private createSuccessToast(notification: NotificationInterface): void {
        this.createToast("success", {
            ...notification,
            title: notification.title ?? this.getSuccessTitle()
        });
    }

    /**
     * Creates a warning toast.
     *
     * @param notification
     * @return void
     */
    private createWarningToast(notification: NotificationInterface): void {
        this.createToast("warning", {
            ...notification,
            title: notification.title ?? this.getWarningTitle()
        });
    }

    /**
     * Creates an error message toast.
     *
     * @param notification
     * @return void
     */
    private createErrorToast(notification: NotificationInterface): void {
        this.createToast("danger", {
            ...notification,
            title: notification.title ?? this.getErrorTitle()
        });
    }

    /**
     * Creates a toast with error message from server.
     *
     * @param notification
     * @return void
     */
    private createServerErrorToast(notification: NotificationInterface): void {
        this.createToast("danger", {
            ...notification,
            title: notification.title ?? this.getServerErrorTitle()
        });
    }

    /**
     * Creates new toast of given type.
     *
     * @param type
     * @param notification
     * @return void
     */
    private createToast(type: string, notification: NotificationInterface): void {
        this.$bvToast.toast(notification.message, {
            variant: type,
            title: notification.title
        });
    }

    /**
     * Returns default info title.
     *
     * @return string
     */
    private getInfoTitle(): string {
        return this.$lang("Informacja");
    }

    /**
     * Returns default success title.
     *
     * @return string
     */
    private getSuccessTitle(): string {
        return this.$lang("Sukces!");
    }

    /**
     * Returns default warning title.
     *
     * @return string
     */
    private getWarningTitle(): string {
        return this.$lang("Ostrzeżenie!");
    }

    /**
     * Returns default error title.
     *
     * @return string
     */
    private getErrorTitle(): string {
        return this.$lang("Wystąpił błąd!");
    }

    /**
     * Returns default server error title.
     *
     * @return string;
     */
    private getServerErrorTitle(): string {
        return this.$lang("Wystąpił błąd serwera!");
    }

}
