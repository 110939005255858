import Fields from "@vuex-orm/core/lib/model/contracts/Fields";
import ServerErrorHandler from "../classes/ServerResponseHandler/ServerErrorHandler";
import ServerResponseHandler from "../classes/ServerResponseHandler/ServerResponseHandler";
import Model from "./Model";

export default class Account extends Model {

    /**
     * Sends request to patch specific resource with given data.
     *
     * @param {number|string} id
     * @param {Object} data
     * @return {Promise<void>}
     */
    public static updateRemote<T extends typeof Model>(this: T, id: number | string, data: any): Promise<void> {
        return this.api()
            .patch("/account", data, { save: false })
            .then(ServerResponseHandler.handle)
            .catch(ServerErrorHandler.handle);
    }

    /**
     * The name that is going be used as module name in Vuex Store.
     *
     * @type string
     */
    public static entity = "account";

    /**
     * The definition of the fields of the model and its relations.
     *
     * @return Object
     */
    public static fields(): Fields {
        return {
            //
        };
    }

}
