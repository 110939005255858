import VueFormulate from "@braid/vue-formulate";
import "bootstrap";
import BootstrapVue from "bootstrap-vue";
import jQuery from "jquery";
import lodash from "lodash";
import Popper from "popper.js";
import Vue from "vue";
import VueCookies from "vue-cookies";
import Sticky from "vue-sticky-directive";
import UniqueId from "vue-unique-id";
import permissionsScopes from "../json/role_permission_scopes.json";
import VCheckbox from "./components/VCheckbox.vue";
import VFormulateSelect from "./components/VFormulateSelect.vue";
import VFormulateSelectPaginated from "./components/VFormulateSelectPaginated.vue";
import VFormulateDatepicker from "./components/VueFormulate/custom/VFormulateDatepicker.vue";
import VFormulateModelSelect from "./components/VueFormulate/custom/VFormulateModelSelect.vue";
import "./directives/enter-directive";
import "./directives/tooltip-directive";
import { EventBus } from "./event-bus";
import ApiRequestMixin from "./mixins/ApiRequestMixin";
import AuthMixin from "./mixins/AuthMixin";
import ComponentLifeCycleMixin from "./mixins/ComponentLifeCycleMixin";
import ConfirmationMixin from "./mixins/ConfirmationMixin";
import DestroyTooltipMixin from "./mixins/DestroyTooltipMixin";
import LoadingMixin from "./mixins/LoadingMixin";
import RedirectMixin from "./mixins/RedirectMixin";
import __ from "./utils/lang";
import permissionsDescriptions from "./utils/permissions-descriptions";
import scopesDescriptions from "./utils/scopes-descriptions";

try {

    // We append some properties and methods to
    // the window object as it is required by libraries
    window._ = lodash;
    window.Popper = Popper;
    window.$ = window.jQuery = jQuery;

    // We create custom Vue properties
    Vue.prototype.$eventBus = EventBus;
    Vue.prototype.$lang = Vue.prototype.__ = __;
    Vue.prototype.$lodash = lodash;
    Vue.prototype.allPermissionsDescriptions = permissionsDescriptions;
    Vue.prototype.permissionsScopes = permissionsScopes;
    Vue.prototype.scopesDescriptions = scopesDescriptions;

    // We register custom Vue Formulate components earlier
    // so that Vue Formulate can see and access them
    Vue.component("VCheckbox", VCheckbox);
    Vue.component("VFormulateSelect", VFormulateSelect);
    Vue.component("VFormulateSelectPaginated", VFormulateSelectPaginated);
    Vue.component("VFormulateModelSelect", VFormulateModelSelect);
    Vue.component("VFormulateDatepicker", VFormulateDatepicker);

    // We use the unique id plugin in order to be able
    // to assign ids to Vue components
    Vue.use(UniqueId);

    // We use all the Bootstrap Vue components and features
    Vue.use(BootstrapVue);

    // We use a plugin for cookies support
    Vue.use(VueCookies);

    // We use sticky directive plugin
    Vue.use(Sticky);

    // We register the auth mixin that stores provides methods
    // determining if user is authorized to perform some actions
    Vue.mixin(AuthMixin);

    // We register the API request mixin that stores information
    // about the number of pending requests and therefore helps
    // to organize the async functions around it
    Vue.mixin(ApiRequestMixin);

    // We register the confirmation mixin which allows all components
    // to create a confirmation model if necessary
    Vue.mixin(ConfirmationMixin);

    // We register the mixin that hides all tooltips when
    // any component is destroyed
    Vue.mixin(DestroyTooltipMixin);

    // We use redirect helper mixin that provides shortcuts
    // for redirecting to other Vue Router routes
    Vue.mixin(RedirectMixin);

    // We use component life cycle mixin which stores data
    // about current component life cycle phase
    Vue.mixin(ComponentLifeCycleMixin);

    // We use loading mixin which allows to toggle the loading
    // overlay from anywhere
    Vue.mixin(LoadingMixin);

    // We use VueFormulate plugin (along with its configuration)
    // that handles form creation and validation. We also define
    // some global configuration for all VueFormulate components
    Vue.use(VueFormulate, {
        library: {
            "check": {
                classification: "box",
                component: "VCheckbox"
            },
            "dropdown": {
                classification: "select",
                component: "VFormulateSelect"
            },
            "dropdown-paginated": {
                classification: "select",
                component: "VFormulateSelectPaginated"
            },
            "model-select": {
                classification: "select",
                component: "VFormulateModelSelect"
            },
            "datepicker": {
                classification: "date",
                component: "VFormulateDatepicker"
            }
        },
        classes: {
            outer: (context: any) => {
                // We cannot append the same classes to checkbox element
                // as it is customized and won't work that way
                return [
                    "check",
                    "dropdown",
                    "dropdown-paginated",
                    "model-select"
                ].includes(context.type) ? [] : ["form-group"];
            },
            input: (context: any) => {
                // We cannot append the same classes to checkbox element
                // as it is customized and won't work that way
                return [
                    "check",
                    "dropdown",
                    "dropdown-paginated",
                    "model-select"
                ].includes(context.type) ? [] : ["form-control"];
            },
            error: "input-error-message text-white",
            errors: "input-errors-wrapper list-unstyled bg-danger-transparent rounded p-2 mt-2"
        },
        locales: {
            en: {
                required: () => __("Pole nie może być puste."),
                email: () => __("Wpisany tekst nie jest prawidłowym adresem e-mail."),
                object: () => __("Pole nie może być puste."),
                model: () => __("Pole nie może być puste.")
            }
        },
        rules: {
            object: (context: any) => (typeof context.value === "object"),
            model: (context: any) => (typeof context.value === "object")
        }
    });

    $(() => {
        (<any> $("body")).tooltip({
            // option required for tooltips not to flicker
            // inside the .table-responsive div element
            boundary: "window",

            selector: '[data-toggle="tooltip"]',
            animation: false,

            // option required for tooltips not to trigger
            // on buttons clicks
            trigger: "hover"
        });
    });

} catch (exception) {
    throw new Error("The setup was uneventful and brought no fruit in 'bootstrap.ts' :(");
}

// import Echo from "laravel-echo";
//
// window.Pusher = require("pusher-js");
//
// window.Echo = new Echo({
//     broadcaster: "pusher",
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
