






















import Vue from "vue";
import Component from "vue-class-component";
import EmailAddress from "../../components/VueFormulate/EmailAddress.vue";
import Password from "../../components/VueFormulate/Password.vue";
import VButton from "../../components/VButton.vue";
import LoginFormInterface from "../../interfaces/form/LoginFormInterface";
import Auth from "../../models/Auth";

@Component({
    components: {
        EmailAddress,
        Password,
        VButton
    }
})
export default class LoginForm extends Vue {

    /**
     * Form values store for the FormulateForm component.
     *
     * @type Object
     */
    public formValues: LoginFormInterface = <LoginFormInterface> {
        email: "",
        password: ""
    };

    /**
     * Login form submit listener.
     *
     * @return void
     */
    public onSubmit({ email, password }: LoginFormInterface): void {
        Auth.login(email, password);
    }

}
