










import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ResourceCards from "../../components/ResourceCards.vue";
import PaginationInterface from "../../interfaces/PaginationInterface";
import District from "../../models/District";
import DistrictCard from "./DistrictCard.vue";

@Component({
    components: {
        ResourceCards,
        DistrictCard
    }
})
export default class DistrictCards extends Vue {

    @Prop({ required: true })
    public districts!: District[];

    @Prop({ required: true })
    public pagination!: PaginationInterface;

    /**
     * Returns the "key" attribute value.
     *
     * @param {number} id
     * @return string
     */
    public districtKey(id: number): string {
        return `district-card-${id}`;
    }

}
