































import Component, { mixins } from "vue-class-component";
import TwoColumns from "../../components/layouts/TwoColumns.vue";
import VButton from "../../components/VButton.vue";
import VPageWindow from "../../components/VPage/VPageWindow.vue";
import PaginationInterface from "../../interfaces/PaginationInterface";
import PaginationMixin from "../../mixins/PaginationMixin";
import SearchingMixin from "../../mixins/SearchingMixin";
import District from "../../models/District";
import Pagination from "../../models/Pagination";
import DistrictCards from "./DistrictCards.vue";
import DistrictEmpty from "./DistrictEmpty.vue";

@Component({
    components: {
        VButton,
        TwoColumns,
        DistrictEmpty,
        DistrictCards,
        VPageWindow
    }
})
export default class Index extends mixins(PaginationMixin, SearchingMixin) {

    /**
     * On component created.
     *
     * @return {Promise<void>}
     */
    public async created(): Promise<void> {
        return this.withLoading(async () => {
            await District.index();

            this.firstSearch = true;
        });
    }

    /**
     * Returns an array of districts.
     *
     * @return {District[]}
     */
    public get districts(): District[] {
        return District.orderByName();
    }

    /**
     * Returns pagination data.
     *
     * @return {PaginationInterface}
     */
    public get pagination(): PaginationInterface {
        return Pagination.for(District);
    }

    /**
     * Handles the search event.
     *
     * @return {Promise<void>}
     */
    public async onSearch(): Promise<void> {
        await District.index();
    }

    /**
     * Handles the page change.
     *
     * @return {Promise<void>}
     */
    public async onPageChange(): Promise<void> {
        await District.index();
    }

}
