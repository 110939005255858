






















import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import VIcon from "./VIcon.vue";

@Component({
    components: { VIcon }
})
export default class VResourceNotFound extends Vue {

    /**
     * The Font Awesome icon to display.
     *
     * @type string
     */
    @Prop({ required: true })
    public icon!: string;

    /**
     * Whether to show component content.
     *
     * @type boolean
     */
    private showContent: boolean = false;

    /**
     * On component mounted.
     *
     * @return {void}
     */
    public mounted(): void {
        this.showContent = true;
    }

}
