












































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import VButton from "../../components/VButton.vue";
import Status from "../../models/Status";
import HowContributionsWork from "./HowContributionsWork.vue";

@Component({
    components: {
        VButton,
        HowContributionsWork
    }
})
export default class StatusUnpaid extends Vue {

    @Prop({ required: true })
    public status!: Status;

    /**
     * Texts for quarters keys.
     *
     * @type {Object}
     */
    public quarterTexts = {
        "quarter1": this.$lang("I kwartał"),
        "quarter2": this.$lang("II kwartał"),
        "quarter3": this.$lang("III kwartał"),
        "quarter4": this.$lang("IV kwartał")
    };

    /**
     * Options for localized date format.
     *
     * @type {Intl.DateTimeFormatOptions}
     */
    public dateFormatOptions: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long"
    };

    /**
     * Returns the date of last modification.
     *
     * @return {string}
     */
    public get lastModificationDate(): string {
        if (this.status.last_modified) {
            return new Date(this.status.last_modified).toLocaleDateString(undefined, this.dateFormatOptions);
        }

        return "";
    }

    /**
     * Returns text information about the arrears.
     *
     * @param {number} year
     * @param {string} quarter
     * @param {number} amount
     * @return {string}
     */
    public arrearsInformation(year: number, quarter: string, amount: number): string {
        const texts = this.quarterTexts;
        const quarterText = texts[quarter as keyof typeof texts];

        return [quarterText, year, this.$lang("roku"), "-", amount, this.$lang("zł")].join(" ");
    }

}
