import Vue from "vue";
import Component from "vue-class-component";

declare module "vue/types/vue" {
    interface Vue {
        isCreated: boolean;
        isMounted: boolean;
    }
}

@Component
export default class ComponentLifeCycleMixin extends Vue {

    /**
     * Whether the component is created.
     *
     * @type boolean
     */
    public isCreated: boolean = false;

    /**
     * Whether the component is mounted.
     *
     * @type boolean
     */
    public isMounted: boolean = false;

    /**
     * On component created.
     *
     * @return {void}
     */
    public created(): void {
        this.isCreated = true;
    }

    /**
     * On component mounted.
     *
     * @return {void}
     */
    public mounted(): void {
        this.isMounted = true;
    }

}
