






































import Component, { mixins } from "vue-class-component";
import VButton from "../../../components/VButton.vue";
import { Option } from "../../../components/VFormulateSelectPaginated.vue";
import VPageWindow from "../../../components/VPage/VPageWindow.vue";
import VSection from "../../../components/VSection.vue";
import DistrictSelect from "../../../components/VueFormulate/DistrictSelect.vue";
import FormMixin, { SubmittedDataInterface } from "../../../mixins/FormMixin";
import DistrictModel from "../../../models/District";
import Unit from "../../../models/Unit";

@Component({
    components: {
        DistrictSelect,
        VSection,
        VPageWindow,
        VButton
    }
})
export default class UnitForm extends mixins(FormMixin) {

    /**
     * The form data.
     *
     * @type Object
     */
    private formData = {
        name: "",
        district: null as DistrictModel|null
    }

    /**
     * On component created.
     *
     * @return {Promise<void>}
     */
    public async created(): Promise<void> {
        if (this.isEditForm) {
            const id = this.routeParam("unit")!;

            await this.withLoading(async () => {
                this.showLoading();

                const unit = await Unit.editRemote(id) as Unit;

                this.formData = {
                    name: unit.name,
                    district: unit.district
                };

                this.hideLoading();
            });
        }
    }

    /**
     * The district select value.
     *
     * @return {Option}
     */
    public get value(): Option {
        const { district } = this.formData;

        if (district) {
            return {
                id: district.id.toString(),
                value: district.id.toString(),
                label: district.name
            } as Option;
        }

        return {} as Option;
    }

    /**
     * Returns the data for submitting a form.
     *
     * @return {SubmittedDataInterface}
     */
    public getSubmittedData(): SubmittedDataInterface {
        const { name, district } = this.formData;
        const data = {
            name,
            district_id: district?.id
        };

        return {
            model: Unit,
            data
        };
    }

}
