




















import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Router from "../../classes/Router";
import { EVENT_SEARCH, EventBus } from "../../event-bus";
import SearchPayload from "../../interfaces/SearchPayload";
import VButton from "../VButton.vue";
import VIcon from "../VIcon.vue";

@Component({
    components: {
        VButton,
        VIcon
    }
})
export default class VPageMenuSearch extends Vue {

    /**
     * The ID of the input element.
     *
     * @type string
     */
    @Prop({ default: "search_input" })
    public inputId!: string;

    /**
     * Whether to append query to the URL or not.
     *
     * @type boolean
     */
    @Prop({ default: true })
    public appendQuery!: boolean;

    /**
     * The search phrase.
     *
     * @type string
     */
    public query: string = Router.getQuery() ?? "";

    /**
     * Returns the input label style.
     *
     * @type Object
     */
    private labelStyle = {
        visibility: "hidden",
        height: 0,
        width: 0
    };

    /**
     * Handles submitting the search form.
     *
     * @private
     * @return void
     */
    private onSubmit(): void {
        const { query } = this;
        const searchPayload: SearchPayload = {
            query
        };

        // We add query attribute to the current URL
        if (this.appendQuery) {
            Router.setQuery(query);
        }

        // We emit a global event with the search payload
        EventBus.$emit(EVENT_SEARCH, searchPayload);

        // We emit an "submit" event with the query to the parent
        this.$emit("submit", searchPayload);
    }

}
