






















import Vue from "vue";
import Component from "vue-class-component";
import VAppLogo from "../../components/VAppLogo.vue";
import VButton from "../../components/VButton.vue";
import VResourceNotFound from "../../components/VResourceNotFound.vue";
import HowContributionsWork from "./HowContributionsWork.vue";

@Component({
    components: {
        HowContributionsWork,
        VAppLogo,
        VResourceNotFound,
        VButton
    }
})
export default class StatusPaid extends Vue {}
