
























import Vue from "vue";
import Component from "vue-class-component";
import VAppLogo from "../../components/VAppLogo.vue";
import VIcon from "../../components/VIcon.vue";
import LoginForm from "./LoginForm.vue";

@Component({
    components: {
        LoginForm,
        VIcon,
        VAppLogo
    }
})
export default class LoginMain extends Vue {

}
