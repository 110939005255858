

























import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import PaginationInterface from "../../../interfaces/PaginationInterface";
import VIcon from "../../VIcon.vue";

@Component({
    components: {
        VIcon
    }
})
export default class VFormulateSelectPagination extends Vue {

    @Prop({ required: true })
    public pagination!: PaginationInterface;

    /**
     * Returns the number of the current page.
     *
     * @return number
     */
    public get currentPage(): number {
        return this.getPaginationValue("current_page", 0) as number;
    }

    /**
     * Returns the number of the last page.
     *
     * @return number
     */
    public get lastPage(): number {
        return this.getPaginationValue("last_page", 0) as number;
    }

    /**
     * Returns the total number of results.
     *
     * @return number
     */
    public get total(): number {
        return this.getPaginationValue("total", 0) as number;
    }

    /**
     * Returns the starting offset number of the results.
     *
     * @return number
     */
    public get from(): number {
        return this.getPaginationValue("from", 0) as number;
    }

    /**
     * Returns the ending offset number of the results.
     *
     * @return number
     */
    public get to(): number {
        return this.getPaginationValue("to", 0) as number;
    }

    /**
     * Returns the number of results per page.
     *
     * @return number
     */
    public get perPage(): number {
        return this.getPaginationValue("per_page", 0) as number;
    }

    /**
     * Returns value of the pagination param.
     *
     * @private
     * @param {keyof PaginationInterface} param
     * @param {string|number|null} defaultValue
     * @return {string|number|null}
     */
    private getPaginationValue(param: keyof PaginationInterface, defaultValue: string | number | null): string | number | null {
        if (!this.pagination[param]) {
            return defaultValue;
        }

        return this.pagination[param];
    }

    /**
     * Handles the page change.
     *
     * @private
     * @param {number} page
     * @return {void}
     */
    private onPageChange(page: number): void {
        if (this.currentPage !== page) {
            this.$emit("change", page);
        }
    }

}
