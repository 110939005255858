





















import Vue from "vue";
import Component from "vue-class-component";
import VButton from "../../components/VButton.vue";
import VResourceNotFound from "../../components/VResourceNotFound.vue";
import HowContributionsWork from "./HowContributionsWork.vue";

@Component({
    components: {
        HowContributionsWork,
        VButton,
        VResourceNotFound
    }
})
export default class StatusNotFound extends Vue {}
