





















import Vue from "vue";
import Component from "vue-class-component";
import VAppLogo from "../../components/VAppLogo.vue";
import HomeForm from "./HomeForm.vue";

@Component({
    components: {
        HomeForm,
        VAppLogo
    }
})
export default class HomeMain extends Vue {}
