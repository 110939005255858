import Vue from "vue";
import VueRouter from "vue-router";
import Route from "../classes/Route";
import Home from "../pages/Home/Index.vue";
import Login from "../pages/Login/Index.vue";
import RoleForm from "../pages/Roles/Form/Index.vue";
import Roles from "../pages/Roles/Index.vue";
import Districts from "../pages/Districts/Index.vue";
import DistrictForm from "../pages/Districts/Form/Index.vue";
import Units from "../pages/Units/Index.vue";
import UnitForm from "../pages/Units/Form/Index.vue";
import Users from "../pages/Users/Index.vue";
import UserForm from "../pages/Users/Form/Index.vue";
import Scouts from "../pages/Scouts/Index.vue";
import ScoutForm from "../pages/Scouts/Form/Index.vue";
import Settings from "../pages/Settings/Index.vue";
import Status from "../pages/Status/Index.vue";
import Account from "../pages/Account/Index.vue";
import { allowGuests } from "./guards";
import permissions from "../../json/permissions.json";

Vue.use(VueRouter);

/**
 * VueRouter object.
 *
 * @type VueRouter
 */
export default new VueRouter({
    mode: "history",
    routes: [
        Route.get("/").named("home").renders(Home),
        Route.get("/status/:registrationNumber").named("status").renders(Status),
        Route.get("/login").named("login").renders(Login).guard(allowGuests),
        Route.get("/roles").named("roles").renders(Roles).permission(permissions.role.access),
        Route.get("/roles/create").named("roles.create").renders(RoleForm).permission(permissions.role.create),
        Route.get("/roles/:role/edit").named("roles.edit").renders(RoleForm).permission(permissions.role.edit),
        Route.get("/districts").named("districts").renders(Districts).permission(permissions.district.access),
        Route.get("/districts/create").named("districts.create").renders(DistrictForm).permission(permissions.district.create),
        Route.get("/districts/:district/edit").named("districts.edit").renders(DistrictForm).permission(permissions.district.edit),
        Route.get("/units").named("units").renders(Units).permission(permissions.unit.access),
        Route.get("/units/create").named("units.create").renders(UnitForm).permission(permissions.unit.create),
        Route.get("/units/:unit/edit").named("units.edit").renders(UnitForm).permission(permissions.unit.edit),
        Route.get("/users").named("users").renders(Users).permission(permissions.user.access),
        Route.get("/users/create").named("users.create").renders(UserForm).permission(permissions.user.create),
        Route.get("/users/:user/edit").named("users.edit").renders(UserForm).permission(permissions.user.edit),
        Route.get("/scouts").named("scouts").renders(Scouts).permission(permissions.scout.access),
        Route.get("/scouts/create").named("scouts.create").renders(ScoutForm).permission(permissions.scout.create),
        Route.get("/scouts/:scout/edit").named("scouts.edit").renders(ScoutForm).permission(permissions.scout.edit),
        Route.get("/settings").named("settings").renders(Settings).permission(permissions.setting.access),
        Route.get("/account/settings").named("account.settings").renders(Account)
    ]
});
