




























import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class HomeFooter extends Vue {
    public name = "HomeFooter";
}
