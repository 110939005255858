







































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ResourceDropdownMenu from "../../components/ResourceDropdownMenu.vue";
import VButton from "../../components/VButton.vue";
import VIcon from "../../components/VIcon.vue";
import Role from "../../models/Role";
import RoleCardDetails from "./RoleCardDetails.vue";

@Component({
    components: {
        ResourceDropdownMenu,
        RoleCardDetails,
        VIcon,
        VButton
    }
})
export default class RoleCard extends Vue {

    @Prop({ required: true })
    public role!: Role;

    /**
     * Returns the full class attribute for role card.
     *
     * @return {string[]}
     */
    public get roleCardClass(): string[] {
        return [
            "role-card",
            "text-light",
            "bg-success-transparent",
            "my-3",
            "shadow-sm"
        ];
    }

    /**
     * Checks whether the role is a "super administrator" role.
     *
     * @return boolean
     */
    public get isSuperAdminRole(): boolean {
        return (this.role.name === this.allRoles.super_administrator);
    }

    /**
     * Whether to show the aside menu with actions or not.
     *
     * @return {boolean}
     */
    public get displayAsideMenu(): boolean {
        return this.canAll([
            this.allPermissions.role.edit,
            this.allPermissions.role.delete
        ]);
    }

    /**
     * Returns dropdown id string.
     *
     * @return {string}
     */
    public get dropdownId(): string {
        return "role-card-" + this.role.id + "-dropdown";
    }

    /**
     * Whether to show the dropdown menu.
     *
     * @return {boolean}
     */
    public get showMenu(): boolean {
        return this.can(this.allPermissions.role.edit) || this.can(this.allPermissions.role.delete);
    }

    /**
     * Handles "edit role" button click.
     *
     * @private
     * @return {void}
     */
    private onRoleEdit(): void {
        this.redirectTo("roles.edit", this.role.id);
    }

    /**
     * Handles "delete role" button click.
     *
     * @private
     * @return void
     */
    private onRoleDelete(): void {
        const { id, name } = this.role;
        const question = this.$lang("Czy na pewno chcesz usunąć rolę \":name\"?", { name });

        this.confirmationModal({
            question,
            onConfirm: () => Role.deleteRemote(id)
        });

    }

}
