





























import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import VIcon from "../../components/VIcon.vue";
import District from "../../models/District";

@Component({
    components: { VIcon }
})
export default class DistrictCardDetails extends Vue {

    @Prop({ required: true })
    public district!: District;

}
