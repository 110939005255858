



























































































import Component, { mixins } from "vue-class-component";
import PermissionScope from "../../../classes/PermissionScope";
import VButton from "../../../components/VButton.vue";
import VIcon from "../../../components/VIcon.vue";
import VLoading from "../../../components/VLoading.vue";
import VSection from "../../../components/VSection.vue";
import FormMixin, { SubmittedDataInterface } from "../../../mixins/FormMixin";
import Permission from "../../../models/Permission";
import Role from "../../../models/Role";
import RoleFormPermissionGroup from "./RoleFormPermissionGroup.vue";
import RoleFormPermissionsScopes from "./RoleFormPermissionsScopes.vue";

@Component({
    components: {
        VLoading,
        RoleFormPermissionsScopes,
        VIcon,
        RoleFormPermissionGroup,
        VButton,
        VSection
    }
})
export default class RoleForm extends mixins(FormMixin) {

    /**
     * The form data.
     *
     * @type Object
     */
    private formData = {
        name: "",
        permissions: [] as string[],
        scopes: [] as PermissionScope[]
    };

    /**
     * On component created.
     *
     * @return {Promise<void>}
     */
    public async created(): Promise<void> {
        if (this.isEditForm) {
            const id = this.routeParam("role")!;

            this.showLoading();

            const role = await Role.editRemote(id) as Role;

            this.formData = {
                name: role.name,
                permissions: role.permissions.map((permission: Permission) => permission.name),
                scopes: PermissionScope.from(role.permissions_scopes)
            };

            this.hideLoading();
        }
    }

    /**
     * Returns permissions sub-group for selected permissions tab.
     *
     * @private
     * @return {Object}
     */
    private get permissionsGroup(): object {
        const { allPermissions } = this;
        const queryTab = this.$route.query.tab as keyof typeof allPermissions;
        const tab = (typeof allPermissions[queryTab] === "undefined")
            ? "role"
            : queryTab;

        return allPermissions[tab as keyof typeof allPermissions];
    }

    /**
     * Returns the data for submitting a form.
     *
     * @return {SubmittedDataInterface}
     */
    public getSubmittedData(): SubmittedDataInterface {
        const { name, permissions, scopes } = this.formData;

        const data = {
            name,
            permissions,
            scopes_permissions: scopes.map((scope) => scope.permission),
            scopes_scopes: scopes.map((scope) => scope.scope),
            scopes_values: scopes.map((scope) => scope.values)
        };

        return {
            model: Role,
            data
        };
    }

    /**
     * Checks whether given tab is active or not.
     *
     * @private
     * @param {string} key
     * @return {boolean}
     */
    private isTabActive(key: string): boolean {
        return (this.$route.query.tab === key);
    }

    /**
     * On role nav item click.
     *
     * @private
     * @param {string} key
     * @return {void}
     */
    private onRoleItemClick(key: string): void {
        if (key === this.$route.query.tab) {
            return;
        }

        this.$router.replace({
            query: {
                tab: key
            }
        });
    }

}
