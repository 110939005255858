import Vue from "vue";
import Component from "vue-class-component";
import { Location, Route } from "vue-router";
import { Dictionary } from "vue-router/types/router";

declare module "vue/types/vue" {
    interface Vue {
        redirect(route: Location): void;
        redirectTo(name: string, params?: string | any): void;
        routeParam(name: string): string | undefined;
    }
}

@Component
export default class RedirectMixin extends Vue {

    /**
     * Redirects to the route of given name.
     *
     * @protected
     * @param {string} name The name of the route to redirect to.
     * @param {Object} params The route params.
     * @return {void}
     */
    public redirectTo(name: string, params?: any): void {
        let routeParams: Dictionary<string> = {};

        if (typeof params === "number") {
            const paramName = this.findRouteParamName(name);
            if (paramName) {
                routeParams[paramName] = params.toString();
            }
        } else {
            routeParams = params;
        }

        this.redirect({
            name,
            params: routeParams
        });
    }

    /**
     * Redirects to given route location.
     *
     * @protected
     * @param {Location} route
     * @return {void}
     */
    public redirect(route: Location): void {
        this.$router.push(route);
    }

    /**
     * Returns current's route param value for given name.
     *
     * @param {string} name
     * @return {string|undefined}
     */
    public routeParam(name: string): string | undefined {
        return this.$route.params[name];
    }

    /**
     * Returns first found route param name (if exists).
     *
     * @private
     * @param {string} routeName
     * @return {string|undefined}
     */
    private findRouteParamName(routeName: string): string | undefined {
        const route = this.findRouteByName(routeName);
        const match = route?.path?.match(/\/:(\w+)\//);

        return match ? match[1] : undefined;
    }

    /**
     * Searches for the route with given name.
     *
     * @private
     * @param {string} name
     * @return {Route|undefined}
     */
    private findRouteByName(name: string): Route | undefined {
        return (this.$router as any).options.routes.find((route: Route) => route.name === name);
    }

}
