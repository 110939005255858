
























import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import VIcon from "../../components/VIcon.vue";

@Component({
    components: { VIcon }
})
export default class ScoutCardContributionCheckbox extends Vue {

    @Prop({ default: undefined })
    public labelClass?: string;

    @Prop({ default: false })
    public checked!: boolean;

    @Watch("checked")
    public onCheckedChanged(value: boolean): void {
        this.isChecked = value;
    }

    /**
     * Whether the checkbox is checked.
     *
     * @type boolean
     */
    public isChecked = this.checked;

    /**
     * On checkbox value checked.
     *
     * @return {void}
     */
    public onChange(): void {
        this.$emit("change", this.isChecked);
    }

}
