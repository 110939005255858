





















































































import { Model } from "@vuex-orm/core";
import { Response } from "@vuex-orm/plugin-axios";
import { polishPlurals } from "polish-plurals";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import ServerErrorHandler from "../../../classes/ServerResponseHandler/ServerErrorHandler";
import PaginationInterface from "../../../interfaces/PaginationInterface";
import VButton from "../../VButton.vue";
import VFormulateSelectPagination from "../partials/VFormulateSelectPagination.vue";
import VIcon from "../../VIcon.vue";
import VLoading from "../../VLoading.vue";

@Component({
    components: {
        VButton,
        VIcon,
        VFormulateSelectPagination,
        VLoading
    }
})
export default class VFormulateModelSelect extends Vue {

    @Prop({ required: true })
    public context!: any;

    /**
     * The search phrase.
     *
     * @type string
     */
    public search: string = "";

    /**
     * The dropdown options.
     *
     * @type Model[]
     */
    public options: Model[] = [
        //
    ];

    /**
     * The currently selected option.
     *
     * @type Model|null
     */
    public selectedOption: Model | null = null;

    /**
     * The options pagination.
     *
     * @type {PaginationInterface}
     */
    public pagination: PaginationInterface = {} as PaginationInterface;

    @Watch("context.value")
    public onContextValueChanged(value: Model | Model[]): void {
        if (!this.multiselect) {
            this.selectedOption = value as Model;
        }

        this.context.model = value;
    }

    /**
     * Checks whether it is a multiselect dropdown.
     *
     * @return {boolean}
     */
    public get multiselect(): boolean {
        return (
            "multiselect" in this.context.attributes &&
            this.context.attributes["multiselect"] !== false
        );
    }

    /**
     * Returns the number of options per page.
     *
     * @return {number}
     */
    public get perPage(): number {
        return this.context.attributes["per-page"] || 5;
    }

    /**
     * Returns the Vuex ORM Model passed as attribute.
     *
     * @return {typeof Model}
     */
    public get Model(): typeof Model {
        return this.context.attributes["model"];
    }

    /**
     * Returns the attribute name of model id.
     *
     * @return {string}
     */
    public get modelIdKey(): string {
        return this.context.attributes["model-id-key"] || "id";
    }

    /**
     * Returns the attribute name of model value.
     *
     * @return {string}
     */
    public get modelValueKey(): string {
        return this.context.attributes["model-value-key"] || "id";
    }

    /**
     * Returns the attribute name of model label.
     *
     * @return {string}
     */
    public get modelLabelKey(): string {
        return this.context.attributes["model-label-key"] || "name";
    }

    /**
     * Returns the permission related to the model CRUD action.
     *
     * @return {string}
     */
    public get modelPermission(): string {
        return this.context.attributes["model-permission"];
    }

    /**
     * Returns additional params to append to the model request.
     *
     * @return {Object}
     */
    public get modelParams(): object {
        return this.context.attributes["model-params"];
    }

    /**
     * Returns the default text that appears on toggle button.
     *
     * @return {string}
     */
    public get defaultToggleButtonText(): string {
        return this.context.attributes["default-toggle-button-text"] || this.$lang("Wybierz");
    }

    /**
     * Returns the button class prop.
     *
     * @return {string}
     */
    public get buttonClass(): string {
        return this.context.attributes["button-class"] || "";
    }

    /**
     * Whether to show the reset button or not.
     *
     * @return {boolean}
     */
    public get resetButton(): boolean {
        return (typeof this.context.attributes["reset-button"] !== "undefined");
    }

    /**
     * Whether the "Choose all" option is present or not.
     *
     * @return {boolean}
     */
    public get optionAll(): boolean {
        return (typeof this.context.attributes["option-all"] !== "undefined");
    }

    /**
     * Returns the "choose all" option text.
     *
     * @return {string}
     */
    public get optionAllText(): string {
        return this.context.attributes["option-all-text"] || this.$lang("Wszystkie");
    }

    /**
     * Returns text for toggle button.
     *
     * @return {string}
     */
    public get toggleButtonText(): string {
        const { model } = this.context;

        if (this.multiselect) {
            if (model && Array.isArray(model) && model.length > 0) {
                return this.$lang("Wybrano :amount :options", {
                    amount: model.length,
                    options: polishPlurals(
                        this.$lang("opcję"),
                        this.$lang("opcje"),
                        this.$lang("opcji"),
                        model.length
                    )
                });
            }
        }

        return this.context.model[this.modelLabelKey] || this.defaultToggleButtonText;
    }

    /**
     * Returns dropdown toggle button class.
     *
     * @return {Array}
     */
    public get toggleButtonClass(): any[] {
        return [
            "select-toggle",
            "mw-100",
            "overflow-hidden",
            "text-ellipsis",
            "text-left",
            "position-relative",
            this.buttonClass,
            { "selected": this.multiselect
                    ? (Array.isArray(this.context.model) && this.context.model.length > 0)
                    : this.selectedOption !== null
            }
        ];
    }

    /**
     * Returns the style object for toggle button icon.
     *
     * @return {string}
     */
    public get toggleButtonIconStyle(): object {
        return {
            right: "0.5rem",
            top: "25%"
        };
    }

    /**
     * Returns the class for the whole option label.
     *
     * @return {Array}
     */
    public checkboxLabelClass(option: Model): any[] {
        return [
            "block-checkbox",
            { "active": this.isItemSelected(option[this.modelValueKey as keyof typeof option] as string|number) }
        ];
    }

    /**
     * Handles actions when the dropdown is being shown.
     *
     * @return {void}
     */
    public onDropdownShow(): void {
        if (!this.options || !this.options.length) {
            this.requestOptions();
        }
    }

    /**
     * Handles actions when the dropdown is fully shown.
     *
     * @return {void}
     */
    public onDropdownShown(): void {
        this.setFocusOnSearchInput();
    }

    /**
     * Checks whether option of given value is selected or not.
     *
     * @param {string} value The value of the option.
     * @return {boolean}
     */
    public isItemSelected(value: string | number): boolean {
        const { selectedOption } = this;
        const valueKey = this.modelValueKey as keyof typeof selectedOption;

        if (this.multiselect) {
            const { model } = this.context;

            return (
                model &&
                Array.isArray(model) &&
                !!this.$lodash.find(model, [valueKey, value])
            );
        } else {
            return (
                selectedOption !== null &&
                selectedOption[valueKey as keyof typeof selectedOption] === value
            );
        }
    }

    /**
     * Returns the class for the dropdown item.
     *
     * @return {Array}
     */
    public dropdownItemClass(option: Model | null): any[] {
        const active = (option === null)
            ? this.selectedOption === null
            : this.isItemSelected(option[this.modelValueKey as keyof typeof option] as string | number);

        return [
            "paginated-dropdown-item",
            { "active": active }
        ];
    }

    /**
     * Requests options from server and assigns the results
     * as options.
     *
     * @param {number} page
     * @return {void}
     */
    public requestOptions(page?: number): void {
        const { Model } = this;
        const params = {
            q: this.search,
            page: page || 1,
            per_page: this.perPage,
            model_permission: this.modelPermission,
            ...this.modelParams
        };
        const data = {
            params,
            save: false
        };

        Model.api().get("/" + Model.entity, data).then((response: Response) => {
            const { data } = response.response;
            const entities = data[Model.entity] as Model[];
            const pagination = data.pagination;

            this.options = entities;
            this.pagination = pagination;
        }).catch(ServerErrorHandler.handle).then(() => {
            this.refreshDropdownPosition();
            this.setFocusOnSearchInput();
        });
    }

    /**
     * On dropdown item click.
     *
     * @param {Model} option
     * @return {void}
     */
    public onItemClick(option: Model | null): void {
        let newModel;

        if (this.multiselect) {
            if (this.context.model && Array.isArray(this.context.model)) {
                if (this.$lodash.find(this.context.model, [this.modelValueKey, option![this.modelValueKey as keyof typeof option]])) {
                    newModel = this.context.model.filter((model: Model) => {
                        return model[this.modelValueKey as keyof typeof model] !== option![this.modelValueKey as keyof typeof option];
                    });
                } else {
                    newModel = [...this.context.model, option];
                }
            } else {
                newModel = [option];
            }
        } else {
            newModel = option;
            this.selectedOption = newModel;
        }

        this.context.model = newModel;
        this.$emit("change", newModel);
        this.refreshDropdownPosition();
    }

    /**
     * Sets the focus on search input.
     *
     * @private
     * @return {void}
     */
    public setFocusOnSearchInput(): void {
        (this.$refs["searchInput"] as any).$el.focus();
    }

    /**
     * Refreshes the position of dropdown after dynamic changes
     * in its body content.
     *
     * @private
     * @return {void}
     */
    public refreshDropdownPosition(): void {
        // Nasty, nasty way to achieve the update of the position
        // of the dropdown menu, yet the update() method behaves
        // nothing like the code below so here we are.
        window.scrollBy({ top: 1 });
        window.scrollBy({ top: -1 });

        this.setFocusOnSearchInput();
    }

}
