


































































































import Component, { mixins } from "vue-class-component";
import VButton from "../../../components/VButton.vue";
import VSection from "../../../components/VSection.vue";
import Archived from "../../../components/VueFormulate/Archived.vue";
import DistrictSelect from "../../../components/VueFormulate/DistrictSelect.vue";
import FirstName from "../../../components/VueFormulate/FirstName.vue";
import JoinedAt from "../../../components/VueFormulate/JoinedAt.vue";
import LastName from "../../../components/VueFormulate/LastName.vue";
import QuitAt from "../../../components/VueFormulate/QuitAt.vue";
import RegistrationNumber from "../../../components/VueFormulate/RegistrationNumber.vue";
import Remarks from "../../../components/VueFormulate/Remarks.vue";
import UnitSelect from "../../../components/VueFormulate/UnitSelect.vue";
import FormMixin, { SubmittedDataInterface } from "../../../mixins/FormMixin";
import District from "../../../models/District";
import Scout from "../../../models/Scout";
import Unit from "../../../models/Unit";

@Component({
    components: {
        Archived,
        VButton,
        Remarks,
        QuitAt,
        JoinedAt,
        DistrictSelect,
        UnitSelect,
        RegistrationNumber,
        LastName,
        FirstName,
        VSection
    }
})
export default class ScoutForm extends mixins(FormMixin) {

    /**
     * The form data.
     *
     * @type Object
     */
    public formData = {
        first_name: "",
        last_name: "",
        registration_number: "",
        unit: null as Unit | null,
        district: null as District | null,
        joined_at: null as string | null,
        quit_at: null as string | null,
        remarks: null as string | null,
        archived: [] as string[]
    };

    /**
     * On component created.
     *
     * @return {Promise<void>}
     */
    public async created(): Promise<void> {
        if (this.isEditForm) {
            const id = this.routeParam("scout")!;

            await this.withLoading(async () => {
                this.showLoading();

                const scout = await Scout.editRemote(id) as Scout;

                this.formData = {
                    first_name: scout.first_name,
                    last_name: scout.last_name,
                    registration_number: scout.registration_number,
                    unit: scout.unit as null,
                    district: scout.district as null,
                    joined_at: scout.joined_at,
                    quit_at: scout.quit_at,
                    remarks: scout.remarks,
                    archived: scout.archived ? ["archived"] : []
                };

                this.hideLoading();
            });
        }
    }

    /**
     * Returns the data for submitting a form.
     *
     * @return {SubmittedDataInterface}
     */
    public getSubmittedData(): SubmittedDataInterface {
        const {
            first_name,
            last_name,
            registration_number,
            unit,
            district,
            joined_at,
            quit_at,
            remarks,
            archived
        } = this.formData;

        const data = {
            first_name,
            last_name,
            registration_number,
            unit_id: unit ? unit.id : null,
            district_id: district ? district.id : null,
            joined_at,
            quit_at,
            remarks,
            archived: archived.includes("archived")
        };

        return {
            model: Scout,
            data
        };
    }

    /**
     * Whether to show the district assignment help text or not.
     *
     * @return {boolean}
     */
    public get showDistrictAssignmentHelp(): boolean {
        const permissions = this.allPermissions;

        return (
            this.can(permissions.scout.assign.district) &&
            this.can(permissions.scout.assign.unit)
        );
    }

}
