






















import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import VIcon from "./VIcon.vue";

@Component({
    components: { VIcon }
})
export default class VCheckbox extends Vue {

    @Prop({ required: true })
    public context!: any;

    /**
     * Returns the checkbox model which can be either an object
     * or an array of other checkbox values.
     *
     * @private
     * @return {object|string[]}
     */
    private get checkboxModel(): object | string[] {
        return this.context.attributes["model"];
    }

    /**
     * Returns the checkbox name attribute given as parameter.
     *
     * @private
     * @return {string}
     */
    private get checkboxName(): string {
        return this.context.attributes["name"];
    }

    /**
     * Returns the value of checkbox (true or false).
     *
     * @private
     * @return {boolean}
     */
    private get value(): boolean {
        const model = this.checkboxModel as any;
        const name = this.checkboxName;

        return Array.isArray(model)
            ? model.includes(name)
            : !!model[name];
    }

    /**
     * When the checkbox value is changed.
     *
     * @private
     * @return {void}
     */
    private onChange(): void {
        const model = this.checkboxModel as any;
        const name = this.checkboxName;
        const checked = !(typeof model[name] === "boolean" && model[name]);

        if (Array.isArray(model)) {
            // If the model to change is an array of elements then we assume it is
            // also an array for other checkboxes, therefore we either append our
            // value, or remove it from the array if already exists.
            const index = model.indexOf(name);

            if (index >= 0) {
                model.splice(index, 1);
            } else {
                model.push(name);
            }
        } else {
            // When model is an object, we store the checkbox value in an attribute
            // corresponding with the checkbox name.
            model[name] = checked;
        }
    }

}
