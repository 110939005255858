import Vue from "vue";
import Component from "vue-class-component";
import Auth from "../models/Auth";
import permissions from "../../json/permissions.json";
import roles from "../../json/roles.json";

declare module "vue/types/vue" {
    interface Vue {
        allPermissions: typeof permissions;
        allRoles: typeof roles;
        authorizeWithCookies(): Promise<void>;
        authUser: Auth | null;
        can(permission: string): boolean;
        canAll(permissions: string[]): boolean;
        canAny(permissions: string[]): boolean;
        isAuthorized(): boolean;
        logout(): void;
    }
}

@Component
export default class AuthMixin extends Vue {

    /**
     * JSON object of all available permissions.
     *
     * @type permissions
     */
    public allPermissions: typeof permissions = permissions;

    /**
     * JSON object of all predefined roles.
     *
     * @type roles
     */
    public allRoles: typeof roles = roles;

    /**
     * Returns the authenticated user data.
     *
     * @return {Auth|null}
     */
    public get authUser(): Auth | null {
        return Auth.first();
    }

    /**
     * Creates and stores a new Auth model using cookies.
     *
     * @return {void}
     */
    public async authorizeWithCookies(): Promise<void> {
        return Auth.authorizeWithCookies();
    }

    /**
     * Checks whether the user is authorized.
     *
     * @return {boolean}
     */
    public isAuthorized(): boolean {
        return Auth.check();
    }

    /**
     * Deletes the authorization data and logs the user out.
     *
     * @return {void}
     */
    public logout(): void {
        Auth.logout();
    }

    /**
     * Checks if the user has given permission.
     *
     * @param {string} permission
     * @return {boolean}
     */
    public can(permission: string): boolean {
        return Auth.can(permission);
    }

    /**
     * Checks if the user has all given permissions.
     *
     * @param {string[]} permissions
     * @return {boolean}
     */
    public canAll(permissions: string[]): boolean {
        return permissions.map(this.can).every((value) => value);
    }

    /**
     * Checks if the user has any of given permissions.
     *
     * @param {string[]} permissions
     * @return {boolean}
     */
    public canAny(permissions: string[]): boolean {
        return permissions.some((permission: string) => this.can(permission));
    }

    /**
     * Checks whether the authorized user is a super administrator.
     *
     * @return {boolean}
     */
    public isSuperAdmin(): boolean {
        return Auth.isSuperAdmin();
    }

}
