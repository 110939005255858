









import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import VNavbarToggle from "../VNavbarToggle.vue";

@Component({
    components: { VNavbarToggle }
})
export default class VPageMenu extends Vue {

    @Prop({ required: true })
    public collapseId!: string;

    @Prop({ default: "md" })
    public toggleable!: string | false;

}
