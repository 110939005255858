





































import Vue from "vue";
import Component from "vue-class-component";
import VIcon from "../VIcon.vue";

@Component({
    components: { VIcon }
})
export default class TheNavbarMenu extends Vue {

    /**
     * Routes for menu links.
     *
     * @type Object
     */
    private routes = {
        roles: { name: "roles" },
        districts: { name: "districts" },
        units: { name: "units" },
        users: { name: "users" },
        scouts: { name: "scouts" },
        settings: { name: "settings" },
        accountSettings: { name: "account.settings" }
    };

}
