











import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class Password extends Vue {

    @Prop({ default: "" })
    public label!: string;

    @Prop({ default: "" })
    public name!: string;

    @Prop({ default: "required" })
    public validation!: string;

    /**
     * Returns the input label.
     *
     * @return {string}
     */
    public get theLabel(): string {
        return this.label || this.$lang("Hasło");
    }

    /**
     * Returns the input name.
     *
     * @return {string}
     */
    public get theName(): string {
        return this.name || "password";
    }

    /**
     * Returns the input validation rules.
     *
     * @return {string}
     */
    public get theValidation(): string {
        return typeof this.validation !== "undefined" ? this.validation : "required";
    }

}
