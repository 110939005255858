







































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ResourceDropdownMenu from "../../components/ResourceDropdownMenu.vue";
import VButton from "../../components/VButton.vue";
import VIcon from "../../components/VIcon.vue";
import District from "../../models/District";
import DistrictCardDetails from "./DistrictCardDetails.vue";

@Component({
    components: {
        ResourceDropdownMenu,
        DistrictCardDetails,
        VIcon,
        VButton
    }
})
export default class DistrictCard extends Vue {

    @Prop({ required: true })
    public district!: District;

    /**
     * Returns the full class attribute for district card.
     *
     * @return {string[]}
     */
    public get districtCardClass(): string[] {
        return [
            "district-card",
            "text-light",
            "bg-success-transparent",
            "my-3",
            "shadow-sm"
        ];
    }

    /**
     * Whether to show the aside menu with actions or not.
     *
     * @return {boolean}
     */
    public get displayAsideMenu(): boolean {
        return this.canAll([
            this.allPermissions.district.edit,
            this.allPermissions.district.delete
        ]);
    }

    /**
     * Returns dropdown id string.
     *
     * @return {string}
     */
    public get dropdownId(): string {
        return "district-card-" + this.district.id + "-dropdown";
    }

    /**
     * Whether to show the dropdown menu.
     *
     * @return {boolean}
     */
    public get showMenu(): boolean {
        return this.can(this.allPermissions.district.edit) || this.can(this.allPermissions.district.delete);
    }

    /**
     * Handles "edit district" button click.
     *
     * @private
     * @return {void}
     */
    private onDistrictEdit(): void {
        this.redirectTo("districts.edit", this.district.id);
    }

    /**
     * Handles "delete district" button click.
     *
     * @private
     * @return void
     */
    private onDistrictDelete(): void {
        const { id, name } = this.district;
        const question = this.$lang("Czy na pewno chcesz usunąć hufiec \":name\"?", { name });

        this.confirmationModal({
            question,
            onConfirm: () => District.deleteRemote(id)
        });
    }

}
