
































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import PermissionScope from "../../../classes/PermissionScope";
import VButton from "../../../components/VButton.vue";
import RoleFormPermissionScope from "./RoleFormPermissionScope.vue";

@Component({
    components: {
        VButton,
        RoleFormPermissionScope
    }
})
export default class RoleCreatePermissionsScopes extends Vue {

    @Prop({ required: true })
    public category!: string;

    @Prop({ required: true })
    public model!: any[];

    @Prop({ required: true })
    public permissionsModel!: string[];

    /**
     * Filters the model scopes to find only those of requested category.
     *
     * @private
     * @return {Array}
     */
    private get categoryScopes(): Array<any> {
        return this.model.filter((scope) => scope.category === this.category);
    }

    /**
     * Adds new scope.
     *
     * @private
     * @return {void}
     */
    private addScope(): void {
        const scope = new PermissionScope(
            Date.now(),
            this.category,
            "",
            "",
            []
        );

        this.model.push(scope);
    }

}
