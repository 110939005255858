










import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ResourceCards from "../../components/ResourceCards.vue";
import PaginationInterface from "../../interfaces/PaginationInterface";
import Role from "../../models/Role";
import RoleCard from "./RoleCard.vue";

@Component({
    components: {
        ResourceCards,
        RoleCard
    }
})
export default class RoleCards extends Vue {

    @Prop({ required: true })
    public roles!: Role[];

    @Prop({ required: true })
    public pagination!: PaginationInterface;

    /**
     * Returns the "key" attribute value.
     *
     * @param {number} id
     * @return string
     */
    public roleKey(id: number): string {
        return `role-card-${id}`;
    }

}
