












import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import ResourceCards from "../../components/ResourceCards.vue";
import Pagination from "../../models/Pagination";
import Scout from "../../models/Scout";
import { ContributionsDisplayMode } from "./Index.vue";
import ScoutCard from "./ScoutCard.vue";

@Component({
    components: {
        ResourceCards,
        ScoutCard,
    }
})
export default class ScoutCards extends Vue {

    @Prop({ required: true })
    public scouts!: Scout[];

    @Prop({ required: true })
    public pagination!: Pagination;

    @Prop({ required: true })
    public requestScoutsFunction!: Function;

    @Prop({ required: true })
    public contributionsDisplayMode!: ContributionsDisplayMode;

    /**
     * Returns the "key" attribute value.
     *
     * @param {number} id
     * @return string
     */
    public scoutKey(id: number): string {
        return `scout-card-${id}`;
    }

}
