import Model from "./Model";

export default class UserUnit extends Model {

    /**
     * The name that is going be used as module name in Vuex Store.
     *
     * @type string
     */
    public static entity = "user_units";

    /**
     * The definition of the fields of the model and its relations.
     *
     * @return Object
     */
    public static fields() {
        return {
            id: this.number(0),
            user_id: this.number(0),
            unit_id: this.number(0)
        };
    }

}
